// THIS FILE IS GENERATED, DO NOT EDIT!
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends {
  [key: string]: unknown;
}> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends {
  [key: string]: unknown;
}, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {
    input: string;
    output: string;
  };
  String: {
    input: string;
    output: string;
  };
  Boolean: {
    input: boolean;
    output: boolean;
  };
  Int: {
    input: number;
    output: number;
  };
  Float: {
    input: number;
    output: number;
  };
  /** BigDecimal */
  BigDecimal: {
    input: any;
    output: any;
  };
  /** It's a BigDecimal with scale at 2 */
  BigDecimalQl: {
    input: any;
    output: any;
  };
  /** BigInteger */
  BigInteger: {
    input: any;
    output: any;
  };
  /** An RFC-3339 compliant Date Scalar */
  Date: {
    input: any;
    output: any;
  };
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: {
    input: any;
    output: any;
  };
  /** A JSON scalar */
  JSON: {
    input: any;
    output: any;
  };
  /** "It's a long */
  Long: {
    input: any;
    output: any;
  };
  /** Represents map[string]interface{} */
  Map: {
    input: any;
    output: any;
  };
  /** Object */
  Object: {
    input: any;
    output: any;
  };
  /** Universally unique identifier */
  UUID: {
    input: any;
    output: any;
  };
};

/** ABTest object */
export type ABTest = {
  __typename?: 'ABTest';
  /** Feature of the AB test */
  feature: Scalars['String']['output'];
  /** ID */
  id: Scalars['Int']['output'];
};

/** AB Test values */
export enum ABTestPool {
  aaa = 'aaa',
  aab = 'aab',
  aba = 'aba',
  abb = 'abb',
  baa = 'baa',
  bab = 'bab',
  bba = 'bba',
  bbb = 'bbb',
}

/** Actual cart item offer */
export type ActualOffer = {
  __typename?: 'ActualOffer';
  /** Channel offer */
  channelOffer: ChannelOffer;
  /** Delivery fee for this offer */
  deliveryFee: Scalars['Float']['output'];
  /** Delivery time for this offer */
  deliveryTime: OfferDeliveryTime;
  /** Is VAT deductible for this offer */
  isVatDeductible: Scalars['Boolean']['output'];
};

/**
 * AdPayer enum.
 *
 * Indicates who pays for the displayed advertisement. Used for DSA.
 */
export enum AdPayer {
  BRAND = 'BRAND',
  SELLER = 'SELLER',
}

/** Request address info to update the billing address */
export type AddressInput = {
  /** address */
  address: Scalars['String']['input'];
  /** address2 */
  address2: Scalars['String']['input'];
  /** city */
  city: Scalars['String']['input'];
  /** company */
  company?: InputMaybe<Scalars['String']['input']>;
  /** country */
  country: Scalars['String']['input'];
  /** firstname */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** lastname */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** zip code */
  zipCode: Scalars['String']['input'];
};

/** Admin Refund Request - Request */
export type AdminRefundRequestRequest = {
  /** customerOrderId */
  customerOrderId: Scalars['ID']['input'];
  /** dryRun */
  dryRun: Scalars['Boolean']['input'];
  /** refundLiable */
  refundLiable: RefundLiable;
  /** refundReason */
  refundReason?: InputMaybe<RefundReason>;
  /** requestCanal */
  requestCanal: RequestCanal;
  /** requestorId */
  requestorId?: InputMaybe<Scalars['String']['input']>;
  /** sellerNote */
  sellerNote?: InputMaybe<Scalars['String']['input']>;
  /** sellerOrders */
  sellerOrders: Array<RefundRequestRequestSellerOrder>;
};

/** Admin remaining request */
export type AdminRemainingInput = {
  /** Customer order id */
  customerOrderId: Scalars['ID']['input'];
  /** Order item id */
  orderItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Seller contract id */
  sellerContractId?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * An AncestorMerchandisingCategory represents a merchandising category which is an ancestor of one or
 * more merchandising categories.
 */
export type AncestorMerchandisingCategory = {
  __typename?: 'AncestorMerchandisingCategory';
  /** MerchandisingCategory level */
  level: Scalars['Int']['output'];
  /** MerchandisingCategory name */
  name: Scalars['String']['output'];
  /** MerchandisingCategory public ID */
  publicId: Scalars['Long']['output'];
  /** MerchandisingCategory slug */
  slug: Scalars['String']['output'];
};

/** Article rating picture review */
export type ArticleRatingPictureReview = {
  __typename?: 'ArticleRatingPictureReview';
  /** Created at date */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Customer name */
  customerName?: Maybe<Scalars['String']['output']>;
  /** is incentivized */
  incentivized?: Maybe<Scalars['Boolean']['output']>;
  /** Message */
  message?: Maybe<Scalars['String']['output']>;
  /** Order id */
  orderId?: Maybe<Scalars['Int']['output']>;
  /** Original rating Id */
  originalRatingId?: Maybe<Scalars['Int']['output']>;
  /** Platform name */
  platform?: Maybe<Platform>;
  /** Rating */
  rating?: Maybe<Scalars['Int']['output']>;
  /** Review id */
  reviewId: Scalars['Int']['output'];
  /** Title (deprecated) */
  title?: Maybe<Scalars['String']['output']>;
  /** updated at date */
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Article rating stats */
export type ArticleRatingStats = {
  __typename?: 'ArticleRatingStats';
  /** article Id */
  articleId?: Maybe<Scalars['ID']['output']>;
  /** average of ratings */
  average: Scalars['Float']['output'];
  /** distribution of ratings, from 1 to 5 */
  distribution: Distribution;
  /** recommended ratio */
  recommended: Scalars['Float']['output'];
  /** total of ratings */
  total: Scalars['Int']['output'];
};

/** Article Review query Input type */
export type ArticleReviewInput = {
  /** Article Ids */
  articleIds: Array<Scalars['Int']['input']>;
  /** Customer Ids */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Exclude ratings */
  excludeRatings?: InputMaybe<Scalars['Boolean']['input']>;
  /** Exclude Translated review */
  excludeTranslated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** the number of elements required */
  limit?: Scalars['Int']['input'];
  /** Only B2B */
  onlyB2B?: InputMaybe<Scalars['Boolean']['input']>;
  /** Order by */
  orderBy?: InputMaybe<ArticleReviewOrderBy>;
  /** Order Ids */
  orderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** the number of page */
  page?: Scalars['Int']['input'];
  /** Platform */
  platform?: InputMaybe<Scalars['String']['input']>;
  /** Rating score */
  stars?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Order By Input type */
export type ArticleReviewOrderBy = {
  createdAt?: InputMaybe<SortingDirection>;
  id?: InputMaybe<SortingDirection>;
  rating?: InputMaybe<SortingDirection>;
  reviewScore?: InputMaybe<SortingDirection>;
  upVoteCount?: InputMaybe<SortingDirection>;
};

/** Attribute */
export type Attribute = {
  __typename?: 'Attribute';
  /** id: Int */
  id?: Maybe<Scalars['Int']['output']>;
  /** name: String */
  name?: Maybe<Scalars['String']['output']>;
};

/** Attribute to display for each product */
export type AttributeMetadata = {
  __typename?: 'AttributeMetadata';
  /** Text to display for the attribute */
  displayName: Scalars['String']['output'];
  /** Key of the attribute */
  key: Scalars['Int']['output'];
};

/** Audience */
export type Audience = {
  __typename?: 'Audience';
  /** Audience code */
  code: Scalars['String']['output'];
  /** Audience group: CONSTRUCTION (also known as BTP) or CORPORATE */
  group: AudienceGroup;
  /** Audience id */
  id: Scalars['Int']['output'];
};

/** Audience group types */
export enum AudienceGroup {
  /** Construction companies (also called BTP) */
  CONSTRUCTION = 'CONSTRUCTION',
  /** Corporate companies */
  CORPORATE = 'CORPORATE',
}

/** An author is a person who wrote a guide */
export type Author = {
  __typename?: 'Author';
  /** intro */
  intro: Scalars['String']['output'];
  /** name */
  name: Scalars['String']['output'];
  /** pictureUrl */
  pictureUrl: Scalars['String']['output'];
};

/** Bank account identification */
export type BankAccountIdentification = {
  __typename?: 'BankAccountIdentification';
  /** BIC of the bank account */
  bic: Scalars['String']['output'];
  /** Country of the bank account */
  country?: Maybe<Scalars['String']['output']>;
  /** Holder name of the bank account */
  holderName?: Maybe<Scalars['String']['output']>;
  /** IBAN of the bank account */
  iban: Scalars['String']['output'];
};

/** Bank details */
export type BankDetails = {
  __typename?: 'BankDetails';
  /** BIC of the bank account */
  bic: Scalars['String']['output'];
  /** Country of the bank account */
  country?: Maybe<Scalars['String']['output']>;
  /** Holder name linked to the bank account */
  holderName?: Maybe<Scalars['String']['output']>;
  /** IBAN of the bank account */
  iban: Scalars['String']['output'];
  /** Reference to use for the bank transfer */
  reference?: Maybe<Scalars['String']['output']>;
  /** Remaining amount to pay */
  remainingAmountToPay: PayInEntryAmount;
};

/** BannerCampaign object */
export type BannerCampaign = {
  __typename?: 'BannerCampaign';
  /** AB test configuration */
  abTest?: Maybe<ABTest>;
  /** Advertiser of the campaign */
  advertiser?: Maybe<Scalars['String']['output']>;
  /** Attributes like position or category id */
  attributes?: Maybe<Scalars['Map']['output']>;
  /** Feature flag configuration */
  featureFlag?: Maybe<FeatureFlag>;
  /** ID */
  id: Scalars['String']['output'];
  /** Desktop / mobile URls if available and alternative text for accessibility purpose */
  image?: Maybe<BannerCampaignImage>;
  /** Redirection link and its target */
  link?: Maybe<BannerCampaignLink>;
  /** Name of the campaign */
  name: Scalars['String']['output'];
  /** Page type of the campaign */
  pageType?: Maybe<Scalars['String']['output']>;
  /** Sponsor of the campaign */
  sponsor?: Maybe<Scalars['String']['output']>;
  /** Title of the campaign for this space */
  title?: Maybe<Scalars['String']['output']>;
  /** Tracking ID of the campaign */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** User segments associated to the banner campaign */
  userSegments?: Maybe<Scalars['Map']['output']>;
};

/** BannerCampaignSpaceImage object */
export type BannerCampaignImage = {
  __typename?: 'BannerCampaignImage';
  /** Alternative text for accessibility purpose */
  altText: Scalars['String']['output'];
  /** Desktop image URL */
  desktopUrl?: Maybe<Scalars['String']['output']>;
  /** Mobile image URL */
  mobileUrl?: Maybe<Scalars['String']['output']>;
};

/** BannerCampaignLink object */
export type BannerCampaignLink = {
  __typename?: 'BannerCampaignLink';
  /** Target: _blank, _modal or null */
  target?: Maybe<Scalars['String']['output']>;
  /** URL of the campaign */
  url: Scalars['String']['output'];
};

/** BannerSpace enum */
export enum BannerSpace {
  APP_HOMEPAGE_CO_BANNER = 'APP_HOMEPAGE_CO_BANNER',
  APP_HOMEPAGE_MAIN_PROMOTION = 'APP_HOMEPAGE_MAIN_PROMOTION',
  APP_HOMEPAGE_MAIN_SLIDER = 'APP_HOMEPAGE_MAIN_SLIDER',
  APP_HOMEPAGE_SECONDARY_PROMOTION = 'APP_HOMEPAGE_SECONDARY_PROMOTION',
  APP_HOMEPAGE_TOP_BANNER = 'APP_HOMEPAGE_TOP_BANNER',
  APP_LISTING_BANNER = 'APP_LISTING_BANNER',
  BOTTOM_BANNER = 'BOTTOM_BANNER',
  HEADER_BANNER = 'HEADER_BANNER',
  HOMEPAGE_COMMERCIAL_OPERATION = 'HOMEPAGE_COMMERCIAL_OPERATION',
  HOMEPAGE_MAIN = 'HOMEPAGE_MAIN',
  HOMEPAGE_MAIN_PROMOTION = 'HOMEPAGE_MAIN_PROMOTION',
  HOMEPAGE_SECONDARY = 'HOMEPAGE_SECONDARY',
  HOMEPAGE_SECONDARY_PROMOTION = 'HOMEPAGE_SECONDARY_PROMOTION',
  HOMEPAGE_SUGGESTED_CATEGORY = 'HOMEPAGE_SUGGESTED_CATEGORY',
  HOMEPAGE_TERTIARY = 'HOMEPAGE_TERTIARY',
  LISTING_BANNER = 'LISTING_BANNER',
  LISTING_BOTTOM_INSPIRATIONAL = 'LISTING_BOTTOM_INSPIRATIONAL',
  LISTING_BOTTOM_TECHNICAL = 'LISTING_BOTTOM_TECHNICAL',
  LISTING_MIDDLE_INSPIRATIONAL = 'LISTING_MIDDLE_INSPIRATIONAL',
  LISTING_MIDDLE_TECHNICAL = 'LISTING_MIDDLE_TECHNICAL',
  MENU_CATEGORY = 'MENU_CATEGORY',
  MIDDLE_BANNER = 'MIDDLE_BANNER',
  ONGLET_BANNER = 'ONGLET_BANNER',
  PRO_LANDING_PAGE_MAIN = 'PRO_LANDING_PAGE_MAIN',
  TOP_BANNER = 'TOP_BANNER',
}

/** Base type for each qualification, either parent or children */
export type BaseQualification = {
  __typename?: 'BaseQualification';
  /** The code for a given qualification */
  qualificationCode: Scalars['String']['output'];
  /** The recipient for a given qualification */
  recipient: Recipient;
  /** The translated qualification based on an specific platform */
  translation?: Maybe<Scalars['String']['output']>;
};

/**
 * BestCategory type
 *
 * Represents the best category for a given query, based on the platform and market.
 */
export type BestCategory = {
  __typename?: 'BestCategory';
  /** Category details for the best category */
  category?: Maybe<MerchandisingCategory>;
  /**
   * Whether the category is reliable as the best category.
   * This information is useful to automatically select the best category on a search listing page.
   */
  isReliable: Scalars['Boolean']['output'];
};

/** Response type for bestOffers query */
export type BestOffers = {
  __typename?: 'BestOffers';
  /** A list of best offers in a OfferRecommendations format */
  offerRecommendations?: Maybe<Array<OfferRecommendations>>;
};

/** Digest of best sellers */
export type BestSellersDigest = {
  __typename?: 'BestSellersDigest';
  /** Best sellers for a family */
  bestSellersForFamily: Array<BestSellersForFamily>;
};

/** Best seller for a specific family */
export type BestSellersForFamily = {
  __typename?: 'BestSellersForFamily';
  /** Categories associated to the best sellers */
  categories: Array<Maybe<MerchandisingCategory>>;
  /** End date of the digest */
  endDate: Scalars['Date']['output'];
  /** Family of the best sellers */
  family: MerchandisingCategory;
  /** Ten first best sellers */
  productDigest: Array<Maybe<ChannelOffer>>;
  /** Best sellers grouped */
  productGroups: Array<Maybe<ChannelOffers>>;
  /** Total number of products */
  productsCount: Scalars['Int']['output'];
  /** Start date of the digest */
  startDate: Scalars['Date']['output'];
};

/** Billing frequency for a subscription plan */
export enum BillingFrequency {
  /** Monthly billing */
  MONTHLY = 'MONTHLY',
  /** Yearly billing */
  YEARLY = 'YEARLY',
}

/**
 * Type BlockHeroHeader
 * Represents a block containing a hero header with an image for a landing page.
 */
export type BlockHeroHeader = {
  __typename?: 'BlockHeroHeader';
  /** The image used in the hero header. This field is required. */
  image: LandingPageImage;
};

/**
 * Type BlockLinkCardsList
 * Represents a block containing a list of link cards.
 */
export type BlockLinkCardsList = {
  __typename?: 'BlockLinkCardsList';
  /** The list of link cards in the block. This field is required. */
  linkCards: Array<LandingPageLinkCard>;
  /** The title of the link cards list. */
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * Type BlockText
 * Represents a block containing text content.
 */
export type BlockText = {
  __typename?: 'BlockText';
  /** The text content of the block. */
  content?: Maybe<Scalars['String']['output']>;
};

/**
 * Type BlockTextHeader
 * Represents a block that is a text header
 */
export type BlockTextHeader = {
  __typename?: 'BlockTextHeader';
  /** The text content of the block. */
  content?: Maybe<Scalars['String']['output']>;
  /** The image used with the text header. This field is required. */
  image: LandingPageImage;
  /** The block Call-To-Action link. */
  link?: Maybe<LandingPageCTA>;
  /** Title to use with the image. */
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * Type BlockTextImage
 * Represents a text block associated with an illustrative image.
 */
export type BlockTextImage = {
  __typename?: 'BlockTextImage';
  /** The text content of the block. */
  content?: Maybe<Scalars['String']['output']>;
  /** The image is always required for this block. */
  image: LandingPageImage;
  /** The layout type to use with this block - either "primary" or "secondary" */
  layout?: Maybe<Scalars['String']['output']>;
  /** The block Call-To-Action link. */
  link?: Maybe<LandingPageCTA>;
  /** Title to use with the image */
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * Type BlockTitle
 * Represents a block that is a title
 */
export type BlockTitle = {
  __typename?: 'BlockTitle';
  /** The text content of the block. */
  content?: Maybe<Scalars['String']['output']>;
};

/** Brand data */
export type Brand = {
  __typename?: 'Brand';
  /** Brand identifier */
  brandId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  /** Associated market */
  market: Market;
  /** Associated platform */
  platform: Platform;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Content information for a given page */
export type BrandPage = PageMeshing & {
  __typename?: 'BrandPage';
  /** Block of links for bots */
  botLinks: Array<MeshingLink>;
  /** Associated platform */
  platform: Platform;
  /** URL of the page */
  url: Scalars['String']['output'];
  /** Primary block of links for user */
  userPrimaryLinks: Array<MeshingLink>;
  /** Secondary block of links for user */
  userSecondaryLinks: Array<MeshingLink>;
};

/** Item of the breadcrumb */
export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  /** Category id of the breadcrumb item */
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** Text of the breadcrumb item */
  displayText: Scalars['String']['output'];
  /** URL of the breadcrumb item */
  url?: Maybe<Scalars['String']['output']>;
};

/** BulkDiscount: container type for bulk discounts */
export type BulkDiscount = {
  __typename?: 'BulkDiscount';
  /** market */
  market: Market;
  /** platform */
  platform: Platform;
  /** product id */
  productId: Scalars['Int']['output'];
  /** thresholds: array of different discount thresholds */
  thresholds?: Maybe<Array<Maybe<BulkDiscountThreshold>>>;
};

/** BulkDiscountThreshold: type that returns details for each bulk discount */
export type BulkDiscountThreshold = {
  __typename?: 'BulkDiscountThreshold';
  /** maxUnits: maximum number of units to apply the discount */
  maxUnits?: Maybe<Scalars['Int']['output']>;
  /** minUnits: minimum number of units to apply the discount */
  minUnits: Scalars['Int']['output'];
  /** percentage: discount percentage to apply when buying several units between minUnits and maxUnits */
  percentage: Scalars['BigDecimal']['output'];
};

/** A CTA is a link with a simple text */
export type CTA = {
  __typename?: 'CTA';
  /** text */
  text: Scalars['String']['output'];
  /** url */
  url: Scalars['String']['output'];
};

/** CacheControlScope */
export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Enumeration of canals */
export enum Canal {
  APP = 'APP',
  WEBSITE = 'WEBSITE',
}

/** Product Model Carbon Score */
export type CarbonScore = {
  __typename?: 'CarbonScore';
  /** calculation methodology description */
  calculation_methodology: Scalars['String']['output'];
  /** carbon score data related to the product category */
  external_category: CarbonScoreExternalCategory;
  /** carbon score data for this model */
  product: CarbonScoreProduct;
};

/** Carbon score data related to the product category */
export type CarbonScoreExternalCategory = {
  __typename?: 'CarbonScoreExternalCategory';
  /** category average carbon score footprint in kg CO2e */
  average_carbon_footprint: Scalars['Float']['output'];
  /** category average carbon score rating */
  average_carbon_score: CarbonScoreRating;
  /** average position of products in category (int64) */
  average_position: Scalars['String']['output'];
  /** category max carbon score footprint in kg CO2e */
  max_carbon_footprint: Scalars['Float']['output'];
  /** category min carbon score footprint in kg CO2e */
  min_carbon_footprint: Scalars['Float']['output'];
  /** total count of product scored in this category (int64) */
  total_products_count: Scalars['String']['output'];
};

/** Carbon score data related to a model */
export type CarbonScoreProduct = {
  __typename?: 'CarbonScoreProduct';
  /** carbon score footprint in kg CO2e */
  carbon_footprint: Scalars['Float']['output'];
  /** carbon score rating */
  carbon_score: CarbonScoreRating;
  /** confidence indicator from 1 to 5 (the lower the better) */
  confidence_indicator: Scalars['Int']['output'];
  /** how is the carbon score footprint is dispatched through the product lifecycle */
  lifecycle_assessment: CarbonScoreProductLifecycleAssessment;
  /** position in its category (int64) */
  position_in_category: Scalars['String']['output'];
};

/** Carbon score footprint dispatch through the product lifecycle */
export type CarbonScoreProductLifecycleAssessment = {
  __typename?: 'CarbonScoreProductLifecycleAssessment';
  /** end of life footprint in kg CO2e */
  end_of_life_carbon_footprint: Scalars['Float']['output'];
  /** manufacturing footprint in kg CO2e */
  manufacturing_carbon_footprint: Scalars['Float']['output'];
  /** material extraction footprint in kg CO2e */
  material_extraction_carbon_footprint: Scalars['Float']['output'];
  /** transportation footprint in kg CO2e */
  transportation_carbon_footprint: Scalars['Float']['output'];
  /** usage footprint in kg CO2e */
  usage_carbon_footprint: Scalars['Float']['output'];
};

/** Carbon score rating from A to E (A the better) */
export enum CarbonScoreRating {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

/** Card brand */
export enum CardBrand {
  AMEX = 'AMEX',
  CB = 'CB',
  MASTERCARD = 'MASTERCARD',
  UNIONPAY = 'UNIONPAY',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA',
}

/** Card details */
export type CardDetails = {
  __typename?: 'CardDetails';
  /** Bank issuing the card */
  bank: Scalars['String']['output'];
  /** Card brand (e.g. cb, visa, mastercard) */
  brand: CardBrand;
  /** Country of the bank from which the card is issuing */
  country?: Maybe<Scalars['String']['output']>;
  /** Card description */
  description?: Maybe<Scalars['String']['output']>;
  /** Funding type of the card (e.g. debit, credit, prepaid) */
  funding?: Maybe<CardFunding>;
  /** Card network (e.g. cb, visa) */
  network?: Maybe<Scalars['String']['output']>;
  /** 3DS flow (e.g. challenge, frictionless) */
  threeDSecureFlow?: Maybe<Scalars['String']['output']>;
  /** 3DS result (e.g. authenticated, exempted) */
  threeDSecureResult: Scalars['String']['output'];
  /** 3DS version (e.g. 1.0.2, 2.0.0) */
  threeDSecureVersion?: Maybe<Scalars['String']['output']>;
};

/** Funding type of the card */
export enum CardFunding {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
  UNKNOWN = 'UNKNOWN',
}

/** Amount with value and currency */
export type CartAmount = {
  /** Numeric value of the amount */
  amount: Scalars['Float']['input'];
  /** Currency of the amount */
  currency: Currency;
};

/** Cart computation */
export type CartComputation = {
  __typename?: 'CartComputation';
  /** cart name */
  cartName: Scalars['String']['output'];
  /** list of dispatches */
  dispatches?: Maybe<Array<Dispatch>>;
  /** estimated shipping price */
  estimatedShippingPriceVatIncl?: Maybe<ComputationPrice>;
  /** delivery discount price */
  manomanoDeliveryDiscount: ComputationPrice;
  /** list of products */
  products: Array<ComputationProduct>;
  /** total price to be paid by the customer */
  totalCartPriceToBePaidByTheCustomerVatIncl: ComputationPrice;
  /** total estimated shipping price to be paid by the customer */
  totalEstimatedShippingPriceToBePaidByTheCustomerVatIncl: ComputationPrice;
  /** total price of products */
  totalProductPrice?: Maybe<ComputationPriceWithVat>;
};

/** An item to purchase */
export type CartItem = {
  __typename?: 'CartItem';
  /** The representation of the item on the channel-offers subgraph */
  product: ChannelOffer;
  /** How many of this item are present in the Cart */
  quantity: Scalars['Int']['output'];
};

/**
 * A cashback offer is an offer proposed by the product manufacturer.
 *
 * After a customer buys the product, it can follow a link that will reimburse part of the cost of the product directly to
 * the customer. This offer does not go through the usual payment channels proposed by ManoMano. The proposed link will oftentimes
 * redirect to a PDF file explaining the cashback process - which can vary depending on the manufacturer.
 *
 * Cashback offers should be visible when roaming the website products, on the products which propose it.
 *
 * Note that cashback offers are attached to offers rather than master products/SKU to allow maximum control over where the
 * cashback offers will be displayed. Some cashback offers are not available in all countries/platforms, for instance.
 */
export type CashbackOffer = {
  __typename?: 'CashbackOffer';
  /** Price to display when advertising the offer on the website. The consumer should always add the currency of the platform to this price for a comprehensive user experience. Either this or displayLabel should always be available. */
  displayAmount?: Maybe<Scalars['Int']['output']>;
  /** Label to display directly when advertising the offer on the website. Either this or displayAmount should always be available. */
  displayLabel?: Maybe<Scalars['String']['output']>;
  /** Mandatory link to an external page, allowing to benefit from the cashback offer. */
  link: CashbackOfferLink;
  /** Internal name for this cashback offer. This convenience name is human-friendly, and used for tracking purposes. */
  name: Scalars['String']['output'];
};

/**
 * Representation of a CashbackOffer link.
 *
 * Contains everything needed to forward users to the cashback offer link, which describes the process to obtain a cashback.
 */
export type CashbackOfferLink = {
  __typename?: 'CashbackOfferLink';
  /** URL target, forwarded to the browser so as to know how the link should be opened. */
  target: CashbackOfferLinkTarget;
  /** URL to open. */
  url: Scalars['String']['output'];
};

/** Possible values for the cashback offer link target */
export enum CashbackOfferLinkTarget {
  _blank = '_blank',
  _download = '_download',
}

/** CatalogAttribute */
export type CatalogAttribute = {
  __typename?: 'CatalogAttribute';
  /** attribute: Attribute */
  attribute?: Maybe<Attribute>;
  /** unit: String */
  unit?: Maybe<Scalars['String']['output']>;
  /** value: String */
  value?: Maybe<Scalars['String']['output']>;
};

/** Category */
export type Category = {
  __typename?: 'Category';
  /** All advice associated to the category */
  advice?: Maybe<CategoryAdvice>;
  /** Ancestor categories */
  ancestors: Array<RelatedCategory>;
  /** Id of the category */
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** Children categories */
  children: Array<RelatedCategory>;
  /** Images of the category */
  images?: Maybe<Array<Image>>;
  /** Is visible attribute */
  isVisible: Scalars['Boolean']['output'];
  /** Level */
  level: Scalars['Int']['output'];
  /** Market of the category. B2B or B2C */
  market: Market;
  /** Name for the category */
  name: Scalars['String']['output'];
  /** Platform of the category */
  platform: Platform;
  /** Siblings categories */
  siblings: Array<RelatedCategory>;
  /** Slug */
  slug: Scalars['String']['output'];
  /** Sort */
  sort: Scalars['Int']['output'];
};

/** A Category Advice represent all advice associated to a category */
export type CategoryAdvice = {
  __typename?: 'CategoryAdvice';
  /** Id of the category */
  categoryId: Scalars['Int']['output'];
  /** guides */
  guides: Array<Guide>;
  /** Market of the category. B2B or B2C */
  market: Market;
  /** Platform of the category */
  platform: Platform;
  /** snackAdvice */
  snackAdvice: Array<SnackAdvice>;
};

/** Content of a category page */
export type CategoryPage = PageMeshing & {
  __typename?: 'CategoryPage';
  /** Block of links for bots */
  botLinks: Array<MeshingLink>;
  /** Category of the page */
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** Associated platform */
  platform: Platform;
  /** URL of the page */
  url: Scalars['String']['output'];
  /** Primary block of links for user */
  userPrimaryLinks: Array<MeshingLink>;
  /** Secondary block of links for user */
  userSecondaryLinks: Array<MeshingLink>;
};

/** Channel enum */
export enum Channel {
  /** Mobile context */
  MOBILE = 'MOBILE',
  /** Web context */
  WEB = 'WEB',
}

/**
 * ChannelBrand combines the base brand information with platform-specific brand data.
 * The brand could be ACTIVE or INACTIVE.
 */
export type ChannelBrand = {
  __typename?: 'ChannelBrand';
  /** Platform-specific brand description. */
  description?: Maybe<Scalars['String']['output']>;
  /** Brand UUIDv4 (for example '8057327e-0794-4c45-8300-19763ce0ae62') */
  id: Scalars['ID']['output'];
  /** Platform-specific brand logo, if it is available. Base brand logo otherwise. */
  logo?: Maybe<Scalars['String']['output']>;
  /** Platform, for which platform-specific brand data and overrides are fetched */
  platform: Platform;
  /** Brand Public Identifier (for example '16' for Makita) */
  publicId: Scalars['Int']['output'];
  /**
   * Brand slug, created from the base title (not platform override), and is URL-safe.
   * For example for title '3 RG INDUSTRIAL' slug is '3-rg-industrial'
   */
  slug: Scalars['String']['output'];
  /** Platform-specific brand title, if it is available. Base brand title otherwise. */
  title: Scalars['String']['output'];
};

/** ChannelOffer extension */
export type ChannelOffer = {
  __typename?: 'ChannelOffer';
  /**
   * Available Stock for Sale (= Physical Stock - Reserved Stock)
   * Value could be null if the offer is not known by the service
   */
  availableStock?: Maybe<Scalars['Int']['output']>;
  /** Bulk discount */
  bulkDiscount?: Maybe<BulkDiscount>;
  /**
   * Cashback offer associated to this offer.
   *
   * Cashback offers are unique by platform and market.
   */
  cashbackOffer?: Maybe<CashbackOffer>;
  /** Precalculated delivery promise, the computation is live for non manofulfillment offer and precalculated for manofulfillment one. Performance is better than deliveryOffers but contains basic information */
  coldDeliveryOffers?: Maybe<ColdDeliveryOffers>;
  /**
   * Commercial animation attributes associated to this offer.
   *
   * It contains a list of promo tags and the furthest end date of the live operations associated to the offer.
   */
  commercialAnimation?: Maybe<CommercialAnimationAttributes>;
  /**
   * Competing offers
   * Given this offer, the competing offers corresponding to this offer sku and requested SaleChannel
   */
  competingOffers: Array<ChannelOffer>;
  /**
   * Competing offers count
   * Given this offer, the number of competing offers corresponding to this offer sku and requested SaleChannel
   * example value : 3
   */
  competingOffersCount: Scalars['Int']['output'];
  /** Coupon linked to the product */
  coupon?: Maybe<Coupon>;
  /** Live delivery promise, to use only on product page, computation is live and can be costly and low, mainly for manofulfillment offer */
  deliveryOffers?: Maybe<Array<DeliveryOffer>>;
  /**
   * offer id (omOfferId) - uuid
   * example value : "e3a81ffe-0e78-4622-b417-940f4b600a1e"
   */
  id: Scalars['String']['output'];
  /**
   * Am I the best offer?
   * Given a list of offers present for a sku and a SaleChannel, the best offer is the one who wins the paybox - the most competitive one (with a fallback on the lowest price)
   */
  isBestOffer: Scalars['Boolean']['output'];
  /** 'Cache-Control: public, max-age=3600' Is selection Manomano (isBestQualityOffer) */
  isSelectionManomano?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Am I sellable? Can a marketplace customer add me to its cart?
   * A temporarily out of stock offer is non sellable
   * Always true when offer options input query argument acceptNonSellable is false (its default value)
   */
  isSellable: Scalars['Boolean']['output'];
  /** 'Cache-Control: public, max-age=3600' This parameter comes from current_top_sales.csv file. From Data Team. */
  isTopSales?: Maybe<Scalars['Boolean']['output']>;
  /** The latest reviews for the Sku Master Product */
  latestReviews?: Maybe<LatestReviews>;
  /**
   * Offer Market
   * If the requested SaleChannel Market is B2C, then the only possible market value is B2C
   * If the requested SaleChannel Market is B2B, then the possible market values is are both B2C & B2B (B2C offers are also exposed to the B2B market except when the underlying seller is opted-out)
   */
  market: Market;
  /** packaging */
  packaging: OfferPackaging;
  /**
   * Offer Platform
   * Having the same value as the one provided as input on the queries or through the federated keys
   */
  platform: Platform;
  /** pricing */
  pricing: OfferPricing;
  /** Offer id in the monechelle catalog */
  productId: Scalars['String']['output'];
  /** The rating related with the offer */
  productRating?: Maybe<ArticleRatingStats>;
  /** Get mds stats for an article id, category id and platform */
  productRatingMds?: Maybe<Array<Maybe<ProductRatingMd>>>;
  /** The ProductReviewPictures */
  productReviewPictures?: Maybe<Array<Maybe<ProductReviewPicture>>>;
  /**
   * Sale channel representing the marketplace on which the offer is intended to be exposed
   * Same value as the one provided as input on the queries or through the federated keys
   */
  saleChannel: SaleChannel;
  /** seller */
  seller: OfferSeller;
  /** Federated seller contract */
  sellerContract?: Maybe<SellerContract>;
  /** sku */
  sku: ChannelOfferSku;
  /**
   * Variants count
   * Given this offer, the count of sku variations corresponding to this offer master product and requested SaleChannel
   * example value : 5
   */
  variantsCount: Scalars['Int']['output'];
  /** weight in grams */
  weightInGrams?: Maybe<Scalars['Int']['output']>;
};

/**
 * A Stock Keeping Unit
 * A particular variation of a given MasterProduct
 * For example : A pair of CARHARTT "Hamilton" security shoes, color black, size 42
 */
export type ChannelOfferSku = {
  __typename?: 'ChannelOfferSku';
  /** attributes */
  attributes: Array<ChannelOfferSkuAttribute>;
  /**
   * carbon score
   * Present only for eligible categories
   */
  carbonScore?: Maybe<ChannelOfferSkuCarbonScore>;
  /** description */
  description: ChannelOfferSkuDescription;
  /**
   * ean
   * example value : "0889192818378"
   */
  ean?: Maybe<Scalars['String']['output']>;
  /** images */
  images: Array<ChannelOfferSkuImage>;
  /** manufacturer */
  manufacturer: ChannelOfferSkuManufacturer;
  /** master product */
  masterProduct: ChannelOfferSkuMasterProduct;
  /** merchandising category */
  merchandisingCategory?: Maybe<MerchandisingCategory>;
  /**
   * id - long
   * example value : "401998243062"
   */
  mmId: Scalars['String']['output'];
  /**
   * sku id in the monechelle catalog
   * example value : "16672954"
   */
  modelId: Scalars['String']['output'];
  /**
   * reference (ME<modelId>)
   * example value : "ME16672954"
   */
  reference: Scalars['String']['output'];
  /** repairability */
  repairability: ChannelOfferSkuRepairability;
  /**
   * sku reference in the seller catalog
   * example value : "DEW00072"
   */
  sellerReference?: Maybe<Scalars['String']['output']>;
  /**
   * title
   * example value : "Chaussures de sécurité Hamilton Waterproof Wedge Boot CARHARTT P.39 - S1F702901232S39"
   */
  title?: Maybe<Scalars['String']['output']>;
};

/** A numeric attribute */
export type ChannelOfferSkuAttribute = {
  __typename?: 'ChannelOfferSkuAttribute';
  /** Id */
  id: Scalars['Int']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Order */
  order: Scalars['Int']['output'];
  /** Type the attribute, can be: 'textual', 'numerical' or 'boolean' */
  type: Scalars['String']['output'];
  /** Unit */
  unit?: Maybe<ChannelOfferSkuAttributeUnit>;
  /** ValueType of the attribute, can be: 'numerical', 'textual' or 'boolean' */
  valueType: Scalars['String']['output'];
  /** Values of the attribute */
  values: Array<ChannelOfferSkuAttributeValue>;
};

/** A unit assiocated with a variant attribute */
export type ChannelOfferSkuAttributeUnit = {
  __typename?: 'ChannelOfferSkuAttributeUnit';
  /** Abbreviation */
  abbreviation?: Maybe<Scalars['String']['output']>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Display abbreviation */
  displayAbbreviation?: Maybe<Scalars['Boolean']['output']>;
  /** Id */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
};

/**
 * A value for an attribute. Depending on valueType it can be:
 * - numerical: "12.01"
 * - textual: "text"
 * - boolean: "true"
 */
export type ChannelOfferSkuAttributeValue = {
  __typename?: 'ChannelOfferSkuAttributeValue';
  /** The url for this attribute value */
  colorHex?: Maybe<Scalars['String']['output']>;
  /** Value */
  value: Scalars['String']['output'];
};

/**
 * SKU carbon score information
 * Composed by a calculation methodology description, a carbon score for the sku category and a carbon score for this sku
 */
export type ChannelOfferSkuCarbonScore = {
  __typename?: 'ChannelOfferSkuCarbonScore';
  /**
   * calculation methodology description
   * example value : "Pour estimer l'empreinte carbone de ce produit, ManoMano réalise une analyse du cycle de vie (ACV) simplifiée..."
   */
  calculationMethodology: Scalars['String']['output'];
  /** carbon score related to the category */
  externalCategoryCarbonScore: ChannelOfferSkuCarbonScoreExternalCategory;
  /** carbon score data for this sku */
  skuCarbonScore: ChannelOfferSkuCarbonScoreSku;
};

/** Carbon score data related to an external category */
export type ChannelOfferSkuCarbonScoreExternalCategory = {
  __typename?: 'ChannelOfferSkuCarbonScoreExternalCategory';
  /**
   * category average carbon score footprint in kg CO2e
   * example value : 104.0
   */
  averageCarbonFootprint: Scalars['Float']['output'];
  /**
   * average position of products in category - long
   * example value : "15"
   */
  averagePosition: Scalars['String']['output'];
  /**
   * category average carbon score rating
   * example value : E
   */
  averageRating: CarbonScoreRating;
  /**
   * category maximum carbon score footprint in kg CO2e
   * example value : 3560.0
   */
  maximumCarbonFootprint: Scalars['Float']['output'];
  /**
   * category minimum carbon score footprint in kg CO2e
   * example value : 2.31
   */
  minimumCarbonFootprint: Scalars['Float']['output'];
  /**
   * total count of sku scored in this category - long
   * example value : "1614"
   */
  productCount: Scalars['String']['output'];
};

/** Carbon score footprint dispatch through the product lifecycle */
export type ChannelOfferSkuCarbonScoreLifecycleAssessment = {
  __typename?: 'ChannelOfferSkuCarbonScoreLifecycleAssessment';
  /**
   * end of life footprint in kg CO2e
   * example value : 13.0
   */
  endOfLifeCarbonFootprint: Scalars['Float']['output'];
  /**
   * manufacturing footprint in kg CO2e
   * example value : 5.0
   */
  manufacturingCarbonFootprint: Scalars['Float']['output'];
  /**
   * material extraction footprint in kg CO2e
   * example value : 108.0
   */
  materialExtractionCarbonFootprint: Scalars['Float']['output'];
  /**
   * transportation footprint in kg CO2e
   * example value : 10.0
   */
  transportationCarbonFootprint: Scalars['Float']['output'];
  /**
   * usage footprint in kg CO2e
   * example value : 0.0
   */
  usageCarbonFootprint: Scalars['Float']['output'];
};

/** Carbon score data related to a sku */
export type ChannelOfferSkuCarbonScoreSku = {
  __typename?: 'ChannelOfferSkuCarbonScoreSku';
  /**
   * carbon score footprint in kg CO2e
   * example value : 131.0
   */
  carbonFootprint: Scalars['Float']['output'];
  /**
   * confidence indicator from 1 to 5 (the lower the better)
   * example value : 3
   */
  confidenceIndicator: Scalars['Int']['output'];
  /** how is the carbon score footprint is dispatched through the product lifecycle */
  lifecycleAssessment: ChannelOfferSkuCarbonScoreLifecycleAssessment;
  /**
   * position in its category - long
   * example value : "15"
   */
  positionInCategory: Scalars['String']['output'];
  /**
   * carbon score rating
   * example value : A
   */
  rating: CarbonScoreRating;
};

/** SKU description */
export type ChannelOfferSkuDescription = {
  __typename?: 'ChannelOfferSkuDescription';
  /**
   * information pdf url
   * example value : "https://cdn.manomano.com/pim-dam-img/path/to/the/document.pdf"
   */
  informationPdfUrl?: Maybe<Scalars['String']['output']>;
  /**
   * instructions pdf url
   * example value : "https://cdn.manomano.com/pim-dam-img/path/to/the/document.pdf"
   */
  instructionsPdfUrl?: Maybe<Scalars['String']['output']>;
  /**
   * refrigeration devices information pdf url
   * example value : "https://cdn.manomano.com/pim-dam-img/path/to/the/document.pdf"
   */
  refrigerationDevicesInformationPdfUrl?: Maybe<Scalars['String']['output']>;
  /**
   * safety information pdf url
   * example value : "https://cdn.manomano.com/pim-dam-img/path/to/the/document.pdf"
   */
  safetyInformationPdfUrl?: Maybe<Scalars['String']['output']>;
  /**
   * textual description
   * example value : "<ul><li>Chaussure de sécurité S3 Premium haute à lacets avec embout type mocassin et membrane Carhartt imper-respirante. </li><li>Tige en cuir gras pleine fleur.</li>...</ul>"
   */
  textual?: Maybe<Scalars['String']['output']>;
};

/** SKU images on different sizes */
export type ChannelOfferSkuImage = {
  __typename?: 'ChannelOfferSkuImage';
  /**
   * large url
   * example value : "/images/images_products/12/L/28998396_1.jpg"
   */
  largeUrl: Scalars['String']['output'];
  /**
   * regular url
   * example value : "/images/images_products/12/P/28998396_1.jpg"
   */
  regularUrl?: Maybe<Scalars['String']['output']>;
  /**
   * thumbnail url
   * example value : "/images/images_products/12/T/28998396_1.jpg"
   */
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

/** Offer SKU manufacturer */
export type ChannelOfferSkuManufacturer = {
  __typename?: 'ChannelOfferSkuManufacturer';
  /**
   * pdf document url
   * example value : "https://cdn.manomano.com/pim-dam-img/path/to/the/document.pdf"
   */
  pdfUrl?: Maybe<Scalars['String']['output']>;
  /**
   * SKU identifier (alphanumeric code that may include a combination of letters, numbers, and sometimes symbols)
   * example value : "TTA50035"
   */
  sku?: Maybe<Scalars['String']['output']>;
};

/** ChannelOfferSkuMasterProduct representation */
export type ChannelOfferSkuMasterProduct = {
  __typename?: 'ChannelOfferSkuMasterProduct';
  /**
   * master product id in the monechelle catalog
   * example value : "36173880"
   */
  articleId: Scalars['String']['output'];
  /** brand */
  brand?: Maybe<ChannelBrand>;
  /**
   * category
   * @deprecated Use 'ChannelOfferSku.merchandisingCategory' instead.
   */
  category?: Maybe<Category>;
  /**
   * id - long
   * example value : "27084151"
   */
  id: Scalars['String']['output'];
  /**
   * master category id
   * example value : "21687"
   */
  masterCategoryId?: Maybe<Scalars['String']['output']>;
  /**
   * slug computed from master product title
   * example value : "carharrt-f702901-hamilton-s3-waterproof-wedge-boot-tan-46"
   */
  slug: Scalars['String']['output'];
  /**
   * variant experience
   * example value: "REGULAR"
   */
  variantExperience: VariantExperience;
};

/** SKU repairability information */
export type ChannelOfferSkuRepairability = {
  __typename?: 'ChannelOfferSkuRepairability';
  /** repairability index */
  index?: Maybe<Scalars['Float']['output']>;
  /**
   * pdf url
   * example value : "https://cdn.manomano.com/pim-dam-img/path/to/the/document.pdf"
   */
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

/** ChannelOffers external entity */
export type ChannelOffers = {
  __typename?: 'ChannelOffers';
  /**
   * List of master product ids in the monechelle catalog
   * Use it to federate a collection of ChannelOffer by master product ids
   */
  articleIds?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * List of sku ids in the monechelle catalog
   * Use it to federate a collection of ChannelOffer by sku ids
   */
  modelIds?: Maybe<Array<Scalars['String']['output']>>;
  /** List of sellable offers corresponding to the requested SaleChannel and ids */
  offers: Array<ChannelOffer>;
  /**
   * List of offer ids in the monechelle catalog
   * Use it to federate a collection of ChannelOffer by offer ids
   */
  productIds?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Sale channel representing the marketplace on which the offers are intended to be exposed
   * Same value as the one provided through the entity key
   */
  saleChannel: SaleChannel;
};

/** Address input, it's where we will deliver to */
export type CheapestDeliveryAddressInput = {
  /** Example: Hourtin */
  city: Scalars['String']['input'];
  /**
   * Format iso code 3166-1 alpha-2
   * Example: FR
   */
  country: Scalars['String']['input'];
  /** Example: 33990 */
  postalCode: Scalars['String']['input'];
};

/** Cheapest delivery dispatch */
export type CheapestDeliveryDispatch = {
  __typename?: 'CheapestDeliveryDispatch';
  /** List of delivery promises */
  deliveryPromises: Array<CheapestDeliveryDispatchPromise>;
  /**
   * Is the dispatch a manofulfillment one
   * Example: true
   */
  isManoFulfillment: Scalars['Boolean']['output'];
};

/** Cheapest Delivery promise */
export type CheapestDeliveryDispatchPromise = {
  __typename?: 'CheapestDeliveryDispatchPromise';
  /** Free delivery information (only when a free delivery threshold is configured) */
  freeDelivery?: Maybe<CheapestDeliveryFreeDelivery>;
  /**
   * The delivery mode of the used carrier for this promise.
   * Non exhaustive example:
   * EXPRESS_RELAY_POINT
   * RELAY_POINT
   * PRE_ORDER_SCHEDULED_DELIVERY_AT_STREET
   * PRE_ORDER_SCHEDULED_DELIVERY_ROOM_OF_CHOICE
   * EXPRESS_HOME_DELIVERY
   * HOME_DELIVER
   * ROOM_OF_CHOICE
   */
  mode: Scalars['String']['output'];
  /** Delivery period dates for this delivery promise */
  period: CheapestDeliveryDispatchPromisePeriod;
};

/** Period */
export type CheapestDeliveryDispatchPromisePeriod = {
  __typename?: 'CheapestDeliveryDispatchPromisePeriod';
  /**
   * The cutoff is the expiration time of the delivery promise.
   * Ordering after the cutoff will shift the delivery promise to the next opening day.
   * Date-time with an offset from UTC/Greenwich in the ISO-8601 calendar system
   * Example: '2021-10-14T00:00:00+02:00'
   */
  cutoff?: Maybe<Scalars['String']['output']>;
  /** Express mode can have 2 values : '24h' or '48h'. It can also be null for non express mode */
  expressMode?: Maybe<Scalars['String']['output']>;
  /**
   * Latest delivery date-time with an offset from UTC/Greenwich in the ISO-8601 calendar system.
   * Example: '2021-10-14T00:00:00+02:00'
   */
  max: Scalars['String']['output'];
  /**
   * Earliest delivery date-time with an offset from UTC/Greenwich in the ISO-8601 calendar system.
   * Example: '2021-10-14T00:00:00+02:00'
   */
  min: Scalars['String']['output'];
};

/** Free delivery information */
export type CheapestDeliveryFreeDelivery = {
  __typename?: 'CheapestDeliveryFreeDelivery';
  /** Indicates whether or not the free delivery threshold has been reached */
  isReached: Scalars['Boolean']['output'];
  /** Price to reach to benefits of the free delivery */
  thresholdVatIncluded: CustomerDeliveryPrice;
};

/** Request object for a compute cheapest delivery action */
export type CheapestDeliveryInput = {
  /** The delivery address wanted for the delivery */
  deliveryAddress: CheapestDeliveryAddressInput;
  /** List of products and quantities, with their prices */
  items: Array<CheapestDeliveryItemsInput>;
  /** Market */
  market: Market;
  /** Platform */
  platform: Platform;
};

/** Quantity and price for a product id */
export type CheapestDeliveryItemsInput = {
  /** Example: EUR */
  currency: Scalars['String']['input'];
  /**
   * Product id - Long
   * Example: 1204935
   */
  productId: Scalars['String']['input'];
  /** Minimum: 1, Example: 1 */
  quantity: Scalars['Int']['input'];
  /** Example: 64.95 */
  unitPriceVatIncluded: Scalars['Float']['input'];
};

/** Cobid Customer Order Id creation */
export type CobiCustomerOrderId = {
  __typename?: 'CobiCustomerOrderId';
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
};

/** Pre-calculated cold delivery promise */
export type ColdDeliveryOffer = {
  __typename?: 'ColdDeliveryOffer';
  /** Delivery price */
  deliveryPrice: DeliveryPrice;
  /** Delivery window in hours */
  deliveryTime: DeliveryTimeHours;
};

/** Pre-calculated cold delivery promise information */
export type ColdDeliveryOffers = {
  __typename?: 'ColdDeliveryOffers';
  /** Cheapest pre-calculated cold delivery promise */
  cheapestOffer?: Maybe<ColdDeliveryOffer>;
  /** Cheapest pre-calculated cold delivery promise without relay point */
  cheapestOfferExcludingRelay?: Maybe<ColdDeliveryOffer>;
  /** Fastest pre-calculated cold delivery promise */
  fastestOffer?: Maybe<ColdDeliveryOffer>;
  /** Define if we have at least one offer delivered in 24/48 hours */
  hasExpressOffer: Scalars['Boolean']['output'];
  /** Define if we have at least one offer fulfilled in a relay point */
  hasRelayOffer: Scalars['Boolean']['output'];
  /**
   * Is the product a manofulfillment one
   * Example: true
   */
  isMf: Scalars['Boolean']['output'];
};

/**
 * CommercialAnimationAttributes type
 * Contains a list of promotional tags (labels promo, Black Friday, sales, ManoManoDays)
 * and the furthest end date of the live promo operations associated to the offer (or nil if one of them is infinite)
 */
export type CommercialAnimationAttributes = {
  __typename?: 'CommercialAnimationAttributes';
  /** Furthest end date of the live operations associated to the offer */
  endAt?: Maybe<Scalars['DateTime']['output']>;
  /** List of tags linked to the offer */
  tags: Array<Scalars['String']['output']>;
};

/**
 * CommercialOperation type
 * Represents a commercial operation.
 */
export type CommercialOperation = {
  __typename?: 'CommercialOperation';
  /** The claim of the commercial operation. */
  claim?: Maybe<Scalars['String']['output']>;
  /** The description of the commercial operation. */
  description?: Maybe<Scalars['String']['output']>;
  /** The end timestamp of the commercial operation. */
  endAt?: Maybe<Scalars['Int']['output']>;
  /** The grouping facet of the commercial operation. */
  groupingFacet: CommercialOperationGroupingFacet;
  /** The header color of the commercial operation. */
  headerColor?: Maybe<Scalars['String']['output']>;
  /** The header image of the commercial operation. */
  headerImage?: Maybe<CommercialOperationHeaderImage>;
  /** The ID of the commercial operation. */
  id: Scalars['Int']['output'];
  /** The legal mention of the commercial operation. */
  legalMention?: Maybe<Scalars['String']['output']>;
  /** The name of the commercial operation. */
  name: Scalars['String']['output'];
  /** The platform of the commercial operation. */
  platform: Platform;
  /** The slug of the commercial operation. */
  slug: Scalars['String']['output'];
  /** The start timestamp of the commercial operation. */
  startAt: Scalars['Int']['output'];
  /** The status of the commercial operation. */
  status?: Maybe<CommercialOperationStatus>;
  /** The template of the commercial operation. */
  template?: Maybe<Scalars['String']['output']>;
  /** The title of the commercial operation. */
  title: Scalars['String']['output'];
  /** The IDs of the top products associated with the commercial operation. */
  topProductIDs: Array<Maybe<Scalars['Int']['output']>>;
  /** The type of the commercial operation. */
  type: CommercialOperationType;
};

/**
 * CommercialOperationGroupingFacet type
 * Represents the grouping facet for a commercial operation.
 */
export type CommercialOperationGroupingFacet = {
  __typename?: 'CommercialOperationGroupingFacet';
  /** The level of the grouping facet. */
  level?: Maybe<CommercialOperationGroupingFacetLevel>;
};

/**
 * CommercialOperationGroupingFacetLevel enum
 * Represents the level of grouping facet for a commercial operation.
 */
export enum CommercialOperationGroupingFacetLevel {
  CATEGORIES_L0_ID = 'CATEGORIES_L0_ID',
  CATEGORIES_L1_ID = 'CATEGORIES_L1_ID',
  CATEGORIES_LAST_ID = 'CATEGORIES_LAST_ID',
  PLAYLIST_ID = 'PLAYLIST_ID',
}

/**
 * CommercialOperationHeaderImage type
 * Represents the header image for a commercial operation.
 */
export type CommercialOperationHeaderImage = {
  __typename?: 'CommercialOperationHeaderImage';
  /** Alternate text for the header image. */
  altText: Scalars['String']['output'];
  /** URL for the header image on desktop devices. */
  desktopUrl?: Maybe<Scalars['String']['output']>;
  /** URL for the header image on mobile devices. */
  mobileUrl?: Maybe<Scalars['String']['output']>;
};

/**
 * CommercialOperationStatus enum
 * Represents the status of a commercial operation.
 */
export enum CommercialOperationStatus {
  ARCHIVED = 'ARCHIVED',
  LIVE = 'LIVE',
  PLANNED = 'PLANNED',
}

/**
 * CommercialOperationType enum
 * Represents the type of a commercial operation.
 */
export enum CommercialOperationType {
  COR = 'COR',
  CORPRO = 'CORPRO',
  FS = 'FS',
  OD = 'OD',
  OTR = 'OTR',
  TF = 'TF',
  TFPRO = 'TFPRO',
  TR = 'TR',
  TRPRO = 'TRPRO',
  TRS = 'TRS',
  TRSPRO = 'TRSPRO',
}

/** Commission type */
export type Commission = {
  __typename?: 'Commission';
  /** amount vat excluded */
  amountVatExcl: Scalars['BigDecimalQl']['output'];
  /** amount vat included */
  amountVatIncl: Scalars['BigDecimalQl']['output'];
  /** baseline amount */
  baselineAmount: Scalars['BigDecimalQl']['output'];
  /** currency */
  currency: Currency;
  /** rate */
  rate: Scalars['BigDecimalQl']['output'];
  /** vat amount */
  vatAmount?: Maybe<Scalars['BigDecimalQl']['output']>;
  /** vat rate */
  vatRate?: Maybe<Scalars['BigDecimalQl']['output']>;
};

/** Company */
export type Company = {
  __typename?: 'Company';
  /** Activity code of the company, following normally NACEv2 standard */
  activityCode?: Maybe<Scalars['String']['output']>;
  /** Company address */
  address: CompanyAddress;
  /** Audience group where the company belongs */
  audienceGroup?: Maybe<AudienceGroup>;
  /** All information about the cross border VAT of the company */
  crossBorderVat: CrossBorderVat;
  /** DUNS number, a unique 9-digit number that identifies companies */
  duns?: Maybe<Scalars['String']['output']>;
  /** Customer first name linked to the company */
  firstName: Scalars['String']['output'];
  /** Fiscal number */
  fiscalNumber?: Maybe<Scalars['String']['output']>;
  /** Customer indicated if it is a sole trader or not */
  isSoleTrader?: Maybe<Scalars['Boolean']['output']>;
  /** Customer last name linked to the company */
  lastName: Scalars['String']['output'];
  /** Company name */
  name?: Maybe<Scalars['String']['output']>;
  /** Company phone number */
  phone: Scalars['String']['output'];
  /** Customer decided to phone opt-out */
  phoneOptOut: Scalars['Boolean']['output'];
  /** Registration date */
  registrationDate: Scalars['Date']['output'];
  /** List of specialties related to the company */
  specialties: Array<Maybe<Scalars['Int']['output']>>;
};

/** Company address */
export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  /** Address */
  address: Scalars['String']['output'];
  /** Address complement */
  addressComplement?: Maybe<Scalars['String']['output']>;
  /** City */
  city: Scalars['String']['output'];
  /** Format iso code 3166-1 alpha-2 (ex: FR, BE, ES, CH, IT, GB, DE) */
  country: Scalars['String']['output'];
  /** County */
  county?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** ZIP code */
  zipCode: Scalars['String']['output'];
};

/** Coupon type */
export type ComputationCoupon = {
  __typename?: 'ComputationCoupon';
  /** Coupon code */
  couponCode: Scalars['String']['output'];
  /** Coupon items */
  couponItems?: Maybe<Array<Maybe<CouponItem>>>;
  /** Coupon value */
  couponValue: Scalars['BigDecimalQl']['output'];
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
};

/** Price type */
export type ComputationPrice = {
  __typename?: 'ComputationPrice';
  /** Price amount */
  amount: Scalars['BigDecimalQl']['output'];
  /** currency */
  currency: Currency;
};

/** Price with VAT type */
export type ComputationPriceWithVat = {
  __typename?: 'ComputationPriceWithVat';
  /** Price amount without VAT */
  amountVatExcl: Scalars['BigDecimalQl']['output'];
  /** Price amount */
  amountVatIncl: Scalars['BigDecimalQl']['output'];
  /** currency */
  currency: Currency;
  /** VAT amount */
  vatAmount: Scalars['BigDecimalQl']['output'];
  /** VAT rate */
  vatRate?: Maybe<Scalars['BigDecimalQl']['output']>;
};

/** Product type */
export type ComputationProduct = {
  __typename?: 'ComputationProduct';
  /** Legacy category id */
  legacyMasterCategoryId: Scalars['Long']['output'];
  /** Order item id */
  orderItemId: Scalars['ID']['output'];
  /** Price without computation */
  originalProductPriceVatIncl?: Maybe<Scalars['BigDecimalQl']['output']>;
  /** Unit price without computation */
  originalUnitProductPriceVatIncl?: Maybe<Scalars['BigDecimalQl']['output']>;
  /** Product id */
  productId: Scalars['Int']['output'];
  /** Product price */
  productPrice: ComputationPriceWithVat;
  /** Quantity */
  quantity: Scalars['Int']['output'];
  /** Unit product price */
  unitProductPrice: ComputationPriceWithVat;
  /** Xborder vat treatment code */
  xborderVatTreatmentCode: Scalars['String']['output'];
};

/** Request object for a compute cart action */
export type ComputeCartTotalInput = {
  app: Scalars['String']['input'];
  cartName: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  market: Scalars['String']['input'];
  platform: Platform;
  visitorId: Scalars['ID']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Request object for a compute customer order action */
export type ComputeCustomerOrderTotalInput = {
  customerOrderId: Scalars['ID']['input'];
  /** if you need to query without coupon */
  withoutCoupon?: InputMaybe<Scalars['Boolean']['input']>;
  /** if you need to query without delivery */
  withoutDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  /** if you need to query without delivery discount */
  withoutDeliveryDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for DeliveryAddressDto */
export type ComputeDeliveryAddressInput = {
  /** Example: Hourtin */
  city: Scalars['String']['input'];
  /** Contact information */
  contact: ComputeDeliveryContactInput;
  /**
   * Format iso code 3166-1 alpha-2
   * Example: FR
   */
  country: Scalars['String']['input'];
  /** Latitude and longitude for this address */
  geoCoordinates?: InputMaybe<ComputeDeliveryGeoCoordinatesInput>;
  /**
   * ID of this address in the seller address book
   * Example: "345"
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Address lines. First elements must not be null
   * Example: ["5 avenue des Jonquilles", "Résidence des Argentiers", "Apt 234"]
   */
  lines: Array<InputMaybe<Scalars['String']['input']>>;
  /** Example: 33990 */
  postalCode: Scalars['String']['input'];
};

/** Input type for ContactDto */
export type ComputeDeliveryContactInput = {
  /** Example: ManoMano */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Example: jean.dujardin+1@manomano.com */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Example: Jean */
  firstname: Scalars['String']['input'];
  /** Example: Dujardin */
  lastname: Scalars['String']['input'];
  /** Example: +33614384450 */
  phone: Scalars['String']['input'];
};

/** Input type for GeoCoordinatesDto */
export type ComputeDeliveryGeoCoordinatesInput = {
  /** Example: 45.1861 */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  /** Example: 1.0572 */
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

/** Request object for a Xborder Output */
export type ComputeXborderInput = {
  companyZipCode: Scalars['String']['input'];
  eoriNumberValid: Scalars['Boolean']['input'];
  isFraudSuspected: Scalars['Boolean']['input'];
  isLocalVat: Scalars['Boolean']['input'];
  platform: Scalars['String']['input'];
  sellerId: Scalars['Int']['input'];
  subjectedToVat: Scalars['Boolean']['input'];
  vatStatus: Scalars['String']['input'];
};

/** Enum representing a SellerContract status */
export enum ContractStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Draft = 'Draft',
  Holidays = 'Holidays',
  Suspended = 'Suspended',
}

/** Enum representing a contract status */
export enum ContractStatusDto {
  Active = 'Active',
  Deleted = 'Deleted',
  Draft = 'Draft',
  Holidays = 'Holidays',
  Suspended = 'Suspended',
}

/** Coupon type definition */
export type Coupon = {
  __typename?: 'Coupon';
  /** Coupon channel, it's a restriction, when null there is no restriction, Coupon is valid for all channels */
  channel?: Maybe<Channel>;
  /** Coupon code */
  code: Scalars['ID']['output'];
  /** Coupon creation date */
  creationDate: Scalars['String']['output'];
  /** Coupon expiration date */
  expirationDate: Scalars['String']['output'];
  /** Coupon is percentage type */
  isPercentage: Scalars['Boolean']['output'];
  /** Coupon minimum price */
  minPrice: Scalars['Float']['output'];
  /** Coupon value */
  value: Scalars['Float']['output'];
};

/** Coupon Bulk promise */
export type CouponBulkPromise = {
  __typename?: 'CouponBulkPromise';
  /** Promised coupon amount */
  amount: Scalars['Float']['output'];
  /** Customer Order Item UUID */
  customerOrderItemUuid: Scalars['String']['output'];
};

/** Coupon item type */
export type CouponItem = {
  __typename?: 'CouponItem';
  /** Order item id */
  itemId: Scalars['ID']['output'];
  /** Manomano coupon price without VAT */
  manoManoPriceVatExcl: Scalars['BigDecimalQl']['output'];
  /** Manomano coupon price */
  manoManoPriceVatIncl: Scalars['BigDecimalQl']['output'];
  /** Manomano coupon price on product without VAT */
  manomanoPriceOnProductVatExcl: Scalars['BigDecimalQl']['output'];
  /** Manomano coupon price on product */
  manomanoPriceOnProductVatIncl: Scalars['BigDecimalQl']['output'];
  /** Manomano coupon price on shipping without VAT */
  manomanoPriceOnShippingVatExcl: Scalars['BigDecimalQl']['output'];
  /** Manomano coupon price on shipping */
  manomanoPriceOnShippingVatIncl: Scalars['BigDecimalQl']['output'];
  /** Seller coupon price on product without VAT */
  sellerPriceOnProductVatExcl: Scalars['BigDecimalQl']['output'];
  /** Seller coupon price on product */
  sellerPriceOnProductVatIncl: Scalars['BigDecimalQl']['output'];
  /** Seller coupon price on shipping without VAT */
  sellerPriceOnShippingVatExcl: Scalars['BigDecimalQl']['output'];
  /** Seller coupon price on shipping */
  sellerPriceOnShippingVatIncl: Scalars['BigDecimalQl']['output'];
  /** Seller coupon price without VAT */
  sellerPriceVatExcl: Scalars['BigDecimalQl']['output'];
  /** Seller coupon price */
  sellerPriceVatIncl: Scalars['BigDecimalQl']['output'];
};

/** Represent a coupon redeemed restriction */
export enum CouponRedeemedRestrictionName {
  MINIMUM_PAYMENT_REQUIRED = 'MINIMUM_PAYMENT_REQUIRED',
  REACHED_LIMIT = 'REACHED_LIMIT',
}

/** All information about the cross border VAT of the company, full documentation: https://manomano.atlassian.net/wiki/spaces/GF/pages/4009952021/Cross-Border+VAT */
export type CrossBorderVat = {
  __typename?: 'CrossBorderVat';
  /** Intracom VAT number */
  intracomVatNumber?: Maybe<Scalars['String']['output']>;
  /** Intracom VAT status applicable to EU countries (FR, ES, IT, DE) and GB (Northern Ireland) */
  intracomVatStatus: VatStatus;
  /** Flag to indicate if the VAT is fraud suspected or not */
  isFraudSuspected: Scalars['Boolean']['output'];
  /** Indicates if the VAT number is an intracommunity VAT number or not (possibles values: YES, NO, NOT_INFORMED). Only applies to ES and IT, otherwise will be null */
  isIntracommunity?: Maybe<Intracommunity>;
  /** Local VAT number */
  localVatNumber?: Maybe<Scalars['String']['output']>;
  /** Local VAT status applicable to GB (excluding Northern Ireland) and ES, IT and GB (Northern Ireland) for other purposes */
  localVatStatus: VatStatus;
  /** Subjected to VAT */
  subjectedToVat: SubjectedToVat;
  /** Name of the validation proof document for the VAT number if it's IN_PROGRESS to be validated by an agent */
  validationProofDocumentName?: Maybe<Scalars['String']['output']>;
};

/** Currency */
export enum Currency {
  /** Euro */
  EUR = 'EUR',
  /** British Pound */
  GBP = 'GBP',
}

/** CurrentPrice */
export type CurrentPrice = {
  __typename?: 'CurrentPrice';
  /** amount_with_vat: BigDecimal! */
  amount_with_vat: Scalars['BigDecimal']['output'];
  /** amount_without_vat: BigDecimal! */
  amount_without_vat: Scalars['BigDecimal']['output'];
  /** vat_rate: BigDecimal! */
  vat_rate: Scalars['BigDecimal']['output'];
};

/** Customer */
export type Customer = {
  __typename?: 'Customer';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Email */
  email: Scalars['String']['output'];
  /** First name */
  firstName: Scalars['String']['output'];
  /** Fiscal number */
  fiscalNumber: Scalars['String']['output'];
  /** Customer id */
  id: Scalars['Int']['output'];
  /** Is Guest */
  isGuest: Scalars['Boolean']['output'];
  /** Language (de_DE, es_ES, fr_FR, en_GB or it_IT) */
  language: Language;
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Market */
  market: Scalars['String']['output'];
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Platform */
  platform: Platform;
  /** Profile image */
  profileImage?: Maybe<Scalars['String']['output']>;
  /** Receive offers */
  receiveOffers: Scalars['Boolean']['output'];
  /** Receive SMS offers */
  receiveSmsOffers: Scalars['Boolean']['output'];
  /** Referer id */
  refererId?: Maybe<Scalars['Int']['output']>;
  /** Id for customers authenticated with their Facebook account */
  socialAuthFacebookId?: Maybe<Scalars['String']['output']>;
  /** Id for customers authenticated with their Google account */
  socialAuthGoogleId?: Maybe<Scalars['String']['output']>;
  /** Time zone */
  timeZone?: Maybe<Scalars['String']['output']>;
  /** Updated at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Username */
  username: Scalars['String']['output'];
  /** UUIDv4 (like 8057327e-0794-4c45-8300-19763ce0ae62) */
  uuid?: Maybe<Scalars['String']['output']>;
};

/** Customer address */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** Address */
  address: Scalars['String']['output'];
  /** Address 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Address 3 */
  address3?: Maybe<Scalars['String']['output']>;
  /** City */
  city: Scalars['String']['output'];
  /** Company name */
  companyName?: Maybe<Scalars['String']['output']>;
  /** Address is complete */
  complete: Scalars['Boolean']['output'];
  /** Format iso code 3166-1 alpha-2 (ex: FR) */
  country?: Maybe<Scalars['String']['output']>;
  /** County */
  county?: Maybe<Scalars['String']['output']>;
  /** Default for billing */
  defaultBilling: Scalars['Boolean']['output'];
  /** Default for shipping */
  defaultShipping: Scalars['Boolean']['output'];
  /** First name */
  firstName: Scalars['String']['output'];
  /** Fiscal number */
  fiscalNumber?: Maybe<Scalars['String']['output']>;
  /** Id */
  id: Scalars['Int']['output'];
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Latitude */
  latitude?: Maybe<Scalars['String']['output']>;
  /** Longitude */
  longitude?: Maybe<Scalars['String']['output']>;
  /** Phone */
  phone: Scalars['String']['output'];
  /** Recipient code */
  recipientCode?: Maybe<Scalars['String']['output']>;
  /** region */
  region?: Maybe<Scalars['String']['output']>;
  /** Zip code */
  zipCode: Scalars['String']['output'];
};
export type CustomerBankDetails = {
  __typename?: 'CustomerBankDetails';
  /** Bank account holder name */
  bankAccountHolderName: Scalars['String']['output'];
  /** Bank account BIC */
  bic: Scalars['String']['output'];
  /** Bank account IBAN */
  iban: Scalars['String']['output'];
};

/**
 * A Collection of items that a Customer intends to Purchase.
 * The only information in possession of the cart is the product_id and the quantity of each product.
 */
export type CustomerCart = {
  __typename?: 'CustomerCart';
  /** When was this Cart created */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** The Customer who owns this Cart */
  customerId: Scalars['Int']['output'];
  /** The items in the Cart */
  items: Array<CartItem>;
  /** The Market of the Cart, may be B2C or B2B */
  market: Market;
  /** The platform of the Cart */
  platform: Platform;
  /** A base64 encoding of a json representation of this Cart */
  sharingKey?: Maybe<Scalars['String']['output']>;
  /** When was the last update to this Cart */
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Represents a customer delivery */
export type CustomerDelivery = {
  __typename?: 'CustomerDelivery';
  /** The address to deliver to */
  deliveryAddress: CustomerDeliveryAddress;
  /** List of dispatch(es) for this customer order */
  dispatches: Array<CustomerDeliveryDispatch>;
  /**
   * Expiration date of the delivery
   * Date-time in the ISO-8601 format
   * example: 2024-08-09T00:00:00Z
   */
  expiresAt: Scalars['String']['output'];
  /**
   * Unique identifier for the customer order delivery - uuid
   * example: b6c84891-b59d-40f0-b9c1-efc9479a0f2e
   */
  id: Scalars['ID']['output'];
  /**
   * Current state of the delivery
   * example: PROPOSED
   */
  state: CustomerDeliveryState;
};

/** Represents a delivery address */
export type CustomerDeliveryAddress = {
  __typename?: 'CustomerDeliveryAddress';
  /**
   * City of the delivery address
   * example: Hourtin
   */
  city: Scalars['String']['output'];
  /** Contact information */
  contact: CustomerDeliveryAddressContact;
  /**
   * Country of the delivery address
   * example: FR
   */
  country: Scalars['String']['output'];
  /** Latitude and longitude for this address */
  geoCoordinates?: Maybe<CustomerDeliveryAddressGeoCoordinates>;
  /**
   * Address lines. First element must not be null.
   * Minimum of 1 line and maximum of 3 lines.
   * example: ["5 avenue des Jonquilles", "Résidence des Argentiers", "Apt 234"]
   */
  lines: Array<Maybe<Scalars['String']['output']>>;
  /**
   * Postal code of the delivery address
   * example: 33990
   */
  postalCode: Scalars['String']['output'];
};

/** Represents contact information */
export type CustomerDeliveryAddressContact = {
  __typename?: 'CustomerDeliveryAddressContact';
  /**
   * Company name of the contact
   * example: ManMano
   */
  company?: Maybe<Scalars['String']['output']>;
  /**
   * Email address of the contact
   * example: jean.dujardin+1@manomano.com
   */
  email?: Maybe<Scalars['String']['output']>;
  /**
   * First name of the contact
   * example: Jean
   */
  firstname: Scalars['String']['output'];
  /**
   * Last name of the contact
   * example: Dujardin
   */
  lastname: Scalars['String']['output'];
  /**
   * Phone number of the contact
   * example: +33614384450
   */
  phone: Scalars['String']['output'];
};

/** Represents geographical coordinates. Pair of latitude and longitude double */
export type CustomerDeliveryAddressGeoCoordinates = {
  __typename?: 'CustomerDeliveryAddressGeoCoordinates';
  /**
   * Latitude
   * example: 45.1861
   */
  latitude: Scalars['Float']['output'];
  /**
   * Longitude
   * example: 1.0572
   */
  longitude: Scalars['Float']['output'];
};

/** Represents a dispatch. Items grouped by MF and seller */
export type CustomerDeliveryDispatch = {
  __typename?: 'CustomerDeliveryDispatch';
  /** List of delivery promises possible for this dispatch */
  deliveryPromises: Array<CustomerDeliveryPromise>;
  /**
   * Unique identifier for the dispatch - uuid
   * example: b6c84891-b59d-40f0-b9c1-efc9479a0f2e
   */
  id: Scalars['ID']['output'];
  /** Indicates if the dispatch is fulfilled by Mano */
  isManoFulfillment: Scalars['Boolean']['output'];
  /**
   * List of product order ids in this dispatch - @see future ProductOrder from ms.customer-order
   * NB: to be federated on product-order / customer-order : productId, quantity, sellerContractId, offerId ...
   */
  items: Array<Scalars['String']['output']>;
};

/** Represents a delivery period */
export type CustomerDeliveryPeriod = {
  __typename?: 'CustomerDeliveryPeriod';
  /**
   * The cutoff is the expiration time of the delivery promise.
   * Ordering after the cutoff will shift the delivery promise to the next opening day.
   * Date-time in the ISO-8601 format
   * example: 2021-10-14T19:00:00+02:00
   */
  cutoff?: Maybe<Scalars['String']['output']>;
  /**
   * Express mode can have 2 values : 24h or 48h. It can also be null for non express mode
   * example: 24h
   */
  expressMode?: Maybe<Scalars['String']['output']>;
  /**
   * Latest date-time in the ISO-8601 format
   * example: 2021-10-15T00:00:00+00:00
   */
  max: Scalars['String']['output'];
  /**
   * Earliest date-time in the ISO-8601 format
   * example: 2021-10-14T00:00:00+00:00
   */
  min: Scalars['String']['output'];
};

/** Represents a pickup point */
export type CustomerDeliveryPickupPoint = {
  __typename?: 'CustomerDeliveryPickupPoint';
  /**
   * Address of the pickup point
   * example: 42 rue de la gare
   */
  address: Scalars['String']['output'];
  /**
   * City of the pickup point
   * example: Paris
   */
  city: Scalars['String']['output'];
  /**
   * Country of the pickup point
   * example: FR
   */
  country: Scalars['String']['output'];
  /**
   * Name of the pickup point
   * example: Librairie de la gare
   */
  name: Scalars['String']['output'];
  /**
   * Postal code of the pickup point
   * example: 75008
   */
  postalCode: Scalars['String']['output'];
};

/** Price */
export type CustomerDeliveryPrice = {
  __typename?: 'CustomerDeliveryPrice';
  /** Example: 345.34 */
  amount: Scalars['Float']['output'];
  /** Example: EUR */
  currency: Scalars['String']['output'];
};

/** Represents a product distribution */
export type CustomerDeliveryProductDistribution = {
  __typename?: 'CustomerDeliveryProductDistribution';
  /**
   * External carrier name
   * example: CXP
   */
  externalCarrierName: Scalars['String']['output'];
  /**
   * External carrier offer. It is null for non MF
   * example: CES
   */
  externalCarrierOffer?: Maybe<Scalars['String']['output']>;
  /** Identifier for the product - long */
  productId: Scalars['String']['output'];
  /** Quantity of the product */
  quantity: Scalars['Int']['output'];
  /**
   * Country where the product is shipped from
   * example: FR
   */
  shippingFrom?: Maybe<Scalars['String']['output']>;
};

/** Represents a delivery promise */
export type CustomerDeliveryPromise = {
  __typename?: 'CustomerDeliveryPromise';
  /**
   * Unique identifier for the delivery promise - uuid
   * example: b6c84891-b59d-40f0-b9c1-efc9479a0f2e
   */
  id: Scalars['ID']['output'];
  /** If the promise is a pickup delivery */
  isPickupDelivery: Scalars['Boolean']['output'];
  /** Indicates if this delivery promise has been recommended by manomano */
  isRecommended: Scalars['Boolean']['output'];
  /** If the promise is a scheduled delivery */
  isScheduledDelivery: Scalars['Boolean']['output'];
  /** Indicates if this delivery promise has been selected by the customer */
  isSelected: Scalars['Boolean']['output'];
  /**
   * Delivery mode for this promise (home, relay point ...)
   * Possible values: EXPRESS_HOME_DELIVERY, HOME_DELIVERY, EXPRESS_RELAY_POINT, RELAY_POINT, PRE_ORDER_SCHEDULED_DELIVERY_ROOM_OF_CHOICE, PRE_ORDER_SCHEDULED_DELIVERY_AT_STREET.
   * example: HOME_DELIVERY
   */
  mode: Scalars['String']['output'];
  /** Range of dates for delivery period */
  period: CustomerDeliveryPeriod;
  /** Used in case of relay point scenario */
  pickupApiSlug?: Maybe<Scalars['String']['output']>;
  /** List of product distribution */
  productsDistribution: Array<CustomerDeliveryProductDistribution>;
  /** If a delivery slot point has been selected, describe slot data */
  selectedDeliverySlot?: Maybe<CustomerDeliverySlot>;
  /** If a relay point has been selected, describe relay point data and location */
  selectedPickupPoint?: Maybe<CustomerDeliveryPickupPoint>;
  /** Range of days for calculating delivery period dates */
  window: DeliveryWindow;
};

/** Represents a scheduled delivery slot */
export type CustomerDeliveryScheduleSlot = {
  __typename?: 'CustomerDeliveryScheduleSlot';
  /**
   * End date and time of the scheduled delivery slot - Represents a local date time in the format of 'yyyy-MM-ddTHH:mm'
   * example: 2021-12-31T23:59
   */
  dateEnd: Scalars['String']['output'];
  /**
   * Start date and time of the scheduled delivery slot - Represents a local date time in the format of 'yyyy-MM-ddTHH:mm'
   * example: 2021-12-31T23:59
   */
  dateStart: Scalars['String']['output'];
};

/** Represents a delivery slot */
export type CustomerDeliverySlot = {
  __typename?: 'CustomerDeliverySlot';
  /**
   * Ending local date-time in the ISO-8601 format, with a minute precision
   * example: 2021-10-15T00:00
   */
  dateEnd: Scalars['String']['output'];
  /**
   * Starting local date-time in the ISO-8601 format, with a minute precision
   * example: 2021-10-14T00:00
   */
  dateStart: Scalars['String']['output'];
  /**
   * Offset of the delivery slot
   * example: +02:00
   */
  offset: Scalars['String']['output'];
};

/**
 * Represents the state of a delivery
 * @see https://git.manomano.tech/deliver/product-delivery/ms.customer-delivery.manomano.com/-/blob/master/docs/delivery_state.md?ref_type=heads
 */
export enum CustomerDeliveryState {
  ACCEPTED = 'ACCEPTED',
  PROPOSED = 'PROPOSED',
  SELECTED = 'SELECTED',
  VALIDATED = 'VALIDATED',
}

/** Customer-Order */
export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  /** Customer-Order creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Customer */
  customer: CustomerOrderCustomer;
  /** Computation of the customer order */
  customerOrderComputation?: Maybe<CustomerOrderComputation>;
  /** represents the customer order delivery */
  delivery?: Maybe<CustomerDelivery>;
  /** Date at which the Customer-Order will expire */
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  /** Customer-Order ID (UUID) */
  id: Scalars['ID']['output'];
  /** Payment related to the customer order if a payment has been started */
  payment?: Maybe<Payment>;
  /** Platform on which the customer-order was created */
  platform: Platform;
  /** Products ordered */
  products: Array<ProductOrder>;
  /** Customer-Order human readable reference */
  reference: Scalars['String']['output'];
  /** Sales Market */
  salesMarket: Market;
  /** Customer-Order status */
  status: CustomerOrderStatus;
  /** Customer-Order last update date */
  updatedAt: Scalars['DateTime']['output'];
};

/** Customer Order Billing Info */
export type CustomerOrderBillingInfo = {
  __typename?: 'CustomerOrderBillingInfo';
  /** address */
  address?: Maybe<Scalars['String']['output']>;
  /** address complement */
  addressComplement?: Maybe<Scalars['String']['output']>;
  /** city */
  city?: Maybe<Scalars['String']['output']>;
  /** company name */
  companyName?: Maybe<Scalars['String']['output']>;
  /** Country code */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** county */
  county?: Maybe<Scalars['String']['output']>;
  /** Customer Id */
  customerId?: Maybe<Scalars['Long']['output']>;
  /** Customer Order Id */
  customerOrderId: Scalars['ID']['output'];
  /** eori number */
  eoriNumber?: Maybe<Scalars['String']['output']>;
  /** eori number validity */
  eoriNumberValid: Scalars['Boolean']['output'];
  /** first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** intraco vat number */
  intracoVatNumber?: Maybe<Scalars['String']['output']>;
  /** intraco vat number validity */
  intracoVatNumberValid: Scalars['Boolean']['output'];
  /** is invoice claimed */
  invoiceClaim?: Maybe<Scalars['Boolean']['output']>;
  /** invoice fiscal number */
  invoiceFiscalNumber?: Maybe<Scalars['String']['output']>;
  /** invoice fiscal number type */
  invoiceFiscalNumberType?: Maybe<Scalars['String']['output']>;
  /** is b2b */
  isB2b?: Maybe<Scalars['Boolean']['output']>;
  /** is fraud suspected */
  isFraudSuspected: Scalars['Boolean']['output'];
  /** last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** local vat number */
  localVatNumber?: Maybe<Scalars['String']['output']>;
  /** local vat number validity */
  localVatNumberValid: Scalars['Boolean']['output'];
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** recipient code */
  recipientCode?: Maybe<Scalars['String']['output']>;
  /** recipient code type */
  recipientCodeType?: Maybe<Scalars['String']['output']>;
  /** region */
  region?: Maybe<Scalars['String']['output']>;
  /** Sellers informations */
  sellers: Array<SellerInfo>;
  /** subjected to vat */
  subjectedToVat: Scalars['Boolean']['output'];
  /** zip code */
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Customer order computation */
export type CustomerOrderComputation = {
  __typename?: 'CustomerOrderComputation';
  /** List of coupons */
  coupons: Array<ComputationCoupon>;
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
  /** List of dispatches */
  dispatches?: Maybe<Array<Dispatch>>;
  /** List of products */
  products: Array<ComputationProduct>;
  /** total price of coupon without VAT */
  totalCouponPriceVatExcl: ComputationPrice;
  /** total price of coupon */
  totalCouponPriceVatIncl: ComputationPrice;
  /** total price of delivery discount without VAT */
  totalDeliveryDiscountPriceVatExcl: ComputationPrice;
  /** total price of delivery discount */
  totalDeliveryDiscountPriceVatIncl: ComputationPrice;
  /** price to be paid by the customer without VAT */
  totalPriceToPayByCustomerVatExcl: ComputationPrice;
  /** price to be paid by the customer */
  totalPriceToPayByCustomerVatIncl: ComputationPrice;
  /** total price of products without VAT */
  totalProductPriceVatExcl: ComputationPrice;
  /** total price of products */
  totalProductPriceVatIncl: ComputationPrice;
  /** total price of shipping paid by the customer without VAT */
  totalShippingPriceToPayByCustomerVatExcl: ComputationPrice;
  /** total price of shipping paid by the customer */
  totalShippingPriceToPayByCustomerVatIncl: ComputationPrice;
  /** total price of shipping without VAT */
  totalShippingPriceVatExcl: ComputationPrice;
  /** total price of shipping */
  totalShippingPriceVatIncl: ComputationPrice;
};

/** The customer */
export type CustomerOrderCustomer = {
  __typename?: 'CustomerOrderCustomer';
  /** Customer ids */
  ids: CustomerOrderCustomerIds;
  /** Is the customer a guest */
  isGuest: Scalars['Boolean']['output'];
  /** Customer type */
  type: CustomerOrderCustomerType;
  /** User Information */
  userInfo: CustomerOrderUserInfo;
};

/** Customer ids */
export type CustomerOrderCustomerIds = {
  __typename?: 'CustomerOrderCustomerIds';
  /** Customer's legacy ID */
  legacy: Scalars['ID']['output'];
  /** Customer's visitor ID */
  visitor?: Maybe<Scalars['ID']['output']>;
};

/** Customer ids */
export type CustomerOrderCustomerIdsInput = {
  /** Customer's visitor ID */
  visitor?: InputMaybe<Scalars['ID']['input']>;
};

/** The customer */
export type CustomerOrderCustomerInput = {
  /** Customer ids */
  ids?: InputMaybe<CustomerOrderCustomerIdsInput>;
  /** Is the customer a guest */
  isGuest: Scalars['Boolean']['input'];
  /** Customer type */
  type: CustomerOrderCustomerType;
  /** User Information */
  userInfo: CustomerOrderUserInfoInput;
};

/** Customer type */
export enum CustomerOrderCustomerType {
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_B2B = 'CUSTOMER_B2B',
}

/** Customer-Order page */
export type CustomerOrderPage = {
  __typename?: 'CustomerOrderPage';
  /** Customer-Orders */
  customerOrders: Array<CustomerOrder>;
  /** Total number of Customer-Orders */
  totalCount: Scalars['Long']['output'];
};

/** Price */
export type CustomerOrderPrice = {
  __typename?: 'CustomerOrderPrice';
  /** Amount */
  amount: Scalars['BigDecimal']['output'];
  /** Currency */
  currency: Scalars['String']['output'];
};

/** The status of a customer order */
export enum CustomerOrderStatus {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  READY = 'READY',
  VALIDATED = 'VALIDATED',
}

/** User Information */
export type CustomerOrderUserInfo = {
  __typename?: 'CustomerOrderUserInfo';
  /** Customer application (ex: app, website, etc.) */
  app: Scalars['String']['output'];
  /** Customer device (ex: mobile, tablet, desktop, etc.) */
  device: Scalars['String']['output'];
  /** Customer OS (ex: iOS, Android, Windows, etc.) */
  os: Scalars['String']['output'];
  /** User-Agent of the Customer */
  userAgent: Scalars['String']['output'];
};

/** User Information */
export type CustomerOrderUserInfoInput = {
  /** Customer application (ex: app, website, etc.) */
  app: Scalars['String']['input'];
  /** Customer device (ex: mobile, tablet, desktop, etc.) */
  device: Scalars['String']['input'];
  /** Customer OS (ex: iOS, Android, Windows, etc.) */
  os: Scalars['String']['input'];
  /** User-Agent of the Customer */
  userAgent: Scalars['String']['input'];
};

/** Info about which products have been purchased by a customer */
export type CustomerProductPreferences = {
  __typename?: 'CustomerProductPreferences';
  /** The Customer who owns these preferences */
  customerId: Scalars['Int']['output'];
  /** The Market of the Customer, always B2B */
  market: Market;
  /** The platform of the Customer */
  platform: Platform;
  /** Collection of products that were purchased at least once */
  purchasedAtLeastOnce: Array<PurchasedAtLeastOnce>;
  /** Collection of products that were purchased twice */
  purchasedTwice: Array<Maybe<ChannelOffer>>;
};

/** A customer refund */
export type CustomerRefund = {
  __typename?: 'CustomerRefund';
  /** Amount of the customer refund */
  amount: CustomerRefundAmount;
  /** Creation date of the customer refund */
  createdAt: Scalars['String']['output'];
  /** Customer refund id is an unique key under UUID format (eb729c04-957d-4819-8bef-26fb72a23b84) */
  id: Scalars['ID']['output'];
  /** List of refund attempts */
  refundAttempts: Array<Maybe<RefundAttempt>>;
  /** Refund request issuer id is an unique key */
  refundRequestIssuerId: Scalars['ID']['output'];
  /** Refund request issuer type */
  refundRequestIssuerType: CustomerRefundRequestIssuerType;
  /** Status of the customer refund */
  status: CustomerRefundStatus;
  /** Last update date of the customer refund */
  updatedAt: Scalars['String']['output'];
};

/** An amount with currency */
export type CustomerRefundAmount = {
  __typename?: 'CustomerRefundAmount';
  /** Amount value */
  amount: Scalars['Float']['output'];
  /** Currency of the amount */
  currency: Currency;
};

/** Enumeration of customer refund request issuer type */
export enum CustomerRefundRequestIssuerType {
  FINANCIAL_REQUEST = 'FINANCIAL_REQUEST',
}

/** Enumeration of customer refund status */
export enum CustomerRefundStatus {
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  SUCCEEDED = 'SUCCEEDED',
  TO_PROCESS_BY_PSP = 'TO_PROCESS_BY_PSP',
}
export type CustomerSegments = {
  __typename?: 'CustomerSegments';
  antiChurn?: Maybe<Scalars['String']['output']>;
  appCluster?: Maybe<Scalars['String']['output']>;
  audienceGroup?: Maybe<Scalars['String']['output']>;
  b2bIntention?: Maybe<Scalars['String']['output']>;
  b2bScore?: Maybe<Scalars['Int']['output']>;
  b2bSubscription?: Maybe<Scalars['String']['output']>;
  b2bSuspectedPro?: Maybe<Scalars['String']['output']>;
  b2bVatModal?: Maybe<Scalars['Boolean']['output']>;
  b2bWelcome?: Maybe<Scalars['Boolean']['output']>;
  buyNumberCluster?: Maybe<Scalars['String']['output']>;
  cluster?: Maybe<Scalars['String']['output']>;
  customerValue?: Maybe<Scalars['String']['output']>;
  highValue?: Maybe<Scalars['String']['output']>;
  lastChanceChurn?: Maybe<Scalars['String']['output']>;
  loyaltyHVCLevel?: Maybe<Scalars['String']['output']>;
  loyaltyLevel?: Maybe<Scalars['String']['output']>;
  preferredAdviceContactChannel?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  suspectedProType?: Maybe<Scalars['String']['output']>;
};

/** Customer Subscription */
export type CustomerSubscription = {
  __typename?: 'CustomerSubscription';
  /** Market */
  market: Market;
  /** Platform */
  platform: Platform;
  /** Prices */
  prices: Array<CustomerSubscriptionPrice>;
};

/** Customer Subscription Price */
export type CustomerSubscriptionPrice = {
  __typename?: 'CustomerSubscriptionPrice';
  /** Price description */
  description: Scalars['String']['output'];
  /** Price title */
  name: Scalars['String']['output'];
  /** Price ID */
  priceId: Scalars['String']['output'];
  /** Price without VAT */
  priceWithoutVat: Scalars['Float']['output'];
  /** Price type */
  type: CustomerSubscriptionPriceType;
};

/** Customer Subscription Price Type Enum */
export enum CustomerSubscriptionPriceType {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

/** Customer Subscription status of the last subscription of a b2b user */
export type CustomerSubscriptionStatus = {
  __typename?: 'CustomerSubscriptionStatus';
  /** The date when a customer ask for a cancellation of the subscription */
  cancelledAt?: Maybe<Scalars['String']['output']>;
  /** Enda Date of subscription */
  endDate: Scalars['String']['output'];
  /** If subscription is expired */
  isExpired: Scalars['Boolean']['output'];
  /** Subscription information */
  price: CustomerSubscriptionPrice;
  /** Start Date of subscription */
  startDate: Scalars['String']['output'];
};

/** Customer type */
export enum CustomerType {
  B2B = 'B2B',
  B2C = 'B2C',
  Guest = 'Guest',
}

/** An item in the cart that was deleted */
export type DeletedCartItem = {
  __typename?: 'DeletedCartItem';
  /** Information about the offer deleted: platform, market, customerId, productId */
  deletedOffer?: Maybe<ChannelOffer>;
  /** Flag to indicate if the item was deleted */
  itemDeleted: Scalars['Boolean']['output'];
  /** Reason why the item was not deleted in case of failure */
  reason?: Maybe<Scalars['String']['output']>;
};

/** Delivery */
export type Delivery = {
  __typename?: 'Delivery';
  /** delivery_expenses: String */
  delivery_expenses?: Maybe<Scalars['String']['output']>;
  /** is_fulfillment: Boolean! */
  is_fulfillment: Scalars['Boolean']['output'];
  /** warehouse_id: Int */
  warehouse_id?: Maybe<Scalars['Int']['output']>;
  /** weight: Float */
  weight?: Maybe<Scalars['Float']['output']>;
};

/** Represent a delivery discount advertising */
export type DeliveryDiscountAdvertising = {
  __typename?: 'DeliveryDiscountAdvertising';
  /** end date */
  endDate?: Maybe<Scalars['String']['output']>;
  /** is express delivery excluded */
  isExpressDeliveryExcluded: Scalars['Boolean']['output'];
  /** legal conditions */
  legalConditions?: Maybe<Scalars['String']['output']>;
  /** max fees */
  maxFees: Scalars['Int']['output'];
  /** message */
  message?: Maybe<Scalars['String']['output']>;
  /** id */
  offerReasonId: Scalars['String']['output'];
  /** start date */
  startDate: Scalars['String']['output'];
  /** threshold */
  threshold: Scalars['Int']['output'];
  /** type id */
  typeId: Scalars['Int']['output'];
  /** with VAT or not */
  withVat: Scalars['Boolean']['output'];
};

/** Type representing a delivery message */
export type DeliveryMessage = {
  __typename?: 'DeliveryMessage';
  /** The id of the delivery message */
  id: Scalars['ID']['output'];
  /** The message of the delivery */
  message: Scalars['String']['output'];
  /** The country of the delivery message */
  platform: Scalars['String']['output'];
};

/** Estimated delivery promise */
export type DeliveryOffer = {
  __typename?: 'DeliveryOffer';
  /**
   * list of country where the product is deliverable - Array of String
   * Example: ["FR", "BE"]
   */
  deliveryCountries: Array<Scalars['String']['output']>;
  /** estimated delivery */
  estimateDelivery: EstimatedDelivery;
  /**
   * The delivery mode of the used carrier for this promise.
   * Non exhaustive example:
   * RELAY_POINT
   * PRE_ORDER_SCHEDULED_DELIVERY_AT_STREET
   * PRE_ORDER_SCHEDULED_DELIVERY_ROOM_OF_CHOICE
   * HOME_DELIVERY
   * ROOM_OF_CHOICE
   */
  mode: Scalars['String']['output'];
  /**
   * delivery offer name set by the seller - String
   * Example: gls relay point
   */
  name: Scalars['String']['output'];
};

/** Federated Type */
export type DeliveryOfferList = {
  __typename?: 'DeliveryOfferList';
  /**
   * price vat included of the product - Float
   * Example: 25.99
   */
  amount: Scalars['Float']['output'];
  /**
   * currency of price vat included of the product - String
   * Example: EUR
   */
  currency: Scalars['String']['output'];
  /** Estimated delivery promises */
  deliveryOffer: Array<Maybe<DeliveryOffer>>;
  /**
   * Is the dispatch a manofulfillment one
   * Example: true
   */
  isMf: Scalars['Boolean']['output'];
  /** Market */
  market?: Maybe<Market>;
  /** Platform */
  platform: Platform;
  /**
   * Product id - Long
   * Example: 1204935
   */
  productId: Scalars['Int']['output'];
  /**
   * quantity of the product (min_quantity to buy) - Int
   * Example: 1
   */
  quantity: Scalars['Int']['output'];
  /**
   * seller contrat id - Int
   * Example: 1204935
   */
  sellerId: Scalars['Int']['output'];
  /**
   * Product sku (product code from xcart_products) - String
   * Example: "1204935-FC"
   */
  sku: Scalars['String']['output'];
  /**
   * WarehouseId of the seller for non MF or MF warehouseId - Int
   * Example: 14
   * @deprecated Warehouse id should no longer be used as it will be removed in future, delete at 2024-06-12
   */
  warehouseId?: Maybe<Scalars['Int']['output']>;
  /**
   * weight of the product in grams (0 if null)- Float
   * Example: 12.99
   */
  weight: Scalars['Float']['output'];
};

/** Delivery price */
export type DeliveryPrice = {
  __typename?: 'DeliveryPrice';
  /**
   * amount - Float
   * Example: 345.34
   */
  amount: Scalars['Float']['output'];
  /**
   * currency - String
   * Example: EUR
   */
  currency: Scalars['String']['output'];
};

/**
 * Input type for delivery slot
 * date range for a scheduled delivery wanted by the customer
 */
export type DeliverySlotInput = {
  /**
   * Ending date-time without a time zone in the ISO-8601 calendar system, with a minute precision.
   * example: 2020-01-01T12:00:00
   */
  dateEnd: Scalars['String']['input'];
  /**
   * Starting date-time without a time zone in the ISO-8601 calendar system, with a minute precision.
   * example: 2020-01-01T10:00:00
   */
  dateStart: Scalars['String']['input'];
};

/** Delivery window */
export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  /** Express mode can have 2 values : '24h' or '48h'. It can also be null for non express mode */
  expressMode?: Maybe<Scalars['String']['output']>;
  /**
   * Amount of calendar day to deliver in the slowest scenario - Int
   * example: 1
   */
  max: Scalars['Int']['output'];
  /**
   * Amount of calendar day to deliver in the quickest scenario - Int
   * example: 1
   */
  min: Scalars['Int']['output'];
  /**
   * The cutoff is the expiration time of the delivery promise.
   * Ordering after the cutoff will shift the delivery promise to the next opening day.
   * Date-time in the ISO-8601 format
   * example: 2021-10-14T19:00:00+02:00
   */
  orderBefore?: Maybe<Scalars['String']['output']>;
};

/** Delivery window in hours */
export type DeliveryTimeHours = {
  __typename?: 'DeliveryTimeHours';
  /**
   * Amount of hour to deliver in the slowest scenario - Int
   * example: 48
   */
  max?: Maybe<Scalars['Int']['output']>;
  /**
   * Amount of hour to deliver in the quickest scenario - Int
   * example: 24
   */
  min?: Maybe<Scalars['Int']['output']>;
};

/** Represents a delivery window */
export type DeliveryWindow = {
  __typename?: 'DeliveryWindow';
  /**
   * Amount of day to deliver in the slowest scenario
   * example: 3
   */
  max: Scalars['Int']['output'];
  /**
   * Amount of day to deliver in the quickest scenario
   * example: 1
   */
  min: Scalars['Int']['output'];
};

/** Description */
export type Description = {
  __typename?: 'Description';
  /** specification_url: String */
  specification_url?: Maybe<Scalars['String']['output']>;
  /** text: String */
  text?: Maybe<Scalars['String']['output']>;
};

/** Device */
export enum Device {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

/** Dispatch type */
export type Dispatch = {
  __typename?: 'Dispatch';
  /** Dispatch discount price without VAT */
  dispatchDiscountVatExcl: ComputationPrice;
  /** Dispatch discount price */
  dispatchDiscountVatIncl: ComputationPrice;
  /** Dispatch id */
  dispatchId: Scalars['ID']['output'];
  /** Dispatch price without VAT */
  dispatchPriceVatExcl: ComputationPrice;
  /** Dispatch price */
  dispatchPriceVatIncl: ComputationPrice;
  /** List of dispatch items */
  items?: Maybe<Array<DispatchItem>>;
  /** List of promises */
  promises?: Maybe<Array<Promise>>;
};

/** Dispatch item type */
export type DispatchItem = {
  __typename?: 'DispatchItem';
  /** dispatch item discount price without VAT */
  dispatchItemDeliveryDiscountAppliedVatExcl: ComputationPrice;
  /** dispatch item discount price */
  dispatchItemDeliveryDiscountAppliedVatIncl: ComputationPrice;
  /** dispatch item price without VAT */
  dispatchItemPriceVatExcl: ComputationPrice;
  /** dispatch item price */
  dispatchItemPriceVatIncl: ComputationPrice;
  /** Order item id */
  orderItemId?: Maybe<Scalars['ID']['output']>;
  /** Product id */
  productId: Scalars['Int']['output'];
};

/** Bank transfer details to display on the frontend */
export type DisplayBankTransfer = {
  __typename?: 'DisplayBankTransfer';
  /** Bank account identification */
  bankAccountIdentification: BankAccountIdentification;
  /** Reference to use for the bank transfer */
  reference?: Maybe<Scalars['String']['output']>;
  /** Remaining amount to pay */
  remainingAmountToPay: PayInEntryAmount;
};

/** Display Type */
export enum DisplayType {
  bundle = 'bundle',
  categoryList = 'categoryList',
  productList = 'productList',
}

/** Ratings distribution */
export type Distribution = {
  __typename?: 'Distribution';
  /** five stars */
  five: Scalars['Int']['output'];
  /** four stars */
  four: Scalars['Int']['output'];
  /** one star */
  one: Scalars['Int']['output'];
  /** three stars */
  three: Scalars['Int']['output'];
  /** two stars */
  two: Scalars['Int']['output'];
};

/** Energy Label data */
export type EnergyLabel = {
  __typename?: 'EnergyLabel';
  /** image url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** product information pdf */
  productInformationPdf?: Maybe<Scalars['String']['output']>;
};

/** Engine */
export type Engine = {
  __typename?: 'Engine';
  /** category Ids */
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  /** display add to cart button, boolean */
  displayAddToCartButton?: Maybe<Scalars['Boolean']['output']>;
  /** Type of display */
  displayType: Scalars['String']['output'];
  /** Same data as in displayType field but in enum. No dashes "-" allowed. */
  displayTypeEnum: DisplayType;
  /** Unique identification name of the engine */
  engineName: Scalars['ID']['output'];
  /** Internationalized name of the engine */
  name: Scalars['String']['output'];
  /** productCollection */
  productCollection: ProductCollection;
  /**
   * products
   * @deprecated products is deprecated. Use productCollection instead.
   */
  products?: Maybe<Array<Maybe<Product>>>;
};

/** Estimated delivery */
export type EstimatedDelivery = {
  __typename?: 'EstimatedDelivery';
  /** Range of days for calculating delivery period dates (calendar days) */
  deliveryTime: DeliveryTime;
  /** Free delivery information (only when a free delivery threshold is configured) */
  freeDelivery: FreeDelivery;
  /** is best delivery offer */
  isBestDeliveryOffer: Scalars['Boolean']['output'];
  /**
   * is the delivery express - Boolean
   * Example: true
   */
  isExpress: Scalars['Boolean']['output'];
  /** delivery price cat included of the promise */
  price: DeliveryPrice;
};

/** FeatureFlag object */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Whether the feature flag is enabled or not */
  active?: Maybe<Scalars['Boolean']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
};

/** Filter Input */
export type FilterInput = {
  /** amountGte */
  amountGte?: InputMaybe<Scalars['BigDecimalQl']['input']>;
  /** amountLte */
  amountLte?: InputMaybe<Scalars['BigDecimalQl']['input']>;
  /** Refund request created after Instant type */
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  /** Refund request created before Instant type */
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  /** customerOrderIds */
  customerOrderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** customerOrderReferences */
  customerOrderReferences?: InputMaybe<Array<Scalars['String']['input']>>;
  /** orderIds */
  orderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** orderPaymentMeans */
  orderPaymentMeans?: InputMaybe<Array<PaymentMean>>;
  /** orderRefs */
  orderRefs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** payInEntryIds */
  payInEntryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** platforms */
  platforms?: InputMaybe<Array<Platform>>;
  /** refundLiable */
  refundLiable?: InputMaybe<RefundLiable>;
  /** refundPaymentMeans */
  refundPaymentMeans?: InputMaybe<Array<PaymentMean>>;
  /** refundReason */
  refundReason?: InputMaybe<RefundReason>;
  /** refundRequestIds */
  refundRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** sellerContractIds */
  sellerContractIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** statuses */
  statuses?: InputMaybe<Array<RefundRequestStatus>>;
  /** Refund request validated after Instant type */
  validatedAfter?: InputMaybe<Scalars['String']['input']>;
  /** Refund request validated before Instant type */
  validatedBefore?: InputMaybe<Scalars['String']['input']>;
};

/** Finance referential type */
export type FinanceReferentialOutput = {
  __typename?: 'FinanceReferentialOutput';
  /** bv */
  bv: ComputationPriceWithVat;
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
  /** Manomano coupon */
  manomanoCoupon: ComputationPriceWithVat;
  /** Manomano coupon on product */
  manomanoCouponOnProduct: ComputationPriceWithVat;
  /** Manomano coupon on shipping */
  manomanoCouponOnShipping: ComputationPriceWithVat;
  /** Manomano delivery discount */
  manomanoDeliveryDiscount: ComputationPriceWithVat;
  /** PayIns */
  payIns: Array<PayIn>;
  /** PaymentId */
  paymentId: Scalars['ID']['output'];
  /** Product amount paid by the customer */
  productAmountPaidByTheCustomer: ComputationPrice;
  /** Product price */
  productPrice: ComputationPriceWithVat;
  /** Market */
  salesMarket: Market;
  /** Seller coupon on product */
  sellerCouponOnProduct: ComputationPriceWithVat;
  /** Seller orders */
  sellerOrders: Array<SellerOrder>;
  /** Shipping amount paid by the customer */
  shippingAmountPaidByTheCustomer: ComputationPrice;
  /** Shipping price */
  shippingPrice: ComputationPriceWithVat;
  /** Total amount paid by the customer */
  totalAmountPaidByTheCustomer: ComputationPrice;
  /** Total discount */
  totalDiscount: ComputationPrice;
  /** Total discount on product */
  totalDiscountOnProduct: ComputationPrice;
  /** Total discount on shipping */
  totalDiscountOnShipping: ComputationPrice;
  /** Total Manomano discount */
  totalManomanoDiscount: ComputationPrice;
};

/** Request fiscal info to update */
export type FiscalInfoInput = {
  /** Invoice claimed */
  invoiceClaim: Scalars['Boolean']['input'];
  /** Invoice fiscal number */
  invoiceFiscalNumber?: InputMaybe<Scalars['String']['input']>;
  /** Recipient code */
  recipientCode?: InputMaybe<Scalars['String']['input']>;
};

/** Fiscal info requirements */
export type FiscalInfoRequirements = {
  __typename?: 'FiscalInfoRequirements';
  /** Default fiscal number */
  defaultFiscalNumber?: Maybe<Scalars['String']['output']>;
  /** Default recipient code */
  defaultRecipientCode?: Maybe<Scalars['String']['output']>;
  /** Fiscal number requirements */
  fiscalNumberRequirements: FiscalInformationRequirements;
  /** Invoice claim by default */
  invoiceClaimByDefault: Scalars['Boolean']['output'];
  /** Invoice claim visible */
  invoiceClaimVisible: Scalars['Boolean']['output'];
  /** Recipient code requirements */
  recipientCodeRequirements: FiscalInformationRequirements;
};

/** Fiscal information requirements */
export enum FiscalInformationRequirements {
  /** Never */
  NEVER = 'NEVER',
  /** On claim */
  ON_CLAIM = 'ON_CLAIM',
  /** Required */
  REQUIRED = 'REQUIRED',
}

/** Free delivery */
export type FreeDelivery = {
  __typename?: 'FreeDelivery';
  /** Indicates whether or not the free delivery threshold has been reached */
  isReached: Scalars['Boolean']['output'];
  /** Delivery Price when the threshold is reached and the delivery became free */
  priceWhenNotReached: DeliveryPrice;
  /** Price to reach to benefits of the free delivery */
  threshold?: Maybe<DeliveryPrice>;
};

/** An enum with the possible sources of this request */
export enum FromRequest {
  CONTACT_PAGE = 'CONTACT_PAGE',
  HELP_CENTER = 'HELP_CENTER',
}

/** Request for the getSavedPaymentMethodsForCurrentCustomer query */
export type GetSavedPaymentMethodsRequest = {
  /** To specify the place from where saved payment method are fetch */
  context?: InputMaybe<SavedPaymentMethodContext>;
  /** Types of payment methods to retrieve */
  paymentMethodTypes: Array<PaymentMethodType>;
  /** Platform from which the saved payment method must be retrieved */
  platform?: InputMaybe<Platform>;
};

/** Response type for the getSavedPaymentMethodsForCurrentCustomer query */
export type GetSavedPaymentMethodsResponse = {
  __typename?: 'GetSavedPaymentMethodsResponse';
  /** Does the customer reached the MM limit count of saved payment methods */
  limitReached: Scalars['Boolean']['output'];
  /** List of saved payment methods of current customer */
  savedPaymentMethods: Array<SavedPaymentMethod>;
};

/** Input for fetching return policy details for a given seller. */
export type GetSellerReturnPolicyInput = {
  /**
   * Specifies whether campaign-specific rules should be applied.
   * Defaults to true.
   */
  applyCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Specifies if the request is for a B2B context.
   * Defaults to false.
   */
  b2b?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specifies whether the custom return policy should be fetched (optional). */
  fetchCustomReturnPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Platform for which the policy should be fetched (optional). */
  platform?: InputMaybe<Scalars['String']['input']>;
  /** Seller ID for whom the return policy details are requested. */
  sellerId: Scalars['Int']['input'];
};

/** Input for fetching seller return policy text by seller ID. */
export type GetSellerReturnPolicyTextInput = {
  /**
   * Specifies if the request is for a B2B context.
   * Defaults to false.
   */
  b2b?: InputMaybe<Scalars['Boolean']['input']>;
  /** Platform for which the policy should be fetched (optional). */
  platform?: InputMaybe<Scalars['String']['input']>;
  /** Seller ID for whom the custom return policy text is requested. */
  sellerId: Scalars['Int']['input'];
};

/** A guide is a complete article about a subject. */
export type Guide = {
  __typename?: 'Guide';
  /** author */
  author: Author;
  /** chapo */
  chapo: Scalars['String']['output'];
  /** content */
  content: Scalars['JSON']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** cta */
  cta?: Maybe<CTA>;
  /** features */
  features: Array<Scalars['String']['output']>;
  /** id */
  id: Scalars['ID']['output'];
  /** legacyId */
  legacyId: Scalars['Int']['output'];
  /** legacyPicture */
  legacyPicture: Scalars['String']['output'];
  /** legacySlug */
  legacySlug: Scalars['String']['output'];
  /** links */
  links: Array<GuideLink>;
  /** platform */
  platform: Platform;
  /** title */
  title: Scalars['String']['output'];
  /** type */
  type: GuideType;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
};

/** A link to another guide */
export type GuideLink = {
  __typename?: 'GuideLink';
  /** legacyId */
  legacyId: Scalars['Int']['output'];
  /** legacySlug */
  legacySlug: Scalars['String']['output'];
  /** title */
  title: Scalars['String']['output'];
};

/** All the available guide types */
export enum GuideType {
  /** How To build or use something */
  action = 'action',
  /** How to choose the right product depending on your needs */
  buying = 'buying',
  /** General information guides */
  information = 'information',
}

/** The helpcenter decision tree */
export type HelpCenterTree = {
  __typename?: 'HelpCenterTree';
  /** List of nodes */
  nodes: Array<Node>;
  /** The status tree */
  status: Scalars['String']['output'];
};

/** Content required to display the home page */
export type Homepage = {
  __typename?: 'Homepage';
  /**
   * All brands attached to the provided platform/channel/market, sorted by their
   * configured position. Capped at 1000 elements, an error is emitted if the
   * maximum is reached.
   */
  brands: Array<Maybe<Brand>>;
  /** Associated channel */
  channel: Channel;
  /**
   * All guides attached to the provided platform/channel/market, sorted by their
   * configured position. Capped at 1000 elements, an error is emitted if the
   * maximum is reached.
   */
  guides: Array<Maybe<Guide>>;
  /** Associated market */
  market: Market;
  /** Associated platform */
  platform: Platform;
  /**
   * All promoted categories attached to the provided platform/market,
   * sorted by their configured position.
   * Capped at 1000 elements, an error is emitted if the maximum is reached.
   * Promoted categories can be subject to user segmentation.
   */
  promotedCategories: Array<Maybe<Category>>;
  /**
   * All promoted models attached to the provided platform/channel/market,
   * fetched from their model identifiers, sorted by their configured position.
   * Capped at 1000 elements, an error is emitted if the maximum is reached.
   */
  promotedModels: Array<PromotedModel>;
  /**
   * All promoted products attached to the provided platform/channel/market,
   * fetched from their model identifiers, sorted by their configured position.
   * Capped at 1000 elements, an error is emitted if the maximum is reached.
   * @deprecated Use the new DSA compliant `promotedModels` instead.
   */
  promotedProducts: ProductCollection;
};

/** Image */
export type Image = {
  __typename?: 'Image';
  /** Path to the image */
  path: Scalars['String']['output'];
  /** Type of the image */
  type: Scalars['String']['output'];
};

/** Input type for payment means in cart context */
export type InputPaymentMeanCart = {
  /** Application type (web or mobile) */
  app?: InputMaybe<Scalars['String']['input']>;
  /** Market of the cart */
  market: Market;
  /** Information about the shipment country of the offers in the cart */
  offers_shipped_from: Array<InputMaybe<OfferShipmentData>>;
  /** Platform of the cart */
  platform: Platform;
  /** Total amount of the cart */
  total_amount: CartAmount;
};

/** Content information for a given page */
export type InspirationalPage = PageMeshing & {
  __typename?: 'InspirationalPage';
  /** Block of links for bots */
  botLinks: Array<MeshingLink>;
  /** Breadcrumb of the page */
  breadcrumb: Array<Breadcrumb>;
  /** Associated platform */
  platform: Platform;
  /** Product images for carousel */
  productImages: Array<ProductImage>;
  /** Title of the page */
  title?: Maybe<Scalars['String']['output']>;
  /** URL of the page */
  url: Scalars['String']['output'];
  /** Primary block of links for user */
  userPrimaryLinks: Array<MeshingLink>;
  /** Secondary block of links for user */
  userSecondaryLinks: Array<MeshingLink>;
};

/** Intracommunity */
export enum Intracommunity {
  /** The VAT number is not intracommunity */
  NO = 'NO',
  /** Not informed */
  NOT_INFORMED = 'NOT_INFORMED',
  /** The VAT number is intracommunity */
  YES = 'YES',
}
export type IsSuspectedPro = {
  __typename?: 'IsSuspectedPro';
  prediction?: Maybe<Scalars['Boolean']['output']>;
};

/** Klarna details */
export type KlarnaDetails = {
  __typename?: 'KlarnaDetails';
  /** Klarna product type (e.g. pay_now, pay_later) */
  productType?: Maybe<Scalars['String']['output']>;
};

/**
 * LandingPage type
 * Represents a landing page.
 */
export type LandingPage = {
  __typename?: 'LandingPage';
  /** The content of the landing page, represented as an array of dynamic block of components. */
  content: Array<LandingPageBlock>;
  /** The description of the landing page. */
  description?: Maybe<Scalars['String']['output']>;
  /** The end DateTime of the landing page. */
  endAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the landing page. */
  id: Scalars['ID']['output'];
  /** The market of the landing page. */
  market: Market;
  /** The name of the landing page. */
  name: Scalars['String']['output'];
  /** The platform of the landing page. */
  platform: Platform;
  /** The slug of the landing page. */
  slug: Scalars['String']['output'];
  /** The start DateTime of the landing page. */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** The title of the landing page. */
  title: Scalars['String']['output'];
};

/**
 * Union type LandingPageBlock
 * Represents a union of different block types used in a landing page.
 */
export type LandingPageBlock = BlockHeroHeader | BlockLinkCardsList | BlockText | BlockTextHeader | BlockTextImage | BlockTitle;

/**
 * Type LandingPageCTA
 * Represents a call-to-action link
 */
export type LandingPageCTA = {
  __typename?: 'LandingPageCTA';
  /** Label to use with the link. */
  label?: Maybe<Scalars['String']['output']>;
  /** The link itself. This field is required. */
  link: LandingPageLink;
};

/**
 * Type LandingPageImage
 * Represents an image used in a landing page.
 */
export type LandingPageImage = {
  __typename?: 'LandingPageImage';
  /** The alternative text for the image. This field is required. */
  altText: Scalars['String']['output'];
  /** The URL of the desktop version of the image. */
  desktopUrl?: Maybe<Scalars['String']['output']>;
  /** The URL of the mobile version of the image. */
  mobileUrl?: Maybe<Scalars['String']['output']>;
};

/**
 * Type LandingPageLink
 * Represents a link used in a landing page link card.
 */
export type LandingPageLink = {
  __typename?: 'LandingPageLink';
  /** The target attribute of the link. Can be _blank, or _modal. */
  target?: Maybe<Scalars['String']['output']>;
  /** The URL of the link. This field is required. */
  url: Scalars['String']['output'];
};

/**
 * Type LandingPageLinkCard
 * Represents a link card used in a landing page block.
 */
export type LandingPageLinkCard = {
  __typename?: 'LandingPageLinkCard';
  /** The image used in the link card. This field is required. */
  image: LandingPageImage;
  /** The link used in the link card. This field is required. */
  link: LandingPageLink;
  /** The title of the link card. This field is required. */
  title: Scalars['String']['output'];
};

/** Language enum GraphQL */
export enum Language {
  de_DE = 'de_DE',
  en_GB = 'en_GB',
  es_ES = 'es_ES',
  fr_FR = 'fr_FR',
  it_IT = 'it_IT',
}

/** Latest Reviews */
export type LatestReviews = {
  __typename?: 'LatestReviews';
  /** article id */
  articleId: Scalars['Int']['output'];
  /** best rated reviews */
  bestRatedReviews?: Maybe<Array<Maybe<Review>>>;
  /** id */
  id: Scalars['ID']['output'];
  /** latest reviews */
  latestReviews?: Maybe<Array<Maybe<Review>>>;
  /** most voted reviews */
  mostVotedReviews?: Maybe<Array<Maybe<Review>>>;
  /** Platform name */
  platformId: Platform;
  /** total review count */
  totalReviewCount: Scalars['Int']['output'];
};

/** A decision tree leaf */
export type LeafNode = NodeLabel & {
  __typename?: 'LeafNode';
  /** The label translated in the platform language */
  label: Scalars['String']['output'];
  /** The workflow */
  workflow?: Maybe<Workflow>;
};

/** Legacy refund ids */
export type LegacyRefundIds = {
  __typename?: 'LegacyRefundIds';
  /** Ms Refund id */
  msRefundId?: Maybe<Scalars['Int']['output']>;
  /** Refund Claims id */
  refundClaimsId?: Maybe<Scalars['Int']['output']>;
  /** Refund Request Item id */
  refundRequestItemId: Scalars['ID']['output'];
};

/** A variant returned within the legacy experience */
export type LegacyVariant = {
  __typename?: 'LegacyVariant';
  /** The best offer for this variant. */
  bestOffer: ChannelOffer;
  /** sku id in PIM catalog */
  mmId: Scalars['String']['output'];
  /** sku id in the monechelle catalog */
  modelId: Scalars['String']['output'];
};

/** A set of LegacyVariant with their pagination info, see VariantExperience enumeration for more details */
export type LegacyVariants = {
  __typename?: 'LegacyVariants';
  /** The pagination information for the current results */
  pageInfo?: Maybe<OffsetPageInfo>;
  /** The set of legacy variants */
  variants?: Maybe<Array<LegacyVariant>>;
};

/** Link object */
export type Link = {
  __typename?: 'Link';
  /** Link text to be displayed */
  label?: Maybe<Scalars['String']['output']>;
  /** HTML target attribute */
  target?: Maybe<Scalars['String']['output']>;
  /** Link's destination */
  url: Scalars['String']['output'];
};

/** Link enum */
export enum LinkEnum {
  /** FIRST */
  FIRST = 'FIRST',
  /** GOTO */
  GOTO = 'GOTO',
  /** LAST */
  LAST = 'LAST',
  /** NEXT */
  NEXT = 'NEXT',
  /** PREVIOUS */
  PREVIOUS = 'PREVIOUS',
}

/** Link enum map */
export type LinkEnumMap = {
  __typename?: 'LinkEnumMap';
  /** key */
  key: LinkEnum;
  /** value */
  value: Scalars['String']['output'];
};

/** LoyaltyCouponUsage type definition */
export type LoyaltyCouponUsage = {
  __typename?: 'LoyaltyCouponUsage';
  /** LoyaltyCouponUsage number */
  number?: Maybe<Scalars['Int']['output']>;
  /** LoyaltyCouponUsage total amount */
  totalAmount?: Maybe<Scalars['Float']['output']>;
};

/** Represent a loyalty program HVC level */
export type LoyaltyHVCLevel = {
  __typename?: 'LoyaltyHVCLevel';
  /** cashback percentage */
  cashbackPercentage: Scalars['Float']['output'];
  /** id */
  id: Scalars['Int']['output'];
  /** name */
  name: LoyaltyHVCLevelName;
  /** purchases to reach next level */
  purchasesToReachNextLevel: Scalars['Int']['output'];
};

/** Represent a loyalty program HVC level configuration */
export type LoyaltyHVCLevelConfiguration = {
  __typename?: 'LoyaltyHVCLevelConfiguration';
  /** loyalty levels */
  levels: Array<LoyaltyHVCLevel>;
};

/** Represent a loyalty HVC level name */
export enum LoyaltyHVCLevelName {
  MEMBER = 'MEMBER',
  TOP_MEMBER = 'TOP_MEMBER',
}

/** Represent a loyalty program HVC level status */
export type LoyaltyHVCLevelStatus = {
  __typename?: 'LoyaltyHVCLevelStatus';
  /** current purchases in the level */
  currentPurchases: Scalars['Int']['output'];
  /** flag that determines if the downgrade reminder should be displayed */
  displayDowngradeReminder?: Maybe<Scalars['Boolean']['output']>;
  /** downgrade date of the current level */
  downgradeDate?: Maybe<Scalars['DateTime']['output']>;
  /** current level HVC name */
  level: LoyaltyHVCLevel;
  /** number of purchases to keep the current level */
  purchasesToKeepLevel?: Maybe<Scalars['Int']['output']>;
  /** remaining purchases for the next level */
  remainingPurchasesForNextLevel?: Maybe<Scalars['Int']['output']>;
};

/** Represent the loyalty program HVC offer */
export type LoyaltyHVCOffer = {
  __typename?: 'LoyaltyHVCOffer';
  /** amount of the offer */
  amount: Scalars['Float']['output'];
  /** CDN link of the offer image */
  image: Scalars['String']['output'];
  /** label of the offer */
  label: Scalars['String']['output'];
  /** link of the card offer based on the channel */
  link: LoyaltyHVCOfferLink;
  /** mininum amount to use the offer */
  minAmount: Scalars['Float']['output'];
  /** text of the offer */
  text: Scalars['String']['output'];
};

/** Represent the loyalty program HVC offer link */
export type LoyaltyHVCOfferLink = {
  __typename?: 'LoyaltyHVCOfferLink';
  /** text shown for the url */
  text: Scalars['String']['output'];
  /** url */
  url: Scalars['String']['output'];
};

/** Represent a loyalty program HVC level status response */
export type LoyaltyHvcOptinResponse = {
  __typename?: 'LoyaltyHvcOptinResponse';
  /** Represent a loyalty program HVC level status */
  loyaltyHVCLevelStatus?: Maybe<LoyaltyHVCLevelStatus>;
};

/** Represent a loyalty level */
export type LoyaltyLevel = {
  __typename?: 'LoyaltyLevel';
  /** discount percentage */
  discountPercentage: Scalars['Float']['output'];
  /** id */
  id: Scalars['Int']['output'];
  /** name */
  name: LoyaltyLevelName;
  /** purchases to reach level */
  purchasesToReachLevel: Scalars['Int']['output'];
};

/** Represent a loyalty level name */
export enum LoyaltyLevelName {
  BRONZE = 'BRONZE',
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  UNDEFINED = 'UNDEFINED',
}

/** Represent a loyalty program level configuration */
export type LoyaltyProgramLevelConfiguration = {
  __typename?: 'LoyaltyProgramLevelConfiguration';
  /** loyalty levels */
  levels: Array<LoyaltyLevel>;
  /** minimum purchase price */
  minimumPurchasePrice: Scalars['Float']['output'];
};

/** Represent a loyalty program level status */
export type LoyaltyProgramLevelStatus = {
  __typename?: 'LoyaltyProgramLevelStatus';
  /** current pot value */
  currentPotValue?: Maybe<Scalars['Float']['output']>;
  /** current purchases in the level */
  currentPurchases: Scalars['Int']['output'];
  /** current level number */
  level: Scalars['Int']['output'];
  /** current level name */
  loyaltyLevel: LoyaltyLevel;
  /** remaining purchases for the next level */
  remainingPurchasesForNextLevel?: Maybe<Scalars['Int']['output']>;
};

/** Represent a loyalty program reward status */
export type LoyaltyProgramRewardStatus = {
  __typename?: 'LoyaltyProgramRewardStatus';
  /** amount achieved */
  amountAchieved?: Maybe<Scalars['Float']['output']>;
  /** amount target */
  amountTarget?: Maybe<Scalars['Float']['output']>;
};

/** Represents a loyalty wallet containing an amount and potential expiration details */
export type LoyaltyWallet = {
  __typename?: 'LoyaltyWallet';
  /** The current amount available in the wallet */
  amount: Scalars['Float']['output'];
  /** Details of the next expiration for the wallet, if applicable */
  nextExpiration?: Maybe<WalletExpiration>;
};

/** Represents a historical transaction or event related to the loyalty wallet */
export type LoyaltyWalletHistoryItem = {
  __typename?: 'LoyaltyWalletHistoryItem';
  /** The amount of the credit or debit involved in this history item */
  amount: Scalars['Float']['output'];
  /** The expiration date of the associated amount, if relevant */
  expireAt?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the history item */
  id?: Maybe<Scalars['String']['output']>;
  /** The date and time when this event occurred */
  occurredAt: Scalars['String']['output'];
  /** Optional reference to the parent history item, used for tracking related events, only expired debit will have a parent */
  parent?: Maybe<LoyaltyWalletHistoryItem>;
  /** The source or origin of this wallet event (e.g., PURCHASE, MIGRATION, or CAMPAIGN) */
  source?: Maybe<WalletHistorySource>;
  /** The type of wallet history event (e.g., CREATED, EXPIRED, or USED) */
  type: WalletHistoryType;
};

/** A manual refund */
export type ManualRefund = {
  __typename?: 'ManualRefund';
  /** Creation date of the manual refund */
  createdAt: Scalars['String']['output'];
  /** Details of the customer bank account */
  customerBankDetails?: Maybe<CustomerBankDetails>;
  /** Manual refund id is an unique key under UUID format (eb729c04-957d-4819-8bef-26fb72a23b84) */
  id: Scalars['ID']['output'];
  /** Status of the manual refund */
  status: ManualRefundStatus;
  /** Last update date of the manual refund */
  updatedAt: Scalars['String']['output'];
};

/** Enumeration of manual refund status */
export enum ManualRefundStatus {
  CUSTOMER_DATA_RECEIVED = 'CUSTOMER_DATA_RECEIVED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
  WAITING_CUSTOMER_DATA = 'WAITING_CUSTOMER_DATA',
}

/** Market enum */
export enum Market {
  /** Business to Business */
  B2B = 'B2B',
  /** Business to Customer */
  B2C = 'B2C',
}
export type MarketingConsent = {
  __typename?: 'MarketingConsent';
  /** Email */
  email: Scalars['Boolean']['output'];
  /** Push batch */
  pushBatch: Scalars['Boolean']['output'];
  /** Sms */
  sms: Scalars['Boolean']['output'];
};

/** Content of a masslanding page */
export type MasslandingPage = PageMeshing & {
  __typename?: 'MasslandingPage';
  /** Block of links for bots */
  botLinks: Array<MeshingLink>;
  /** Breadcrumb of the page */
  breadcrumb: Array<Breadcrumb>;
  /** Category of the page */
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** Associated platform */
  platform: Platform;
  /** Title of the page */
  title?: Maybe<Scalars['String']['output']>;
  /** URL of the page */
  url: Scalars['String']['output'];
  /** Primary block of links for user */
  userPrimaryLinks: Array<MeshingLink>;
  /** Secondary block of links for user */
  userSecondaryLinks: Array<MeshingLink>;
};

/** MasterProduct */
export type MasterProduct = {
  __typename?: 'MasterProduct';
  /** article_id: BigInteger! */
  article_id: Scalars['BigInteger']['output'];
  /** brand: Brand! */
  brand: Brand;
  /** category: Category! */
  category: Category;
  /** category_id: Int! */
  category_id: Scalars['Int']['output'];
  /** is_indexable: Boolean! */
  is_indexable: Scalars['Boolean']['output'];
  /** is_top_sale: Boolean! */
  is_top_sale: Scalars['Boolean']['output'];
  /** master_product_id: BigInteger */
  master_product_id?: Maybe<Scalars['BigInteger']['output']>;
};

/** Md stats for other articles */
export type MdStatsOtherArticle = {
  __typename?: 'MdStatsOtherArticle';
  /** average rating */
  avgRating: Scalars['Float']['output'];
  /** total number of ratings */
  nbTotal: Scalars['Int']['output'];
};

/** Md stats ranking */
export type MdStatsRanking = {
  __typename?: 'MdStatsRanking';
  /** article id */
  articleId: Scalars['Int']['output'];
  /** the mds stats for this article */
  mdStats: MdStatsOtherArticle;
  /** this article's rank */
  position: Scalars['Int']['output'];
};

/** MeasurementPrice */
export type MeasurementPrice = {
  __typename?: 'MeasurementPrice';
  /** amount_with_vat: BigDecimal! */
  amount_with_vat: Scalars['BigDecimal']['output'];
  /** amount_without_vat: BigDecimal! */
  amount_without_vat: Scalars['BigDecimal']['output'];
  /** measurement_unit: String! */
  measurement_unit: Scalars['String']['output'];
  /** vat_rate: BigDecimal! */
  vat_rate: Scalars['BigDecimal']['output'];
};

/** Media */
export type Media = {
  __typename?: 'Media';
  /** large_url: String */
  large_url?: Maybe<Scalars['String']['output']>;
  /** regular_url: String */
  regular_url?: Maybe<Scalars['String']['output']>;
  /** thumbnail_url: String */
  thumbnail_url?: Maybe<Scalars['String']['output']>;
};

/** Federated MerchandisingCategory entity */
export type MerchandisingCategory = {
  __typename?: 'MerchandisingCategory';
  /** MerchandisingCategory ancestors from the root until the parent, which belongs to the same merchandising tree */
  ancestors?: Maybe<Array<AncestorMerchandisingCategory>>;
  /** MerchandisingCategory UUID */
  id: Scalars['ID']['output'];
  /** MerchandisingCategory images */
  images?: Maybe<Array<MerchandisingCategoryImage>>;
  /** Indicates if the MerchandisingCategory is a final node, that is, it has no children */
  isFinalNode: Scalars['Boolean']['output'];
  /** Indicates if the MerchandisingCategory is visible */
  isVisible: Scalars['Boolean']['output'];
  /** MerchandisingCategory level. The root nodes has level 0, its children have level 1, and so on */
  level: Scalars['Int']['output'];
  /** MerchandisingCategory market */
  market: Market;
  /** MerchandisingCategory name */
  name: Scalars['String']['output'];
  /** MerchandisingCategory platform */
  platform: Platform;
  /** MerchandisingCategory public ID */
  publicId: Scalars['Long']['output'];
  /** MerchandisingCategory URL-safe slug created from the name */
  slug: Scalars['String']['output'];
  /** MerchandisingCategory sort between siblings for visual ordering */
  sort: Scalars['Int']['output'];
  /** MerchandisingCategory tags */
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

/** Merchandising Category Image */
export type MerchandisingCategoryImage = {
  __typename?: 'MerchandisingCategoryImage';
  /** Image absolute URL */
  path: Scalars['String']['output'];
  /** Image type (banner, mail, thumbnail) */
  type: Scalars['String']['output'];
};

/**
 * Same as a MerchandisingCategory type, but with limited information.
 * It is used when full tree is requested, not just a single category.
 */
export type MerchandisingTreeCategory = {
  __typename?: 'MerchandisingTreeCategory';
  /** MerchandisingCategory children categories */
  children?: Maybe<Array<MerchandisingTreeCategory>>;
  /** MerchandisingCategory images */
  images?: Maybe<Array<MerchandisingCategoryImage>>;
  /** MerchandisingCategory name */
  name?: Maybe<Scalars['String']['output']>;
  /** MerchandisingCategory public ID */
  publicId: Scalars['Long']['output'];
  /** MerchandisingCategory slug */
  slug?: Maybe<Scalars['String']['output']>;
};

/** Meshing link to a page */
export type MeshingLink = {
  __typename?: 'MeshingLink';
  /** Text of the link */
  displayText: Scalars['String']['output'];
  /** Target url */
  url: Scalars['String']['output'];
};

/** Response type for mfAlternatives query */
export type MfAlternatives = {
  __typename?: 'MfAlternatives';
  /** A list of Mano Fulfillment offers in a OfferRecommendations format */
  offerRecommendations?: Maybe<Array<OfferRecommendations>>;
};

/** ModelAttributes object that contains properties for product */
export type ModelAttributes = {
  __typename?: 'ModelAttributes';
  /** Commercial operations related to product */
  commercialOperations?: Maybe<Array<Scalars['Int']['output']>>;
  /** Product is best quality offer or not */
  isBestQualityOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Product is competitive or not */
  isCompetitive?: Maybe<Scalars['Boolean']['output']>;
  /** ModelID of product */
  modelId: Scalars['Int']['output'];
  /** Platform of product */
  platform: Platform;
  /** Score of product */
  score?: Maybe<Scalars['Float']['output']>;
  /** Smart query of product */
  smartQuery?: Maybe<Scalars['String']['output']>;
  /** Smart title of product */
  smartTitle?: Maybe<Scalars['String']['output']>;
};

/** Mutation type for updating data */
export type Mutation = {
  __typename?: 'Mutation';
  /** cancel refund request for admin */
  cancelRefundRequestAdmin?: Maybe<Scalars['Int']['output']>;
  /** cancel refund request for seller */
  cancelRefundRequestSeller?: Maybe<Scalars['Int']['output']>;
  /** Cancel a wallet coupon using a given order id */
  cancelWalletCoupon?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Compute delivery promises for a customer order. There are one dispatch for MF and one per seller.
   * List of promises can be empty when it is impossible to compute due to misconfiguration.
   */
  computeDelivery?: Maybe<CustomerDelivery>;
  /** Create a COBI regarding a customer order id */
  createCobi: CobiCustomerOrderId;
  /** Create a new Order for a Customer on a Platform */
  createCustomerOrder: CustomerOrder;
  /**
   * Create refund request as a seller
   * - An header x-mm-idempotency-key can be had to manage idempotency on it
   * - maximum size of the header is 36 characters
   */
  createRefundRequest: SellerCreateRefundRequest;
  /**
   * Create refund request as an admin
   * - An header x-mm-idempotency-key can be had to manage idempotency on it
   * - maximum size of the header is 36 characters
   */
  createRefundRequestAdmin: RefundRequestInfo;
  /** Given a Market, a Platform, and product Id, remove it from the cart. Required JWT authentication */
  deleteCustomerCartItem?: Maybe<DeletedCartItem>;
  /** Given a Market, a Platform, and product Id, remove it from the cart. Required Visitor Id authentication */
  deleteVisitorCartItem?: Maybe<DeletedCartItem>;
  /** migration for missing refundIds */
  importFromMsRefundIds?: Maybe<Scalars['Int']['output']>;
  /** Opt in mutation for HVC customers */
  loyaltyHVCOptIn?: Maybe<LoyaltyHvcOptinResponse>;
  /** Opt out mutation for HVC customers */
  loyaltyHVCOptOut?: Maybe<Scalars['Boolean']['output']>;
  /** Redeem a wallet coupon using a given order id */
  redeemWalletCoupon?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Select  all promises of a delivery
   * Returns the UUID of the customer order
   */
  selectAllPromises?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Select a single delivery promise
   * Returns the UUID of the customer order
   */
  selectSinglePromise?: Maybe<Scalars['Boolean']['output']>;
  /** Update the billing address of a COBI return null */
  updateBillingAddress?: Maybe<Scalars['Int']['output']>;
  /** Update the fiscal info of a COBI return null */
  updateFiscalInfo?: Maybe<Scalars['Int']['output']>;
  /** Update a manual refund with customer bank details */
  updateManualRefundCustomerBankDetails?: Maybe<UpdateManualRefundCustomerBankDetailsResponse>;
  /** validate refund request for admin */
  validateRefundRequestAdmin?: Maybe<Scalars['Int']['output']>;
};

/** Mutation type for updating data */
export type MutationcancelRefundRequestAdminArgs = {
  cancelerId?: InputMaybe<Scalars['String']['input']>;
  refundRequestId: Scalars['ID']['input'];
};

/** Mutation type for updating data */
export type MutationcancelRefundRequestSellerArgs = {
  refundRequestId: Scalars['ID']['input'];
  sellerContractId: Scalars['Int']['input'];
};

/** Mutation type for updating data */
export type MutationcancelWalletCouponArgs = {
  customerOrderId: Scalars['String']['input'];
};

/** Mutation type for updating data */
export type MutationcomputeDeliveryArgs = {
  customerOrderId: Scalars['ID']['input'];
  deliveryAddress: ComputeDeliveryAddressInput;
};

/** Mutation type for updating data */
export type MutationcreateCobiArgs = {
  customerOrderId: Scalars['ID']['input'];
};

/** Mutation type for updating data */
export type MutationcreateCustomerOrderArgs = {
  customer: CustomerOrderCustomerInput;
  platform: Platform;
  products: Array<ProductOrderInput>;
  salesMarket: Market;
};

/** Mutation type for updating data */
export type MutationcreateRefundRequestArgs = {
  request: SellerRefundRequestRequest;
};

/** Mutation type for updating data */
export type MutationcreateRefundRequestAdminArgs = {
  request: AdminRefundRequestRequest;
};

/** Mutation type for updating data */
export type MutationdeleteCustomerCartItemArgs = {
  market: Market;
  platform: Platform;
  productId: Scalars['String']['input'];
};

/** Mutation type for updating data */
export type MutationdeleteVisitorCartItemArgs = {
  market: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  visitorId: Scalars['String']['input'];
};

/** Mutation type for updating data */
export type MutationimportFromMsRefundIdsArgs = {
  msRefundIds: Array<Scalars['Int']['input']>;
};

/** Mutation type for updating data */
export type MutationloyaltyHVCOptInArgs = {
  platform: Platform;
};

/** Mutation type for updating data */
export type MutationloyaltyHVCOptOutArgs = {
  platform: Platform;
};

/** Mutation type for updating data */
export type MutationredeemWalletCouponArgs = {
  customerOrderId: Scalars['String']['input'];
};

/** Mutation type for updating data */
export type MutationselectAllPromisesArgs = {
  customerOrderId: Scalars['ID']['input'];
  promises: Array<SelectPromiseInput>;
};

/** Mutation type for updating data */
export type MutationselectSinglePromiseArgs = {
  customerOrderId: Scalars['ID']['input'];
  promise: SelectPromiseInput;
};

/** Mutation type for updating data */
export type MutationupdateBillingAddressArgs = {
  address: AddressInput;
  customerOrderId: Scalars['ID']['input'];
};

/** Mutation type for updating data */
export type MutationupdateFiscalInfoArgs = {
  customerOrderId: Scalars['ID']['input'];
  fiscalInfo: FiscalInfoInput;
};

/** Mutation type for updating data */
export type MutationupdateManualRefundCustomerBankDetailsArgs = {
  input: UpdateManualRefundCustomerBankDetailsInput;
};

/** Mutation type for updating data */
export type MutationvalidateRefundRequestAdminArgs = {
  refundRequestId: Scalars['ID']['input'];
};

/** Mutation response interface */
export type MutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Action to be performed by the frontend after the pay in entry is initialized */
export type NextAction = {
  __typename?: 'NextAction';
  /** Bank transfer details to display to the customer */
  displayBankTransfer?: Maybe<DisplayBankTransfer>;
  /** URL on which the frontend should redirect */
  redirectToUrl?: Maybe<RedirectToUrl>;
  /** Indicates if the PSP SDK must be used */
  usePspSdk?: Maybe<UsePspSdk>;
};

/** A type containing the node for a given decision tree */
export type Node = LeafNode | ParentNode;

/** A node belonging to the decision tree */
export type NodeLabel = {
  /** The label translated in the platform language */
  label: Scalars['String']['output'];
};

/** Offer */
export type Offer = {
  __typename?: 'Offer';
  /**
   * Cashback offer associated to this offer.
   *
   * Cashback offers are unique by platform and market, thus those need to be provided.
   * The platform is available directly from the offer, it is provided directly to find the cashback offer.
   * The "isPro" boolean, when "false", means the offer will be available in both B2C AND B2B markets, so this is not
   * usable directly as the market. The market needs to be provided separately as an argument.
   */
  cashbackOffer?: Maybe<CashbackOffer>;
  /**
   * Commercial animation attributes associated to this offer.
   *
   * It contains a list of promo tags and the furthest end date of the live operations associated to the offer.
   */
  commercialAnimation?: Maybe<CommercialAnimationAttributes>;
  /** delivery: Delivery! */
  delivery: Delivery;
  /** is pro: Boolean! */
  isPro: Scalars['Boolean']['output'];
  /** is_best_offer: Boolean! */
  is_best_offer: Scalars['Boolean']['output'];
  /** is_for_sale: Boolean! */
  is_for_sale: Scalars['Boolean']['output'];
  /** marketPriceDifference: PriceAdvantage. Difference in price between B2B and B2C prices */
  marketPriceDifference?: Maybe<PriceAdvantage>;
  /** model id: BigInteger */
  modelId?: Maybe<Scalars['BigInteger']['output']>;
  /** offer_id: String */
  offer_id?: Maybe<Scalars['String']['output']>;
  /** platform: Platform! */
  platform: Platform;
  /** price: Price! */
  price: Price;
  /** Legacy product identifier */
  product_id: Scalars['Int']['output'];
  /** related_sellers: Int! */
  related_sellers: Scalars['Int']['output'];
  /** seller: Seller! */
  seller: Seller;
  /** sku: String */
  sku?: Maybe<Scalars['String']['output']>;
  /** stock: Stock */
  stock?: Maybe<Stock>;
  /** unit_count: BigDecimal */
  unit_count?: Maybe<Scalars['BigDecimal']['output']>;
  /** unit_count_type: String */
  unit_count_type?: Maybe<Scalars['String']['output']>;
  /** warranty: String */
  warranty?: Maybe<Scalars['String']['output']>;
};

/** Offer */
export type OffercashbackOfferArgs = {
  market: Market;
};

/** Offer */
export type OffercommercialAnimationArgs = {
  market: Market;
};

/** Candidate for best offer */
export type OfferCandidate = {
  __typename?: 'OfferCandidate';
  /** Channel offer */
  channelOffer: ChannelOffer;
  /** Delivery fee for this offer */
  deliveryFee?: Maybe<Scalars['Float']['output']>;
  /** Delivery time for this offer */
  deliveryTime?: Maybe<OfferDeliveryTime>;
  /** Is VAT deductible for this offer */
  isVatDeductible: Scalars['Boolean']['output'];
  /** Offer details */
  offerDetails: OfferDetails;
  /** Recommendation Type: grouped_and_price, price, grouped, alternative */
  recommendationType: RecommendationType;
};

/** OfferCollection */
export type OfferCollection = {
  __typename?: 'OfferCollection';
  /** is_pro: Boolean! */
  is_pro: Scalars['Boolean']['output'];
  /** offers: [OtherOffer] */
  offers?: Maybe<Array<Maybe<OtherOffer>>>;
  /** pages: Int! */
  pages: Scalars['Int']['output'];
  /** platform: Platform! */
  platform: Platform;
  /** product_id: BigInteger! */
  product_id: Scalars['BigInteger']['output'];
  /** total_count: Int! */
  total_count: Scalars['Int']['output'];
};

/** Delivery time of this offer */
export type OfferDeliveryTime = {
  __typename?: 'OfferDeliveryTime';
  /** Fastest offer delivery time */
  fastest?: Maybe<Scalars['String']['output']>;
  /** Slowest offer delivery time */
  slowest?: Maybe<Scalars['String']['output']>;
};

/** Candidate for best offer details */
export type OfferDetails = {
  __typename?: 'OfferDetails';
  /** Package saved */
  packageSaved: Scalars['Boolean']['output'];
  /** Price difference compared to the actual offer */
  priceDifference?: Maybe<Scalars['Float']['output']>;
  /** Time difference compared to the actual offer */
  timeDifference?: Maybe<Scalars['Float']['output']>;
};

/** Offer eco participation */
export type OfferEcoParticipation = {
  __typename?: 'OfferEcoParticipation';
  /**
   * amount including VAT
   * example value : 1.2
   */
  amountVatIncluded: Scalars['Float']['output'];
  /** currency */
  currency: Currency;
};

/** Offer measurement price (for example price per square meter for facing) */
export type OfferMeasurementPrice = {
  __typename?: 'OfferMeasurementPrice';
  /** price */
  price: OfferPrice;
  /**
   * unit
   * example value : "square meter"
   */
  unit: Scalars['String']['output'];
};

/**
 * Input options for offer queries
 * Allows consumer to have control over what they accept as a response
 */
export type OfferOptions = {
  /**
   * Whether to accept non sellable offer as response - default to false
   * When true, the response may be a non sellable offer (out of stock for example)
   * See also ChannelOffer.isSellable comment
   */
  acceptNonSellable?: Scalars['Boolean']['input'];
};

/** Offer packaging */
export type OfferPackaging = {
  __typename?: 'OfferPackaging';
  /**
   * quantity increment - long
   * the quantity increment when added to the cart
   * example value : "2"
   */
  increment: Scalars['String']['output'];
  /**
   * minimum sellable quantity - long
   * the minimum quantity that can be added to the cart
   * example value : "2"
   */
  minimumQuantity: Scalars['String']['output'];
};

/** Offer price */
export type OfferPrice = {
  __typename?: 'OfferPrice';
  /**
   * amount excluding VAT
   * example value : 148.03
   */
  amountVatExcluded: Scalars['Float']['output'];
  /**
   * amount including VAT
   * example value : 177.64
   */
  amountVatIncluded: Scalars['Float']['output'];
  /** currency */
  currency: Currency;
  /**
   * VAT percentage
   * example value : 20.0
   */
  vatPercentage: Scalars['Float']['output'];
};

/** Offer pricing */
export type OfferPricing = {
  __typename?: 'OfferPricing';
  /**
   * discount percentage [0..100] computed between retail price and sell price
   * example value : 15
   */
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  /** eco participation part of the offer price */
  ecoParticipation?: Maybe<OfferEcoParticipation>;
  /**
   * Should the measurement price be considered as the primary price according to offer product category?
   * true on facing product category for example
   */
  isMeasurementPricePrimary: Scalars['Boolean']['output'];
  /**
   * measurement price computed from retail price
   * for example price per square meter for facing
   */
  measurementRetailPrice?: Maybe<OfferMeasurementPrice>;
  /**
   * measurement price computed from sell price
   * for example price per square meter for facing
   */
  measurementSellPrice?: Maybe<OfferMeasurementPrice>;
  /**
   * retail price
   * for information purpose
   */
  retailPrice?: Maybe<OfferPrice>;
  /**
   * price to be paid by customers
   * regardless of coupons, discounts, etc.
   */
  sellPrice: OfferPrice;
};

/** An offer recommendation with the actual offer from the cart and other offers that are candidate to replace it */
export type OfferRecommendations = {
  __typename?: 'OfferRecommendations';
  /** Actual offer in the cart */
  actualOffer: ActualOffer;
  /** A candidate offer to replace the actual offer */
  otherOffers?: Maybe<Array<OfferCandidate>>;
};

/** Offer seller */
export type OfferSeller = {
  __typename?: 'OfferSeller';
  /**
   * contract id - long
   * example value : "12"
   */
  contractId: Scalars['String']['output'];
  /**
   * contract name
   * example value : "Maxoutil"
   */
  contractName?: Maybe<Scalars['String']['output']>;
  /**
   * country location (ISO 3166-1)
   * example value : "FR"
   */
  countryLocation?: Maybe<Scalars['String']['output']>;
  /** is the seller providing its offer through ManoFulfillment? */
  isMF: Scalars['Boolean']['output'];
};

/** The shipment info of an offer */
export type OfferShipmentData = {
  /** Country code of the shipment */
  country_code: Scalars['String']['input'];
  /** Total amount of the offer */
  offer: Scalars['String']['input'];
};

/** Information about the current page */
export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** The offset used to retrieve those data */
  currentOffset: Scalars['Int']['output'];
  /** Whether there is a next page */
  hasNextPage: Scalars['Boolean']['output'];
  /** The limit used to retrieve those data */
  limit: Scalars['Int']['output'];
  /** Total number of elements */
  totalCount: Scalars['Int']['output'];
};

/** Input parameter of a paginated query */
export type OffsetPaginationInput = {
  /** Number of elements to return after the offset */
  limit: Scalars['Int']['input'];
  /** Number of elements to skip */
  offset: Scalars['Int']['input'];
};

/** Order Sort */
export enum OrderSort {
  /** ASC */
  ASC = 'ASC',
  /** DESC */
  DESC = 'DESC',
}

/** OtherOffer */
export type OtherOffer = {
  __typename?: 'OtherOffer';
  /** delivery: DeliveryOfferList */
  delivery?: Maybe<DeliveryOfferList>;
  /** has_pro_conditions: Boolean! */
  has_pro_conditions: Scalars['Boolean']['output'];
  /** is_Fulfillment: Boolean! */
  is_Fulfillment: Scalars['Boolean']['output'];
  /** is_b2b: Boolean! */
  is_b2b: Scalars['Boolean']['output'];
  /** price: Price! */
  price: Price;
  /** product_id: Int! */
  product_id: Scalars['Int']['output'];
  /** seller: Seller */
  seller?: Maybe<Seller>;
  /** sku: String */
  sku?: Maybe<Scalars['String']['output']>;
  /** stock: Stock! */
  stock: Stock;
  /** title: String */
  title?: Maybe<Scalars['String']['output']>;
  /** warehouse_id: Int */
  warehouse_id?: Maybe<Scalars['Int']['output']>;
  /** weight: Float! */
  weight: Scalars['Float']['output'];
};

/** Meshing of the page */
export type PageMeshing = {
  /** Block of links for bots */
  botLinks: Array<MeshingLink>;
  /** Associated platform */
  platform: Platform;
  /** URL of the page */
  url: Scalars['String']['output'];
  /** Primary block of links for user */
  userPrimaryLinks: Array<MeshingLink>;
  /** Secondary block of links for user */
  userSecondaryLinks: Array<MeshingLink>;
};

/** Metadata of the page */
export type PageMetadata = BrandPage | CategoryPage | InspirationalPage | MasslandingPage | ProductPage | RedirectTo;

/** Paged response */
export type PagedResponse = {
  __typename?: 'PagedResponse';
  /** Content */
  content: Array<Maybe<RefundRequestInfo>>;
  /** Pagination info */
  paginationInfo: PaginationInformation;
};

/** Paginated Article Reviews */
export type PaginatedArticleReviews = {
  __typename?: 'PaginatedArticleReviews';
  /** content */
  content: Array<Review>;
  /** pagination info */
  paginationInfo: PaginationInfo;
};

/** Pagination info */
export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  /** The offset used to retrieve those data */
  currentOffset: Scalars['Int']['output'];
  /** Whether there is a next page */
  hasNextPage: Scalars['Boolean']['output'];
  /** The limit used to retrieve those data */
  limit: Scalars['Int']['output'];
  /** Total number of elements */
  totalCount: Scalars['Int']['output'];
};

/** Pagination info */
export type PaginationInformation = {
  __typename?: 'PaginationInformation';
  /** limit element */
  limitElement: Scalars['Int']['output'];
  /** Links */
  links: Array<LinkEnumMap>;
  /** Page number */
  pageNumber: Scalars['Int']['output'];
  /** Total items */
  totalItems: Scalars['Int']['output'];
  /** Total pages */
  totalPages: Scalars['Int']['output'];
};

/** Pagination input */
export type PaginationInput = {
  /** limit */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** page */
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Parcel type */
export type Parcel = {
  __typename?: 'Parcel';
  /**
   * The carrier delivery offer identifier used to ship the Parcel, can be represented as a Long
   * example: "12"
   */
  deliveryOfferId?: Maybe<Scalars['String']['output']>;
  /**
   * The carrier delivery offer name used to ship the Parcel
   * example: Colissimo domicile sans signature
   */
  deliveryOfferName?: Maybe<Scalars['String']['output']>;
  /**
   * The Parcel unique identifier
   * example: 42 or 60dc8d3f76c4f70001789057
   */
  id: Scalars['ID']['output'];
  /** For ManoFulfillment, List of items in the parcel */
  items: Array<ParcelItem>;
  /**
   * The Parcel shipping date
   * example: 2021-07-02T14:42:42+02:00
   */
  shippedAt: Scalars['String']['output'];
  /** Information about the parcels departure location is exposed */
  shippedFrom?: Maybe<ParcelShippedFrom>;
  /** The parcel timeline */
  timeline?: Maybe<ParcelTimeline>;
  /**
   * The Parcel carrier tracking number
   * example: 6A21401603201
   */
  trackingNumber?: Maybe<Scalars['String']['output']>;
  /**
   * The Parcel carrier tracking URL
   * example: https://www.laposte.fr/particulier/outils/suivre-vos-envois?code=6A21401603201
   */
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

/** Product shipped */
export type ParcelItem = {
  __typename?: 'ParcelItem';
  /**
   * Product id shipped
   * example: "42", can be represented as a Long
   */
  productId: Scalars['String']['output'];
  /**
   * Quantity shipped
   * example: 5
   */
  quantity: Scalars['Int']['output'];
};

/** Information about the parcels departure location */
export type ParcelShippedFrom = {
  __typename?: 'ParcelShippedFrom';
  /**
   * The city where the parcel comes from. Null for SELLER warehouses
   * example: Châtres
   */
  city?: Maybe<Scalars['String']['output']>;
  /**
   * The country where the parcel comes from
   * example: FR
   */
  country: Scalars['String']['output'];
  /**
   * The street name where the parcel comes from. Null for SELLER warehouses
   * example: ZAC du Val Bréon,\n2 Rue Louis Renaud
   */
  street?: Maybe<Scalars['String']['output']>;
  /**
   * The slug of the warehouse where the parcel comes from. Null for SELLER warehouses
   * example: CHA
   */
  warehouseSlug?: Maybe<Scalars['String']['output']>;
  /**
   * The zipcode where the parcel comes from. Null for SELLER warehouses
   * example: 77610
   */
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Parcel Timeline type */
export type ParcelTimeline = {
  __typename?: 'ParcelTimeline';
  /**
   * If one or more incident has occurred, it will be true.
   * /!\ Beware that it does not indicates that the delivery is failed, but just that at least one incident occurred in the history (it can be solved or not).
   * example: true
   */
  hasIncident: Scalars['Boolean']['output'];
  /** Indicates if carrier contact is required */
  isCarrierContactRequired: Scalars['Boolean']['output'];
  /**
   * If one or more incident has occurred, indicates if the current status is still in incident or solved. Will be null if no incident occurred.
   * example: true
   */
  isIncidentSolved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the order is traced */
  isTraced: Scalars['Boolean']['output'];
  /**
   * An ISO 8601 datetime format.
   * example: 2018-06-08T15:54:16+02:00
   */
  parcelDeliveredAt?: Maybe<Scalars['String']['output']>;
  /**
   * An ISO 8601 datetime format.
   * example: 2018-06-08T15:54:16+02:00
   */
  parcelInDistributionAt?: Maybe<Scalars['String']['output']>;
  /**
   * An ISO 8601 datetime format.
   * example: 2018-06-08T15:54:16+02:00
   */
  parcelInRelayPointAt?: Maybe<Scalars['String']['output']>;
  /**
   * An ISO 8601 datetime format.
   * example: 2018-06-08T15:54:16+02:00
   */
  parcelSentAt?: Maybe<Scalars['String']['output']>;
};

/** A decision tree with children */
export type ParentNode = NodeLabel & {
  __typename?: 'ParentNode';
  /** The node's children */
  children?: Maybe<Array<Node>>;
  /** The label translated in the platform language */
  label: Scalars['String']['output'];
};

/** PartnerAdCampaign object */
export type PartnerAdCampaign = {
  __typename?: 'PartnerAdCampaign';
  /** ID / citrusAdId to pass to citrus tracking */
  id: Scalars['ID']['output'];
  /** Desktop / mobile URls and alternative text for accessibility purpose */
  image: PartnerAdCampaignImage;
  /** Label of the campaign for this space */
  label?: Maybe<PartnerAdCampaignLabel>;
  /** Redirection link and its target */
  linkUrl?: Maybe<Scalars['String']['output']>;
  /** Logo url of the campaign for this space / heroImage */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** Name of the campaign / citrusCampaignName */
  name: Scalars['String']['output'];
  /** ID of the products */
  pageType?: Maybe<Scalars['String']['output']>;
  /** ID of the seller */
  productIds: Array<Scalars['ID']['output']>;
  /** Sponsor of the campaign */
  sellerAccountId: Scalars['ID']['output'];
  /** Subtitle of the campaign for this space / additionalFields.subtitle-text */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the campaign for this space / additionalFields.title */
  title?: Maybe<Scalars['String']['output']>;
  /** Tracking ID of the campaign */
  trackingId?: Maybe<Scalars['ID']['output']>;
};

/** PartnerAdCampaignSpaceImage object */
export type PartnerAdCampaignImage = {
  __typename?: 'PartnerAdCampaignImage';
  /** Alternative text for accessibility purpose / headingText */
  altText: Scalars['String']['output'];
  /** Desktop image URL / backgroundImage */
  desktopUrl: Scalars['String']['output'];
  /** Mobile image URL / secondaryBackgroundImage */
  mobileUrl: Scalars['String']['output'];
};

/**
 * PartnerAdCampaignLabel object
 *
 * Indicates the label of the partner ad campaign.
 */
export type PartnerAdCampaignLabel = {
  __typename?: 'PartnerAdCampaignLabel';
  /** Format of label / additionalFields.label */
  format?: Maybe<PartnerAdLabelFormat>;
  /** First line of the label / additionalFields.first-line */
  line1?: Maybe<Scalars['String']['output']>;
  /** Second line of the label / additionalFields.second-line */
  line2?: Maybe<Scalars['String']['output']>;
};

/** PartnerAdCampaignsContext object */
export type PartnerAdCampaignsContext = {
  /** Channel */
  channel: Channel;
  /** Market */
  market: Market;
  /** Platform */
  platform: Platform;
  /** User Session Id (mm_visitor_id) */
  sessionId: Scalars['ID']['input'];
  /** Space */
  space: PartnerAdSpace;
};

/** PartnerAdCampaignsQuery object */
export type PartnerAdCampaignsQuery = {
  __typename?: 'PartnerAdCampaignsQuery';
  /** Retrieve PartnerAd campaigns by a list of categories ids */
  listByCategories?: Maybe<Array<PartnerAdCampaign>>;
  /** Retrieve PartnerAd campaigns by context. Context includes platform, market, space, channel and user session ID (aka mm_visitor_id) */
  listByContext?: Maybe<Array<PartnerAdCampaign>>;
  /** Retrieve PartnerAd campaigns by query string */
  listByQuery?: Maybe<Array<PartnerAdCampaign>>;
};

/** PartnerAdCampaignsQuery object */
export type PartnerAdCampaignsQuerylistByCategoriesArgs = {
  categoryIds: Array<Scalars['ID']['input']>;
  context: PartnerAdCampaignsContext;
};

/** PartnerAdCampaignsQuery object */
export type PartnerAdCampaignsQuerylistByContextArgs = {
  context: PartnerAdCampaignsContext;
};

/** PartnerAdCampaignsQuery object */
export type PartnerAdCampaignsQuerylistByQueryArgs = {
  context: PartnerAdCampaignsContext;
  query: Scalars['String']['input'];
};

/**
 * PartnerAdLabelFormat enum
 *
 * Defines the format of the label in the partner ad.
 */
export enum PartnerAdLabelFormat {
  BIG_SMALL = 'BIG_SMALL',
  MIDDLE_MIDDLE = 'MIDDLE_MIDDLE',
  SMALL_BIG = 'SMALL_BIG',
}

/**
 * PartnerAdSpace enum
 *
 * Indicates where the partner ad is displayed.
 */
export enum PartnerAdSpace {
  BRAND_BLOCK = 'BRAND_BLOCK',
  LISTING_MIDDLE_CATEGORY = 'LISTING_MIDDLE_CATEGORY',
  LISTING_MIDDLE_SEARCH = 'LISTING_MIDDLE_SEARCH',
  PROMO_BLOCK = 'PROMO_BLOCK',
  RECTANGULAR_BLOCK = 'RECTANGULAR_BLOCK',
}

/** PayIn type */
export type PayIn = {
  __typename?: 'PayIn';
  /** Amount */
  amount: ComputationPrice;
  /** Coupon code */
  couponCode?: Maybe<Scalars['String']['output']>;
  /** Coupon initiative */
  couponInitiative?: Maybe<Scalars['String']['output']>;
  /** Pay in entry id */
  payInEntryId?: Maybe<Scalars['ID']['output']>;
  /** Payment mean */
  paymentMean: PaymentMean;
  /** Payment time */
  paymentTime: Scalars['String']['output'];
  /** Psp */
  psp?: Maybe<Psp>;
};

/** A pay in entry */
export type PayInEntry = {
  __typename?: 'PayInEntry';
  /** Amount of the pay in entry */
  amount: PayInEntryAmount;
  /** Creation date and time of the pay in entry */
  createdAt: Scalars['String']['output'];
  /** Customer who made the payment associated with the pay in entry */
  customer: PayInEntryCustomer;
  /** Error details (if any) associated with the pay in entry */
  error?: Maybe<PayInEntryError>;
  /** Unique identifier of the pay in entry under UUID format */
  id: Scalars['ID']['output'];
  /** Payment issuer associated with the pay in entry */
  issuer: PayInEntryPaymentIssuer;
  /** Action to be performed by the frontend after the pay in entry is initialized */
  nextAction: NextAction;
  /** Unique identifier of the related payment under UUID format */
  paymentId: Scalars['ID']['output'];
  /** Details of the payment mean associated with the pay in entry */
  paymentMean: PayInEntryPaymentMeanDetails;
  /** Platform on which the pay in entry was initialized (DE, ES, FR, GB, IT) */
  platform: Platform;
  /** Name of the Payment Service Provider associated with the pay in entry */
  pspName: Scalars['String']['output'];
  /** PSP process result of the pay in entry */
  pspProcessResult?: Maybe<Scalars['String']['output']>;
  /** Sales market of the customer order associated with the pay in entry (B2B or B2C) */
  salesMarket: Market;
  /** Status of the pay in entry */
  status: PayInEntryStatus;
};

/** Amount of the pay in entry */
export type PayInEntryAmount = {
  __typename?: 'PayInEntryAmount';
  /** Amount value */
  amount: Scalars['Float']['output'];
  /** Currency */
  currency: Currency;
};

/** Customer details */
export type PayInEntryCustomer = {
  __typename?: 'PayInEntryCustomer';
  /** Email of the customer */
  email: Scalars['String']['output'];
  /** Customer ids */
  ids: PayInEntryCustomerIds;
  /** Customer type (e.g. customer, company) */
  type: PaymentCustomerType;
};

/** Customer identifiers */
export type PayInEntryCustomerIds = {
  __typename?: 'PayInEntryCustomerIds';
  /** Customer xcid under int format (e.g. 123456789) */
  legacyId?: Maybe<Scalars['ID']['output']>;
  /** Customer unique identifier under UUID format */
  manomanoId: Scalars['ID']['output'];
  /** Visitor unique identifier under UUID format */
  visitorId?: Maybe<Scalars['ID']['output']>;
};

/** Error details associated with the pay in entry */
export type PayInEntryError = {
  __typename?: 'PayInEntryError';
  /** Error code */
  errorCode: Scalars['String']['output'];
  /** Error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Error sub code */
  errorSubCode?: Maybe<Scalars['String']['output']>;
};

/** Payment issuer details */
export type PayInEntryPaymentIssuer = {
  __typename?: 'PayInEntryPaymentIssuer';
  /** Issuer unique identifier under UUID format */
  issuerId: Scalars['ID']['output'];
  /** Issuer reference for human-readable purposes (e.g. FRC240925-E8E95B2629EA) */
  issuerReference: Scalars['String']['output'];
  /** Issuer type (e.g. customer_order) */
  issuerType: PaymentIssuerType;
};

/** Details of the payment mean used for the pay in entry */
export type PayInEntryPaymentMeanDetails = {
  __typename?: 'PayInEntryPaymentMeanDetails';
  /** Bank details */
  bankDetails?: Maybe<BankDetails>;
  /** Card details */
  cardDetails?: Maybe<CardDetails>;
  /** Klarna details */
  klarnaDetails?: Maybe<KlarnaDetails>;
  /** Paypal details */
  paypalDetails?: Maybe<PaypalDetails>;
  /** Indicates if the payment mean should be saved */
  savePaymentMean: Scalars['Boolean']['output'];
  /** Indicates if a saved payment mean has been used */
  savedPaymentMean?: Maybe<SavedPaymentMean>;
  /** Type of the payment mean (e.g. card, paypal, klarna) */
  type: PaymentMeanType;
};

/** Status of the pay in entry */
export enum PayInEntryStatus {
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  PARTIALLY_FUNDED = 'PARTIALLY_FUNDED',
  PSP_INITIALIZATION_FAILED = 'PSP_INITIALIZATION_FAILED',
  PSP_INITIALIZED = 'PSP_INITIALIZED',
}

/** A payment */
export type Payment = {
  __typename?: 'Payment';
  /** Billing address for the payment */
  billingAddress: PaymentBillingAddress;
  /** Customer details associated with the payment */
  customer: PaymentCustomer;
  /** Delivery address for the payment */
  deliveryAddress: PaymentDeliveryAddress;
  /** Indicates if all sellers are outside the EEA */
  hasAllSellersOutsideEea: Scalars['Boolean']['output'];
  /** Payment id is an unique key under UUID format (eb729c04-957d-4819-8bef-26fb72a23b84) */
  id: Scalars['ID']['output'];
  /** Issuer details of the payment */
  issuer: PaymentIssuer;
  /** Amounts related to the payment */
  paymentAmounts: PaymentAmounts;
  /** List of payment attempts */
  paymentAttempts: Array<PaymentAttempt>;
  /** Context of the payment */
  paymentContext: PaymentContext;
  /** Dates related to the payment */
  paymentDates: PaymentDates;
  /** Status of the payment */
  status: PaymentStatus;
  /** List of payment attempts that submitted the payment */
  submittedBy: Array<PaymentAttempt>;
};

/** An amount with currency */
export type PaymentAmount = {
  __typename?: 'PaymentAmount';
  /** Amount value */
  amount: Scalars['Float']['output'];
  /** Currency of the amount */
  currency: Currency;
};

/** Represents various amounts related to the payment */
export type PaymentAmounts = {
  __typename?: 'PaymentAmounts';
  /** Total amount to pay */
  amountToPay: PaymentAmount;
  /** Amount that has been fulfilled */
  fulfilledAmount: PaymentAmount;
  /** Paid amount */
  paidAmount: PaymentAmount;
  /** Remaining amount to fulfill */
  remainingAmountToFulfill: PaymentAmount;
  /** Remaining amount to pay */
  remainingAmountToPay: PaymentAmount;
};

/** A payment attempt */
export type PaymentAttempt = {
  __typename?: 'PaymentAttempt';
  /** Amount of the payment attempt */
  amount: PaymentAmount;
  /** Creation date of the payment attempt */
  createdAt: Scalars['String']['output'];
  /** Payment attempt id is an unique key under UUID format (eb729c04-957d-4819-8bef-26fb72a23b84) */
  id: Scalars['ID']['output'];
  /** Unique identifier of the related pay in entry under UUID format */
  payInEntry: PayInEntry;
  /** Payment mean of the payment attempt */
  paymentMean: PaymentMeanType;
  /** Psp name of the payment attempt */
  pspName: Scalars['String']['output'];
  /** Status of the payment attempt */
  status: PaymentAttemptStatus;
  /** Last update date of the payment attempt */
  updatedAt: Scalars['String']['output'];
};

/** Enumeration of payment attempt statuses */
export enum PaymentAttemptStatus {
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  DENIED = 'DENIED',
  FAILED = 'FAILED',
  SUBMISSION_FAILED = 'SUBMISSION_FAILED',
  SUBMITTED = 'SUBMITTED',
}

/** Billing address */
export type PaymentBillingAddress = {
  __typename?: 'PaymentBillingAddress';
  /** City */
  city?: Maybe<Scalars['String']['output']>;
  /** Company */
  company?: Maybe<Scalars['String']['output']>;
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** First name of the recipient */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last name of the recipient */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Address lines */
  lines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode?: Maybe<Scalars['String']['output']>;
};

/** Context of the payment */
export type PaymentContext = {
  __typename?: 'PaymentContext';
  /** Canal through which the payment was made */
  canal?: Maybe<Canal>;
  /** Device used for the payment */
  device?: Maybe<Scalars['String']['output']>;
  /** Market of the payment */
  market: Market;
  /** Operating system used for the payment */
  os?: Maybe<Scalars['String']['output']>;
  /** Platform on which the payment was made */
  platform: Platform;
};

/** Details of the customer */
export type PaymentCustomer = {
  __typename?: 'PaymentCustomer';
  /** Email of the customer */
  email: Scalars['String']['output'];
  /** Identifiers of the customer */
  ids: PaymentCustomerIds;
  /** Type of the customer */
  type: PaymentCustomerType;
};

/** Identifiers of the customer */
export type PaymentCustomerIds = {
  __typename?: 'PaymentCustomerIds';
  /** Unique identifier of the legacy customer under String format */
  legacyId?: Maybe<Scalars['ID']['output']>;
  /** Unique identifier of the ManoMano customer under UUID format */
  manomanoId: Scalars['ID']['output'];
  /** Unique identifier of the visitor under UUID format */
  visitorId?: Maybe<Scalars['ID']['output']>;
};

/** Customer type */
export enum PaymentCustomerType {
  COMPANY = 'COMPANY',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_B2B = 'CUSTOMER_B2B',
}

/** Payment dates */
export type PaymentDates = {
  __typename?: 'PaymentDates';
  /** Creation date of the payment */
  createdAt: Scalars['String']['output'];
  /** Due date of the payment */
  dueAt: Scalars['String']['output'];
  /** Expiration date of the payment */
  expiredAt: Scalars['String']['output'];
  /** Last update date of the payment */
  updatedAt: Scalars['String']['output'];
};

/** Delivery address */
export type PaymentDeliveryAddress = {
  __typename?: 'PaymentDeliveryAddress';
  /** City */
  city?: Maybe<Scalars['String']['output']>;
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** First name of the recipient */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last name of the recipient */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Address lines */
  lines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Indicates if pickup delivery is chosen */
  pickupDeliveryChosen?: Maybe<Scalars['Boolean']['output']>;
  /** Postal code */
  postalCode?: Maybe<Scalars['String']['output']>;
};

/** Details of the issuer */
export type PaymentIssuer = {
  __typename?: 'PaymentIssuer';
  /** Unique identifier of the issuer under UUID format */
  issuerId: Scalars['ID']['output'];
  /** Reference identifier of the issuer */
  issuerReference: Scalars['String']['output'];
  /** Payment issuer */
  issuerType: PaymentIssuerType;
};

/** Issuer type */
export enum PaymentIssuerType {
  CUSTOMER_ORDER = 'CUSTOMER_ORDER',
  /** For testing purposes, without a link to a real resource such as a customer order */
  PENNY_TEST = 'PENNY_TEST',
}

/** Enumeration of payment means */
export enum PaymentMean {
  /** Apple pay */
  APPLE_PAY = 'APPLE_PAY',
  /** Bank check */
  BANK_CHECK = 'BANK_CHECK',
  /** Bank transfer */
  BANK_TRANSFER = 'BANK_TRANSFER',
  /** Billie */
  BILLIE = 'BILLIE',
  /** Credit card */
  CARD = 'CARD',
  /** Deferred payment */
  DEFERRED_PAYMENT = 'DEFERRED_PAYMENT',
  /** Giropay */
  GIROPAY = 'GIROPAY',
  /** Google pay */
  GOOGLE_PAY = 'GOOGLE_PAY',
  /** Klarna */
  KLARNA = 'KLARNA',
  /** Oney */
  ONEY = 'ONEY',
  /** Paypal */
  PAYPAL = 'PAYPAL',
  /** Sofort */
  SOFORT = 'SOFORT',
}

/** A payment mean with details that can be used to pay */
export type PaymentMeanDetails = {
  __typename?: 'PaymentMeanDetails';
  /** Indicates if the payment mean is enabled */
  enabled: Scalars['Boolean']['output'];
  /** Name of the payment mean */
  name: Scalars['String']['output'];
  /** Identifier of the payment mean */
  paymentMean: Scalars['String']['output'];
  /** Payment service provider ID to use with the payment mean */
  pspId: Scalars['String']['output'];
};

/** Enumeration of payment means */
export enum PaymentMeanType {
  APPLE_PAY = 'APPLE_PAY',
  BANK_CHECK = 'BANK_CHECK',
  BANK_TRANSFER = 'BANK_TRANSFER',
  BILLIE = 'BILLIE',
  CARD = 'CARD',
  DEFERRED_PAYMENT = 'DEFERRED_PAYMENT',
  GIROPAY = 'GIROPAY',
  GIRO_PAY = 'GIRO_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  KLARNA = 'KLARNA',
  ONEY = 'ONEY',
  PAYPAL = 'PAYPAL',
  SOFORT = 'SOFORT',
  UNKNOWN = 'UNKNOWN',
}

/** Defines the types of payment methods */
export enum PaymentMethodType {
  /** Represents a card payment method */
  CARD = 'CARD',
}

/** A payment refund */
export type PaymentRefund = {
  __typename?: 'PaymentRefund';
  /** List of customer refunds */
  customerRefunds: Array<CustomerRefund>;
  /** A payment */
  payment: Payment;
};

/** SDK keys and merchant id for payment providers */
export type PaymentSdkKeys = {
  __typename?: 'PaymentSdkKeys';
  /** merchant id */
  merchantId?: Maybe<Scalars['String']['output']>;
  /** Stripe SDK key */
  stripe?: Maybe<Scalars['String']['output']>;
};

/** Input type for payment SDK keys request */
export type PaymentSdkKeysRequest = {
  /** Market of the payment SDK keys */
  market?: InputMaybe<Market>;
  /** Platform of the payment SDK keys */
  platform: Platform;
};

/** Enumeration of payment statuses */
export enum PaymentStatus {
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  SUBMITTED = 'SUBMITTED',
}

/** Paypal details */
export type PaypalDetails = {
  __typename?: 'PaypalDetails';
  /** Id of the Paypal transaction */
  transactionId?: Maybe<Scalars['String']['output']>;
};

/** Pickup point */
export type PickupPoint = {
  __typename?: 'PickupPoint';
  /**
   * Expressed in meters
   * example: 153
   */
  distance: Scalars['Int']['output'];
  /**
   * Pickup point id
   * example: SOGP-1491
   */
  id: Scalars['String']['output'];
  /** Pickup point location */
  location: PickupPointLocation;
  /**
   * Pickup point name
   * example: Pressing Fondaudège
   */
  name: Scalars['String']['output'];
  /** Pickup point opening hours */
  openingHours: Array<PickupPointOpeningHour>;
};

/**
 * Input type for pickup point
 * Describe pickup point data and location wanted by the customer
 */
export type PickupPointInput = {
  /**
   * Address of the pickup point
   * example: 42 rue de la gare
   */
  address: Scalars['String']['input'];
  /**
   * City of the pickup point
   * example: Paris
   */
  city: Scalars['String']['input'];
  /**
   * Country of the pickup point
   * example: FR (ISO 3166-1)
   */
  country: Scalars['String']['input'];
  /**
   * Unique identifier for the pickup point
   * example: rp-123
   */
  id: Scalars['String']['input'];
  /**
   * Name of the pickup point
   * example: Librairie de la gare
   */
  name: Scalars['String']['input'];
  /**
   * Postal code of the pickup point
   * example: 75008
   */
  postalCode: Scalars['String']['input'];
};

/** Pickup point location */
export type PickupPointLocation = {
  __typename?: 'PickupPointLocation';
  /** Pickup point address */
  address: PickupPointLocationAddress;
  /** Pickup point geographical coordinates */
  geoCoordinates: PickupPointLocationGeoCoordinates;
};

/** Address of a pickup point */
export type PickupPointLocationAddress = {
  __typename?: 'PickupPointLocationAddress';
  /**
   * City of the address
   * example: Paris
   */
  city: Scalars['String']['output'];
  /**
   * Country code of the address
   * example: FR
   */
  country: Scalars['String']['output'];
  /**
   * Street of the address
   * example: 52 rue bayen
   */
  street?: Maybe<Scalars['String']['output']>;
  /**
   * Zip code of the address
   * example: 75017
   */
  zipCode: Scalars['String']['output'];
};

/** Geographical coordinates of a pickup point */
export type PickupPointLocationGeoCoordinates = {
  __typename?: 'PickupPointLocationGeoCoordinates';
  /** example: 2.2894597 */
  latitude: Scalars['Float']['output'];
  /** example: 48.88326 */
  longitude: Scalars['Float']['output'];
};

/** Pickup point opening hours */
export type PickupPointOpeningHour = {
  __typename?: 'PickupPointOpeningHour';
  /** example: Monday */
  day: Scalars['String']['output'];
  /** example: 09:00-13:00 / 14:30-19:00 */
  hours: Scalars['String']['output'];
  /** example: true */
  isOpen: Scalars['Boolean']['output'];
};

/** Request object for fetching pickup points */
export type PickupPointsByTypeInput = {
  /** Location details */
  location: PickupPointsByTypeLocationInput;
  /**
   * Pickup api slug used to define which pickup point api to use
   * examples (not exhaustive): "dpd-fr", "cchezvous", "correos", "gls", "boxtal-mondial-relay"
   */
  pickupPointsApiSlug: Scalars['String']['input'];
};

/** Address input for fetching pickup points */
export type PickupPointsByTypeLocationAddressInput = {
  /**
   * City of the address
   * example: Paris
   */
  city: Scalars['String']['input'];
  /**
   * Country code of the address
   * example: FR
   */
  country: Scalars['String']['input'];
  /**
   * Street of the address
   * example: 52 rue bayen
   */
  street?: InputMaybe<Scalars['String']['input']>;
  /**
   * Zip code of the address
   * example: 75017
   */
  zipCode: Scalars['String']['input'];
};

/** Geographical coordinates input for fetching pickup points */
export type PickupPointsByTypeLocationGeoCoordinatesInput = {
  /**
   * Latitude of the location
   * example: 2.2894597
   */
  latitude: Scalars['Float']['input'];
  /**
   * Longitude of the location
   * example: 48.88326
   */
  longitude: Scalars['Float']['input'];
};

/** Address and geographical coordinates input for fetching pickup points */
export type PickupPointsByTypeLocationInput = {
  /** Address details */
  address: PickupPointsByTypeLocationAddressInput;
  /** Geographical coordinates */
  geoCoordinates: PickupPointsByTypeLocationGeoCoordinatesInput;
};

/** Picture */
export type Picture = {
  __typename?: 'Picture';
  /** resource link */
  resourceLink: Scalars['String']['output'];
  /** uuid */
  uuid: Scalars['String']['output'];
};

/** Platform enum */
export enum Platform {
  /** Germany */
  DE = 'DE',
  /** Spain */
  ES = 'ES',
  /** France */
  FR = 'FR',
  /** Great Britain */
  GB = 'GB',
  /** Italy */
  IT = 'IT',
}

/** Enum representing a platform */
export enum PlatformDto {
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  IT = 'IT',
}

/** PreHeader object */
export type PreHeader = {
  __typename?: 'PreHeader';
  /** Pre header A/B test */
  abTest?: Maybe<ABTest>;
  /** Advertiser associated to pre header */
  advertiser?: Maybe<Scalars['String']['output']>;
  /** Channel */
  channel: Channel;
  /** CSS classname to be applied to the pre header */
  className?: Maybe<Scalars['String']['output']>;
  /** When set, the front-end will display a multi-purpose, arbitrary countdown that ends on the specified timestamp */
  countdownEndAt?: Maybe<Scalars['DateTime']['output']>;
  /** Pre (top) or post (bottom) header choice */
  displayPosition: Scalars['String']['output'];
  /** Date and time the pre header will stop being active */
  endAt?: Maybe<Scalars['Int']['output']>;
  /** Pre header feature flag */
  featureFlag?: Maybe<FeatureFlag>;
  /** ID */
  id: Scalars['String']['output'];
  /** Image to include in the pre header */
  image?: Maybe<PreHeaderImage>;
  /** Link to include in the pre header */
  link?: Maybe<Link>;
  /** Market */
  market: Market;
  /** Text to be displayed in the pre header */
  message?: Maybe<Scalars['String']['output']>;
  /** Platform */
  platform: Platform;
  /** Position the pre header will be displayed in */
  position: Scalars['Int']['output'];
  /** Sponsor associated to pre header */
  sponsor?: Maybe<Scalars['String']['output']>;
  /** Date and time the pre header will start being active */
  startAt?: Maybe<Scalars['Int']['output']>;
  /** Pre header status (live, planned, archived) */
  status: Scalars['String']['output'];
  /** User segments associated to the pre header */
  userSegments?: Maybe<Scalars['Map']['output']>;
};

/** PreHeaderImage object */
export type PreHeaderImage = {
  __typename?: 'PreHeaderImage';
  /** Alternative text for accessibility purposes */
  altText: Scalars['String']['output'];
  /** Desktop image URL */
  desktopUrl?: Maybe<Scalars['String']['output']>;
  /** Mobile image URL */
  mobileUrl?: Maybe<Scalars['String']['output']>;
};

/** Price */
export type Price = {
  __typename?: 'Price';
  /** currency: Currency! */
  currency: Currency;
  /** current_price: CurrentPrice */
  current_price?: Maybe<CurrentPrice>;
  /** discount_percentage_for_primary_price: Int */
  discount_percentage_for_primary_price?: Maybe<Scalars['Int']['output']>;
  /** eco_participation_with_vat: BigDecimal */
  eco_participation_with_vat?: Maybe<Scalars['BigDecimal']['output']>;
  /**
   * is_payment3x_activated: Boolean! Deprecated
   * @deprecated This field has been deprecated by Rhino.
   */
  is_payment3x_activated: Scalars['Boolean']['output'];
  /** measurement_price: MeasurementPrice */
  measurement_price?: Maybe<MeasurementPrice>;
  /** measurement_price_is_primary_price: Boolean! */
  measurement_price_is_primary_price: Scalars['Boolean']['output'];
  /** platform: Platform! */
  platform: Platform;
  /** product_id: Int! */
  productId?: Maybe<Scalars['Int']['output']>;
  /** retail_price: PriceDetail */
  retail_price?: Maybe<PriceDetail>;
};

/** PriceAdvantage: Difference between b2b and b2c prices */
export type PriceAdvantage = {
  __typename?: 'PriceAdvantage';
  /** amountSaved: Float. Total difference between b2b and b2c price */
  amountSaved?: Maybe<Scalars['Float']['output']>;
  /** amountSavedPercentage: Int. Difference in percentage between b2b and b2c price */
  amountSavedPercentage?: Maybe<Scalars['Int']['output']>;
  /** model id */
  modelId: Scalars['BigInteger']['output'];
  /** Platform of the product */
  platform: Platform;
};

/** PriceDetail */
export type PriceDetail = {
  __typename?: 'PriceDetail';
  /** amount_with_vat: BigDecimal! */
  amount_with_vat: Scalars['BigDecimal']['output'];
  /** amount_without_vat: BigDecimal! */
  amount_without_vat: Scalars['BigDecimal']['output'];
  /** vat_rate: BigDecimal! */
  vat_rate: Scalars['BigDecimal']['output'];
};

/** Price input */
export type PriceInput = {
  /** Price amount */
  amount: Scalars['BigDecimalQl']['input'];
  /** currency */
  currency: Currency;
};

/** Price range input type. */
export type PriceRange = {
  /** Maximum price */
  max?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum price */
  min?: InputMaybe<Scalars['Float']['input']>;
};
export type ProOffer = {
  __typename?: 'ProOffer';
  appLink?: Maybe<Scalars['String']['output']>;
  block: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  line: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  mobileImageUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  relativeLink: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Product */
export type Product = {
  __typename?: 'Product';
  /** article_id: BigInteger */
  article_id?: Maybe<Scalars['BigInteger']['output']>;
  /** is_pro: Boolean! */
  is_pro: Scalars['Boolean']['output'];
  /** is_sellable: Boolean */
  is_sellable?: Maybe<Scalars['Boolean']['output']>;
  /** masterProduct: MasterProduct! */
  masterProduct: MasterProduct;
  /** model_id: BigInteger */
  model_id?: Maybe<Scalars['BigInteger']['output']>;
  /** offer: Offer */
  offer?: Maybe<Offer>;
  /** platform: Platform! */
  platform: Platform;
  /** product_id: BigInteger */
  product_id?: Maybe<Scalars['BigInteger']['output']>;
  /** sku: Sku */
  sku?: Maybe<Sku>;
};

/** ProductAdvantageResponseV2 */
export type ProductAdvantageResponseV2 = {
  __typename?: 'ProductAdvantageResponseV2';
  /** article id */
  articleId: Scalars['Int']['output'];
  /** category lowest level */
  categoryLowestLevel: Scalars['Int']['output'];
  /** created at */
  createdAt: Scalars['String']['output'];
  /** id */
  id: Scalars['Int']['output'];
  /** model id */
  modelId: Scalars['Int']['output'];
  /** platform */
  platform: Scalars['String']['output'];
  /** product advantage 1 */
  productAdvantage1?: Maybe<Scalars['String']['output']>;
  /** product advantage 2 */
  productAdvantage2?: Maybe<Scalars['String']['output']>;
  /** product advantage 3 */
  productAdvantage3?: Maybe<Scalars['String']['output']>;
  /** product advantage 4 */
  productAdvantage4?: Maybe<Scalars['String']['output']>;
  /** source */
  source: Source;
};

/** Product collection external entity */
export type ProductCollection = {
  __typename?: 'ProductCollection';
  /** Whether these products are reserved to professional accounts */
  isPro: Scalars['Boolean']['output'];
  /** Maximum number of products to fetch */
  limit: Scalars['Int']['output'];
  /** Identifiers of the searched models */
  modelIds: Array<Scalars['BigInteger']['output']>;
  /** Associated platform */
  platform: Platform;
  /** products: [Product!]! */
  products: Array<Product>;
};

/** Represent a product history */
export type ProductHistory = {
  __typename?: 'ProductHistory';
  /** Product id */
  productId: Scalars['Int']['output'];
  /** Viewed at */
  viewedAt: Scalars['String']['output'];
};

/** Image of the product for the carousel */
export type ProductImage = {
  __typename?: 'ProductImage';
  /** URL of the image */
  imageUrl: Scalars['String']['output'];
  /** Title of the product */
  title: Scalars['String']['output'];
  /** URL of the product */
  url: Scalars['String']['output'];
};

/** Product-Order */
export type ProductOrder = {
  __typename?: 'ProductOrder';
  /** Catalog price (VAT included) */
  catalogUnitPriceVatIncluded: CustomerOrderPrice;
  /** External product ID */
  externalId: Scalars['ID']['output'];
  /** Product-Order ID */
  id: Scalars['ID']['output'];
  /** Offer ID */
  offerId?: Maybe<Scalars['ID']['output']>;
  /** Information about the Product */
  productInfo: ProductOrderInfo;
  /** Quantity */
  quantity: Scalars['Int']['output'];
  /** Seller contract ID */
  sellerContractId: Scalars['ID']['output'];
};

/** Product Attribute (Weight, Color, Size, etc.) */
export type ProductOrderAttribute = {
  __typename?: 'ProductOrderAttribute';
  /** Attribute ID */
  id: Scalars['ID']['output'];
  /** Attribute name */
  name: Scalars['String']['output'];
  /** Attribute value */
  value: Scalars['String']['output'];
};

/** Product-Order Information */
export type ProductOrderInfo = {
  __typename?: 'ProductOrderInfo';
  /** Article ID */
  articleId?: Maybe<Scalars['ID']['output']>;
  /** Product Attributes */
  attributes: Array<ProductOrderAttribute>;
  /** Product Brand ID */
  brandId?: Maybe<Scalars['ID']['output']>;
  /** Product Category ID */
  categoryId: Scalars['ID']['output'];
  /** Product Description */
  description?: Maybe<Scalars['String']['output']>;
  /** European Article Number */
  ean?: Maybe<Scalars['String']['output']>;
  /** Image path */
  image: Scalars['String']['output'];
  /** Thumbnail path */
  imageThumbnail?: Maybe<Scalars['String']['output']>;
  /** ManoMano Fulfillment */
  isMf: Scalars['Boolean']['output'];
  /** ManoMano ID */
  mmId?: Maybe<Scalars['ID']['output']>;
  /** Model ID */
  modelId: Scalars['ID']['output'];
  /** Seller Account ID */
  sellerAccountId?: Maybe<Scalars['ID']['output']>;
  /** Seller's Stock Keeping Unit */
  sellerSku: Scalars['String']['output'];
  /** Product title */
  title: Scalars['String']['output'];
  /** VAT rate */
  vatRate: Scalars['BigDecimal']['output'];
  /** Weight in kilograms */
  weightKg: Scalars['BigDecimal']['output'];
};

/** Product-Order Input */
export type ProductOrderInput = {
  /** Product ID */
  productId: Scalars['ID']['input'];
  /** Quantity */
  quantity: Scalars['Int']['input'];
};

/** Content information for a given page */
export type ProductPage = PageMeshing & {
  __typename?: 'ProductPage';
  /** Block of links for bots */
  botLinks: Array<MeshingLink>;
  /** Associated platform */
  platform: Platform;
  /** URL of the page */
  url: Scalars['String']['output'];
  /** Primary block of links for user */
  userPrimaryLinks: Array<MeshingLink>;
  /** Secondary block of links for user */
  userSecondaryLinks: Array<MeshingLink>;
};

/** Product Rating Md */
export type ProductRatingMd = {
  __typename?: 'ProductRatingMd';
  /** average of ratings */
  avgRating: Scalars['Float']['output'];
  /** description of the question */
  description: Scalars['String']['output'];
  /** is it a top product ? */
  isTop: Scalars['Boolean']['output'];
  /** MD title (the question) */
  name: Scalars['String']['output'];
  /** total number of ratings */
  nbTotal: Scalars['Int']['output'];
  /** question id */
  questionId: Scalars['Int']['output'];
  /** best ranked articles for this MD and category/platform */
  ranking?: Maybe<Array<Maybe<MdStatsRanking>>>;
};

/** Product Review Picture */
export type ProductReviewPicture = {
  __typename?: 'ProductReviewPicture';
  /** Article Id */
  articleId: Scalars['Int']['output'];
  /** Review */
  review: ArticleRatingPictureReview;
  /** UUID */
  uuid: Scalars['ID']['output'];
};

/** Product selection from the user */
export enum ProductSelection {
  MANY = 'MANY',
  NONE = 'NONE',
  ONE = 'ONE',
}

/** Promise type */
export type Promise = {
  __typename?: 'Promise';
  /** Promise express mode */
  expressMode?: Maybe<Scalars['String']['output']>;
  /** Promise id */
  id: Scalars['ID']['output'];
  /** Promise mode */
  mode?: Maybe<Scalars['String']['output']>;
  /** Promise discount price without VAT */
  promiseDiscountedPriceVatExcl?: Maybe<ComputationPrice>;
  /** Promise discount price */
  promiseDiscountedPriceVatIncl?: Maybe<ComputationPrice>;
  /** Promise price without VAT */
  promisePriceVatExcl: ComputationPrice;
  /** Promise price */
  promisePriceVatIncl: ComputationPrice;
  /** Is promise recommended */
  recommended: Scalars['Boolean']['output'];
  /** Is promise selected */
  selected: Scalars['Boolean']['output'];
};

/** Promoted model external entity */
export type PromotedModel = {
  __typename?: 'PromotedModel';
  /** The advertiser is the natural or legal person on behalf of whom the advertisement is being presented */
  advertiser?: Maybe<Scalars['String']['output']>;
  /** Model identifier */
  modelId: Scalars['ID']['output'];
  /** Position of the model in the list */
  position: Scalars['Int']['output'];
  /** The sponsor is the natural or legal person who has paid for the advertisement */
  sponsor?: Maybe<Scalars['String']['output']>;
};

/** Enumeration of Psp */
export enum Psp {
  /** Adyen */
  ADYEN = 'ADYEN',
  /** Bnp */
  BNP = 'BNP',
  /** Limonetik */
  LIMONETIK = 'LIMONETIK',
  /** Manopay */
  MANOPAY = 'MANOPAY',
  /** Pledg */
  PLEDG = 'PLEDG',
  /** Sips */
  SIPS = 'SIPS',
  /** Stripe */
  STRIPE = 'STRIPE',
  /** Stripe source */
  STRIPE_SOURCE = 'STRIPE_SOURCE',
}

/** Item that the Customer has purchased at least once */
export type PurchasedAtLeastOnce = {
  __typename?: 'PurchasedAtLeastOnce';
  /** Last date the product has been purchased */
  lastDate?: Maybe<Scalars['DateTime']['output']>;
  /** Price when purchased last time */
  lastPrice?: Maybe<Scalars['Float']['output']>;
  /** The purchased product */
  product: ChannelOffer;
  /** If product has been purchased (lastDate, lastPrice and total have data) */
  purchased: Scalars['Boolean']['output'];
  /** Number of times the product has been purchased */
  total?: Maybe<Scalars['Int']['output']>;
};

/** A customer contact qualification */
export type Qualification = {
  __typename?: 'Qualification';
  /** If one, many or no products are selected */
  productSelection?: Maybe<ProductSelection>;
  /** Whether the selection of products is required */
  productSelectionRequired: Scalars['Boolean']['output'];
  /** The code for a given qualification */
  qualificationCode: Scalars['String']['output'];
  /** The recipient for a given qualification */
  recipient: Recipient;
  /** Whether this qualification requires a subqualification or not */
  subQualificationRequired?: Maybe<Scalars['Boolean']['output']>;
  /** A list of children of a qualification */
  subQualifications?: Maybe<Array<BaseQualification>>;
  /** The subqualification's title */
  subQualificationsTitle?: Maybe<Scalars['String']['output']>;
  /** The translated qualification based on an specific platform */
  translation?: Maybe<Scalars['String']['output']>;
};

/** Qualification code */
export type QualificationCode = {
  __typename?: 'QualificationCode';
  /** Qualification code value */
  qualificationCode: Scalars['String']['output'];
};

/** Query type for fetching data */
export type Query = {
  __typename?: 'Query';
  GetCustomerSegments?: Maybe<CustomerSegments>;
  /** Get article reviews */
  articleReviews?: Maybe<PaginatedArticleReviews>;
  /** Get all audiences available for B2B customers */
  audiences?: Maybe<Array<Audience>>;
  /** Retrieve banner campaigns by platform / market / space */
  bannerCampaigns?: Maybe<Array<BannerCampaign>>;
  /**
   * Retrieve the best categories for a given query, based on the platform and market.
   * The results are sorted by position, with the first element being the best category.
   */
  bestCategories: Array<Maybe<BestCategory>>;
  /**
   * Query the best offer given a sale channel and a master product identifier
   * Given the master product represented by the requested sale channel and monechelle master product id, returns among all the skus best offers, the lowest priced one corresponding to this master product if present
   */
  bestOfferByArticleId?: Maybe<ChannelOffer>;
  /**
   * Query the best offer given a sale channel and a sku identifier
   * Given the sku represented by the requested sale channel and monechelle sku id, returns the best offer corresponding to this sku if present
   */
  bestOfferByModelId?: Maybe<ChannelOffer>;
  /** Given the customerId, platform, market, device and changes, find the best offers for the cart */
  bestOffers?: Maybe<BestOffers>;
  /**
   * Query for a set of best offers given a sale channel and a set of sku identifiers
   * Given the set of sku represented by the requested sale channel and monechelle sku ids, returns the set of best offers corresponding to each sku if present
   */
  bestOffersByModelIds?: Maybe<ChannelOffers>;
  /** Get the best sellers digest */
  bestSellersDigest?: Maybe<BestSellersDigest>;
  /** Get the best sellers for a specific family */
  bestSellersForFamily?: Maybe<BestSellersForFamily>;
  brand: Brand;
  brands: Array<Maybe<Brand>>;
  /** bulkDiscount: query to obtain discounts for a specific productId and platform */
  bulkDiscount?: Maybe<BulkDiscount>;
  /** Get bundle coupons */
  bundleCoupons?: Maybe<Array<Maybe<Coupon>>>;
  /** Get a CashbackOffer by platform, market and productId. */
  cashbackOffer?: Maybe<CashbackOffer>;
  /**
   * Query for category
   * @deprecated Use MerchandisingCategory instead
   */
  category: Category;
  /** Get a Category Advice by category id, platform and market */
  categoryAdvice?: Maybe<CategoryAdvice>;
  /** Compute the cheapest delivery promises */
  cheapestDelivery: Array<CheapestDeliveryDispatch>;
  /** Get commercial animation attributes by platform, market and productId. */
  commercialAnimationAttributes?: Maybe<CommercialAnimationAttributes>;
  /**
   * Get a commercial operation by ID.
   *
   * Args:
   * id: The ID of the commercial operation.
   */
  commercialOperation?: Maybe<CommercialOperation>;
  /** Get customer company */
  company?: Maybe<Company>;
  /** Compute the total for a cart */
  computeTotalForCart: CartComputation;
  /** Compute the total for a customer order */
  computeTotalForCustomerOrder: CustomerOrder;
  /** Compute the xborder output */
  computeXborder: XborderComputation;
  /** Get bulk promises for a customer order uuid */
  couponBulkPromises?: Maybe<Array<CouponBulkPromise>>;
  /** Get the coupons count of a customer (including loyalty program coupons for B2B) */
  couponsCount?: Maybe<Scalars['Int']['output']>;
  /** Get customer */
  customer?: Maybe<Customer>;
  /** Get customer address by id */
  customerAddress?: Maybe<CustomerAddress>;
  /** Get customer addresses */
  customerAddresses: Array<Maybe<CustomerAddress>>;
  /** Get customer subscription by platform and market */
  customerSubscription: CustomerSubscription;
  /** Get customer subscription status for the logged b2b user */
  customerSubscriptionStatus?: Maybe<CustomerSubscriptionStatus>;
  /** A tree given an order ID */
  decisionTree: HelpCenterTree;
  /** Query a delivery given a customer order ID, if the user is a customer or a guest, he must own the delivery */
  deliveryByCustomerOrderId?: Maybe<CustomerDelivery>;
  /** Retrieve the delivery discount advertising for a given market, platform and channel */
  deliveryDiscountAdvertising?: Maybe<DeliveryDiscountAdvertising>;
  /** Query fetching all delivery messages, one by country, sorted by id */
  deliveryMessages: Array<DeliveryMessage>;
  /**
   * Live delivery promise, to use only on product page, computation is live and can be costly and low, mainly for manofulfillment offer
   * @deprecated Use `deliveryOfferV2` instead, delete at 2024-06-12
   */
  deliveryOffer?: Maybe<Array<Maybe<DeliveryOffer>>>;
  /**
   * Live delivery promise, to use only on product page, computation is live and can be costly and low, mainly for manofulfillment offer
   * version 2 without the warehouseId and with the market mandatory
   */
  deliveryOfferV2?: Maybe<Array<Maybe<DeliveryOffer>>>;
  /** Fetch remaining for admin */
  fetchAdminRemaining?: Maybe<Remaining>;
  /** Fetch finance referential */
  fetchFinanceReferential: FinanceReferentialOutput;
  /** Fetch refund request by refund claims id */
  fetchIds: RefundRequestItemsIds;
  /** Fetch legacy refunds ids */
  fetchLegacyIds: LegacyRefundIds;
  /** Fetch refund request by id */
  fetchReasons: Array<Maybe<ReasonMapping>>;
  /** Fetch refund request id */
  fetchRefundRequest: RefundRequestInfo;
  /** Fetch Paginated refund requests */
  fetchRefundRequests: PagedResponse;
  /** Fetch paginated refund requests by ids */
  fetchRefundRequestsByIds: PagedResponse;
  /** Fetch paginated refund requests for sellers */
  fetchRefundRequestsForSellers: PagedResponse;
  /** Fetch remaining for seller */
  fetchSellerRemaining?: Maybe<Remaining>;
  /** Find a COBI by his customerOrderId */
  findCobiByCustomerOrderId: CustomerOrderBillingInfo;
  /** Given a customer Id, a Platform, and a Market, retrieve the Cart of the Customer */
  findCustomerCart?: Maybe<CustomerCart>;
  /** Given a B2B customer Id (extracted from the authorization header) and a Platform, retrieve their Product preferences */
  findCustomerProductPreferences?: Maybe<CustomerProductPreferences>;
  /** Find fiscal info requirements by customer order id */
  findFiscalInfoRequirementsByCustomerOrderId: FiscalInfoRequirements;
  /**
   * Given a visitor Id and Platform, retrieve a Cart.
   * Visitor Carts only exist on the B2C Market
   */
  findVisitorCart?: Maybe<VisitorCart>;
  /** Given a customer Id, a Platform, a Market and a wishlist name retrieve the Wishlist of the Customer */
  findWishlist?: Maybe<Wishlist>;
  /** Given a customer Id, a Platform and a Market retrieve all the Wishlists of the Customer */
  findWishlists: Array<Wishlist>;
  /** Retrieves actives subscription plans */
  getActiveSubscriptionPlans: Array<SubscriptionPlan>;
  /** Query to get available stock by Offer ID (cached 120 seconds) */
  getAvailableStockByOfferId?: Maybe<Scalars['Int']['output']>;
  /** Query to get ChannelBrand by brand id (UUID) and platform */
  getChannelBrandByIdAndPlatform?: Maybe<ChannelBrand>;
  /** Query to get ChannelBrand by brand public id and platform */
  getChannelBrandByPublicIdAndPlatform?: Maybe<ChannelBrand>;
  /** get precalculated delivery promise, the computation is live for non manofulfillment offer and precalculated for manofulfillment one. Performance is better than deliveryOffers but contains basic information */
  getColdDeliveryOffers?: Maybe<ColdDeliveryOffers>;
  /** Retrieves payment means available in the cart context */
  getPaymentMeanForCart: Array<Maybe<PaymentMeanDetails>>;
  /** Retrieves saved payment methods for the current customer */
  getSavedPaymentMethodsForCurrentCustomer: GetSavedPaymentMethodsResponse;
  /**
   * Query a seller contract given a seller contract id
   * @deprecated Field getSellerContractById is deprecated. Use sellerContractById instead. Delete date: 2024/12/04
   */
  getSellerContractById?: Maybe<SellerContract>;
  /** Query to get the return policy details for a given seller by their ID. */
  getSellerReturnPolicy?: Maybe<SellerReturnPolicy>;
  /** Query to get the custom return policy text for a given seller by their ID. */
  getSellerReturnPolicyTextBySellerId?: Maybe<SellerReturnPolicyText>;
  /** Get a Guide by legacy id */
  guide?: Maybe<Guide>;
  /** Get highlighted coupon */
  highlightedCoupon?: Maybe<Coupon>;
  /** Homepage data */
  homepage?: Maybe<Homepage>;
  /**
   * Legacy - will fetch homepage brands for the provided platform, on B2C market
   * and WEB channel, sorted by their configured position. Capped at 1000 elements,
   * an error is emitted if the maximum is reached.
   * @deprecated Add nullability. Use query "homepage" instead. Removal on 2023-01-10
   */
  homepageB2cBrands: Array<Brand>;
  /**
   * Legacy - will fetch homepage brands for the provided platform, on B2C market
   * and WEB channel, sorted by their configured position. Capped at 1000 elements,
   * an error is emitted if the maximum is reached.
   * @deprecated Full rework. Use query "homepage" instead. Removal on 2023-10-01
   */
  homepageB2cBrandsV1?: Maybe<Array<Brand>>;
  isSuspectedPro?: Maybe<IsSuspectedPro>;
  /**
   * Get a landing page by ID.
   *
   * Args:
   * id: The ID of the landing page.
   */
  landingPage?: Maybe<LandingPage>;
  /** Get latest reviews stats for an article id and platform */
  latestReviews?: Maybe<LatestReviews>;
  /**
   * Query the set of variants within a legacy variant-experience given a sale channel and a master product identifier
   * Given a master product, the set of best offers (1 for each of its sku variation) corresponding to this master product
   * This query should only be called if ChannelOffer.sku.masterProduct.variantExperience is LEGACY
   */
  legacyVariantsByArticleId: LegacyVariants;
  /** Get Loyalty coupons by Platform */
  loyaltyCoupons?: Maybe<Array<Maybe<Coupon>>>;
  /** Get Loyalty coupons usage by Platform */
  loyaltyCouponsUsage?: Maybe<LoyaltyCouponUsage>;
  /** Retrieve the loyalty program HVC level configuration using a given platform */
  loyaltyHVCLevelConfiguration?: Maybe<LoyaltyHVCLevelConfiguration>;
  /** Retrieve the loyalty program HVC level status using a given platform and authentication token */
  loyaltyHVCLevelStatus?: Maybe<LoyaltyHVCLevelStatus>;
  /** Retrieve the loyalty program HVC offers available using a given platform and channel */
  loyaltyHVCOffers: Array<LoyaltyHVCOffer>;
  /** Retrieve the loyalty program level configuration using a given platform and channel */
  loyaltyProgramLevelConfiguration?: Maybe<LoyaltyProgramLevelConfiguration>;
  /** Retrieve the loyalty program level status using a given platform and authentication token */
  loyaltyProgramLevelStatus?: Maybe<LoyaltyProgramLevelStatus>;
  /** Retrieve the number of orders using a given platform and authentication token */
  loyaltyProgramOrders?: Maybe<Scalars['Int']['output']>;
  /** Retrieve the loyalty program reward status using a given platform and authentication token */
  loyaltyProgramRewardStatus?: Maybe<LoyaltyProgramRewardStatus>;
  /** Query for retrieving the current state of the loyalty wallet for a specific platform and authentication token */
  loyaltyWallet?: Maybe<LoyaltyWallet>;
  /** Query for retrieving the history of loyalty wallet transactions for a specific platform and authentication token */
  loyaltyWalletHistory?: Maybe<Array<LoyaltyWalletHistoryItem>>;
  /** Retrieve the consents of a customer */
  marketingConsent?: Maybe<MarketingConsent>;
  /** Get mds stats for an article id, category id and platform */
  mdStats?: Maybe<Array<Maybe<ProductRatingMd>>>;
  /** Query to get a MerchandisingCategory by public ID, platform and market */
  merchandisingCategoryByPublicIdAndPlatformAndMarket?: Maybe<MerchandisingCategory>;
  /** Query to get the full merchandising tree by platform and market - all root categories and their children recursively up to the requested depth. */
  merchandisingTreeByPlatformAndMarket?: Maybe<Array<MerchandisingTreeCategory>>;
  /** Given the customerId, platform, market, device and changes, find the Mano Fulfillment alternatives for the cart */
  mfAlternatives?: Maybe<MfAlternatives>;
  /** Model attributes of query */
  modelAttributes?: Maybe<Array<ModelAttributes>>;
  /**
   * Query an offer given a sale channel and a monechelle offer id
   * Given the offer represented by the requested sale channel and monechelle offer id, returns the offer if present
   */
  offerByProductId?: Maybe<ChannelOffer>;
  /**
   * Query for a set of offers given a sale channel and a set monechelle offer id
   * Given the set of offers represented by the requested sale channel and monechelle offer ids, returns the offers if present
   */
  offersByProductIds?: Maybe<ChannelOffers>;
  /** Get metadata of a page */
  pageMetadata?: Maybe<PageMetadata>;
  /**
   * Fetches parcels by order ID
   * role allowed:
   * * Customer: (ROLE_CUSTOMER or ROLE_CUSTOMER_GUEST) and ROLE_READ_PARCEL
   * * ManoMano: ROLE_READ_ANY_PARCEL
   */
  parcelsByOrderId: Array<Parcel>;
  /**
   * Retrieve PartnerAd campaigns by platform / market / space
   *
   * The channel is used only for tracking reasons
   */
  partnerAdCampaigns?: Maybe<PartnerAdCampaignsQuery>;
  /** Find a pay in entry by id */
  payInEntry?: Maybe<PayInEntry>;
  /** Get a payment by its ID or by issuer */
  payment?: Maybe<Payment>;
  /** Look for a payment refund from criteria, at least one is required */
  paymentRefund?: Maybe<PaymentRefund>;
  /** Retrieves the payment SDK keys and merchant id, for the given market and platform. */
  paymentSdkKeys?: Maybe<PaymentSdkKeys>;
  /** Query fetching available pickup points for a given location and a given slug */
  pickupPointsByType: Array<PickupPoint>;
  /** Retrieve pre header messages by platform / channel / market */
  preHeaders: Array<PreHeader>;
  /** priceAdvantage: Difference between b2b and b2c prices */
  priceAdvantage?: Maybe<PriceAdvantage>;
  proBrands?: Maybe<Array<Maybe<Brand>>>;
  proOffers?: Maybe<Array<Maybe<ProOffer>>>;
  /** Get product advantages by modelId and platform v2 */
  productAdvantagesByModelIdV2?: Maybe<ProductAdvantageResponseV2>;
  /** Get coupons for a product */
  productCoupons?: Maybe<Array<Maybe<Coupon>>>;
  /** Retrieve the product history of a customer */
  productHistory?: Maybe<Array<ProductHistory>>;
  /** Get list of article rating pictures from an article id / platform */
  productReviewPictures?: Maybe<Array<Maybe<ProductReviewPicture>>>;
  /**
   * Get live commercial operations.
   *
   * Args:
   * platform: The platform for which you want to retrieve all live commercial operations.
   * market: The market for which you want to retrieve all live commercial operations.
   */
  promoHub: Array<CommercialOperation>;
  /** A qualification given the qualification code */
  qualification: Qualification;
  /** A list of customer contact qualifications */
  qualifications: Array<Qualification>;
  /** Retrieve quick access links by platform / channel / market */
  quickAccessLinks: Array<QuickAccessLink>;
  /** Get rating stats from an article Id */
  ratingsStats?: Maybe<ArticleRatingStats>;
  /** recommendationEngines */
  recommendationEngines?: Maybe<Array<Engine>>;
  /** recommendations */
  recommendations?: Maybe<Array<RecommendationEngine>>;
  /** Query to get recommendations for the given engine names */
  recommendationsByEngine?: Maybe<Array<Engine>>;
  /**
   * Query the set of variants within a regular variant-experience given a sale channel and a master product identifier
   * Given a master product, the set of best offers (1 for each of its sku variation) corresponding to this master product
   * This query should only be called if ChannelOffer.sku.masterProduct.variantExperience is REGULAR
   */
  regularVariantsByArticleId: RegularVariants;
  /** Get 1 hotel by id */
  release: Release;
  /** Sponsored brand query namespace */
  sBrands?: Maybe<SBrandsQuery>;
  /** Sponsored product query namespace */
  sProducts?: Maybe<SProductsQuery>;
  /** Fetches scheduled delivery slots by promise ID */
  scheduleDeliverySlotsByPromiseId: Array<CustomerDeliveryScheduleSlot>;
  /** Search Customer-Orders */
  searchCustomerOrders: CustomerOrderPage;
  /**
   * TODO query searchOtherOffers
   * @deprecated Use ChannelOffer and federation.
   */
  searchOtherOffers?: Maybe<OfferCollection>;
  /**
   * TODO query searchProduct
   * @deprecated Use ChannelOffer and federation.
   */
  searchProduct?: Maybe<Product>;
  /**
   * Query to search a ProductCollection
   * Given a list of modelIds and a limit, fills a list of products. This list will contain the products returned by Rhino when prompted for the list of modelIds, filtering out the products that are not available in Rhino, and truncating the result by a size of the supplied limit parameter.
   * @deprecated Use ChannelOffer and federation.
   */
  searchProductCollection?: Maybe<ProductCollection>;
  /** Query a seller contract given a seller contract id */
  sellerContractById?: Maybe<SellerContract>;
  /**
   * Query a seller contract media given a seller contract id and an optional group
   * @deprecated Field sellerContractMediaRetrieval is deprecated. Use sellerContractById instead. Delete date: 2024/12/04
   */
  sellerContractMediaRetrieval?: Maybe<SellerContractMedia>;
  /**
   * Query a seller contract given a seller contract id
   * @deprecated Field sellerContractRetrieval is deprecated. Use getSellerContractById instead. Delete date: 2024/10/24
   */
  sellerContractRetrieval?: Maybe<SellerContract>;
  /** sellerVat by seller id */
  sellerVatById?: Maybe<SellerVat>;
  /** SEO content for a given page */
  seoContent?: Maybe<SeoContent>;
  /** SEO content for a given category */
  seoContentCategory?: Maybe<SeoContentCategory>;
  /**
   * Retrieve banner templating themes by market and start date.
   * A platform can be provided to get the theme for a specific platform.
   *
   * - If `startAt` is not provided, returns the current theme, i.e. the one with the latest `startAt`.
   * - If `startAt` is provided, returns the theme valid at that date. If no theme is valid, returns null.
   *   If multiple themes are valid, returns the latest one.
   */
  theme?: Maybe<Theme>;
  /** Get threshold highlighted coupon */
  thresholdHighlightedCoupons?: Maybe<ThresholdHighlightedCoupons>;
  tipsheet?: Maybe<Tipsheet>;
  /** Top products page data */
  topProduct?: Maybe<TopProduct>;
  /** Get User coupons */
  userCoupons?: Maybe<Array<Maybe<Coupon>>>;
  /** Retrieve the referer for a visitor */
  visitorReferer?: Maybe<VisitorReferer>;
  /** Retrieve the wallet coupon using a given order id */
  walletCouponRedeemed?: Maybe<WalletCouponRedeemed>;
};

/** Query type for fetching data */
export type QueryarticleReviewsArgs = {
  articleReviewInput: ArticleReviewInput;
};

/** Query type for fetching data */
export type QuerybannerCampaignsArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  market: Market;
  platform: Platform;
  space: BannerSpace;
  userSegments?: InputMaybe<Scalars['Map']['input']>;
};

/** Query type for fetching data */
export type QuerybestCategoriesArgs = {
  market: Market;
  platform: Platform;
  query: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QuerybestOfferByArticleIdArgs = {
  articleId: Scalars['String']['input'];
  offerOptions?: OfferOptions;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QuerybestOfferByModelIdArgs = {
  modelId: Scalars['String']['input'];
  offerOptions?: OfferOptions;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QuerybestOffersArgs = {
  changes?: InputMaybe<Array<Scalars['Int']['input']>>;
  customerId: Scalars['Int']['input'];
  device: Device;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerybestOffersByModelIdsArgs = {
  modelIds: Array<Scalars['String']['input']>;
  offerOptions?: OfferOptions;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QuerybestSellersDigestArgs = {
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerybestSellersForFamilyArgs = {
  family: Scalars['ID']['input'];
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerybrandArgs = {
  brandId: Scalars['Int']['input'];
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerybrandsArgs = {
  brandIds: Array<Scalars['Int']['input']>;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerybulkDiscountArgs = {
  market?: InputMaybe<Market>;
  platform?: InputMaybe<Platform>;
  productId?: InputMaybe<Scalars['Int']['input']>;
};

/** Query type for fetching data */
export type QuerybundleCouponsArgs = {
  market: Market;
  modelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  platform: Platform;
  sellerId?: InputMaybe<Scalars['Int']['input']>;
};

/** Query type for fetching data */
export type QuerycashbackOfferArgs = {
  market: Market;
  platform: Platform;
  productId: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QuerycategoryArgs = {
  categoryId: Scalars['Int']['input'];
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerycategoryAdviceArgs = {
  categoryId: Scalars['Int']['input'];
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerycheapestDeliveryArgs = {
  request: CheapestDeliveryInput;
};

/** Query type for fetching data */
export type QuerycommercialAnimationAttributesArgs = {
  market: Market;
  platform: Platform;
  productId: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QuerycommercialOperationArgs = {
  id: Scalars['Int']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
};

/** Query type for fetching data */
export type QuerycomputeTotalForCartArgs = {
  request?: InputMaybe<ComputeCartTotalInput>;
};

/** Query type for fetching data */
export type QuerycomputeTotalForCustomerOrderArgs = {
  request?: InputMaybe<ComputeCustomerOrderTotalInput>;
};

/** Query type for fetching data */
export type QuerycomputeXborderArgs = {
  request?: InputMaybe<ComputeXborderInput>;
};

/** Query type for fetching data */
export type QuerycouponBulkPromisesArgs = {
  customerOrderUuid: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QuerycouponsCountArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QuerycustomerAddressArgs = {
  id: Scalars['Int']['input'];
  withGeo?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Query type for fetching data */
export type QuerycustomerSubscriptionArgs = {
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerydecisionTreeArgs = {
  from?: InputMaybe<FromRequest>;
  orderId: Scalars['Float']['input'];
};

/** Query type for fetching data */
export type QuerydeliveryByCustomerOrderIdArgs = {
  customerOrderId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QuerydeliveryDiscountAdvertisingArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerydeliveryOfferArgs = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  isMf: Scalars['Boolean']['input'];
  market?: InputMaybe<Market>;
  platform: Platform;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  sellerId: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  warehouseId: Scalars['Int']['input'];
  weight: Scalars['Float']['input'];
};

/** Query type for fetching data */
export type QuerydeliveryOfferV2Args = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  isMf: Scalars['Boolean']['input'];
  market?: InputMaybe<Market>;
  platform: Platform;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  sellerId: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
};

/** Query type for fetching data */
export type QueryfetchAdminRemainingArgs = {
  request: AdminRemainingInput;
};

/** Query type for fetching data */
export type QueryfetchFinanceReferentialArgs = {
  customerOrderId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QueryfetchIdsArgs = {
  refundClaimsId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QueryfetchLegacyIdsArgs = {
  refundRequestItemId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QueryfetchReasonsArgs = {
  request?: InputMaybe<RefundReasonCategory>;
};

/** Query type for fetching data */
export type QueryfetchRefundRequestArgs = {
  refundRequestId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QueryfetchRefundRequestsArgs = {
  request: RefundRequestCriteriaInput;
};

/** Query type for fetching data */
export type QueryfetchRefundRequestsByIdsArgs = {
  request: RefundRequestCriteriaInBodyInput;
};

/** Query type for fetching data */
export type QueryfetchRefundRequestsForSellersArgs = {
  request: RefundRequestCriteriaInput;
};

/** Query type for fetching data */
export type QueryfetchSellerRemainingArgs = {
  request: SellerRemainingInput;
};

/** Query type for fetching data */
export type QueryfindCobiByCustomerOrderIdArgs = {
  customerOrderId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QueryfindCustomerCartArgs = {
  customerId: Scalars['Int']['input'];
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryfindCustomerProductPreferencesArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryfindFiscalInfoRequirementsByCustomerOrderIdArgs = {
  customerOrderId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QueryfindVisitorCartArgs = {
  platform: Platform;
  visitorId: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QueryfindWishlistArgs = {
  customerId: Scalars['Int']['input'];
  market: Market;
  name: Scalars['String']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QueryfindWishlistsArgs = {
  customerId: Scalars['Int']['input'];
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerygetAvailableStockByOfferIdArgs = {
  offerId: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QuerygetChannelBrandByIdAndPlatformArgs = {
  id: Scalars['ID']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QuerygetChannelBrandByPublicIdAndPlatformArgs = {
  platform: Platform;
  publicId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QuerygetColdDeliveryOffersArgs = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  isMf: Scalars['Boolean']['input'];
  market?: InputMaybe<Market>;
  platform: Platform;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  sellerId: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
};

/** Query type for fetching data */
export type QuerygetPaymentMeanForCartArgs = {
  context: InputPaymentMeanCart;
};

/** Query type for fetching data */
export type QuerygetSavedPaymentMethodsForCurrentCustomerArgs = {
  request?: InputMaybe<GetSavedPaymentMethodsRequest>;
};

/** Query type for fetching data */
export type QuerygetSellerContractByIdArgs = {
  sellerContractId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QuerygetSellerReturnPolicyArgs = {
  input: GetSellerReturnPolicyInput;
};

/** Query type for fetching data */
export type QuerygetSellerReturnPolicyTextBySellerIdArgs = {
  input: GetSellerReturnPolicyTextInput;
};

/** Query type for fetching data */
export type QueryguideArgs = {
  legacyId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QueryhighlightedCouponArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryhomepageArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
  userSegments?: InputMaybe<Scalars['Map']['input']>;
};

/** Query type for fetching data */
export type QueryhomepageB2cBrandsArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryhomepageB2cBrandsV1Args = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryisSuspectedProArgs = {
  email: Scalars['String']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QuerylandingPageArgs = {
  id: Scalars['ID']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
};

/** Query type for fetching data */
export type QuerylatestReviewsArgs = {
  articleId: Scalars['Int']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QuerylegacyVariantsByArticleIdArgs = {
  articleId: Scalars['String']['input'];
  pagination: OffsetPaginationInput;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QueryloyaltyCouponsArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyCouponsUsageArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyHVCLevelConfigurationArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyHVCLevelStatusArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyHVCOffersArgs = {
  channel: Channel;
  platform: Platform;
  userSegments?: InputMaybe<Scalars['Map']['input']>;
};

/** Query type for fetching data */
export type QueryloyaltyProgramLevelConfigurationArgs = {
  channel?: InputMaybe<Channel>;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyProgramLevelStatusArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyProgramOrdersArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyProgramRewardStatusArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyWalletArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryloyaltyWalletHistoryArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QuerymdStatsArgs = {
  articleId: Scalars['Int']['input'];
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerymerchandisingCategoryByPublicIdAndPlatformAndMarketArgs = {
  market: Market;
  platform: Platform;
  publicId: Scalars['Long']['input'];
};

/** Query type for fetching data */
export type QuerymerchandisingTreeByPlatformAndMarketArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerymfAlternativesArgs = {
  changes?: InputMaybe<Array<Scalars['Int']['input']>>;
  customerId: Scalars['Int']['input'];
  device: Device;
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerymodelAttributesArgs = {
  modelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryofferByProductIdArgs = {
  offerOptions?: OfferOptions;
  productId: Scalars['String']['input'];
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QueryoffersByProductIdsArgs = {
  offerOptions?: OfferOptions;
  productIds: Array<Scalars['String']['input']>;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QuerypageMetadataArgs = {
  platform: Platform;
  url: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QueryparcelsByOrderIdArgs = {
  orderId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QuerypayInEntryArgs = {
  id: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QuerypaymentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  issuerId?: InputMaybe<Scalars['ID']['input']>;
  issuerType?: InputMaybe<PaymentIssuerType>;
};

/** Query type for fetching data */
export type QuerypaymentRefundArgs = {
  manualRefundId?: InputMaybe<Scalars['ID']['input']>;
};

/** Query type for fetching data */
export type QuerypaymentSdkKeysArgs = {
  request: PaymentSdkKeysRequest;
};

/** Query type for fetching data */
export type QuerypickupPointsByTypeArgs = {
  request: PickupPointsByTypeInput;
};

/** Query type for fetching data */
export type QuerypreHeadersArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
  userSegments?: InputMaybe<Scalars['Map']['input']>;
};

/** Query type for fetching data */
export type QuerypriceAdvantageArgs = {
  modelId?: InputMaybe<Scalars['BigInteger']['input']>;
  platform?: InputMaybe<Platform>;
};

/** Query type for fetching data */
export type QueryproBrandsArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryproOffersArgs = {
  platform: Platform;
};

/** Query type for fetching data */
export type QueryproductAdvantagesByModelIdV2Args = {
  modelId?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<Platform>;
};

/** Query type for fetching data */
export type QueryproductCouponsArgs = {
  market: Market;
  modelId: Scalars['Int']['input'];
  platform: Platform;
  sellerId?: InputMaybe<Scalars['Int']['input']>;
};

/** Query type for fetching data */
export type QueryproductReviewPicturesArgs = {
  articleId: Scalars['Int']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QuerypromoHubArgs = {
  market: Market;
  platform: Platform;
};

/** Query type for fetching data */
export type QueryqualificationArgs = {
  platform?: InputMaybe<Platform>;
  qualificationCode: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QueryqualificationsArgs = {
  platform?: InputMaybe<Platform>;
};

/** Query type for fetching data */
export type QueryquickAccessLinksArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
  userSegments?: InputMaybe<Scalars['Map']['input']>;
};

/** Query type for fetching data */
export type QueryratingsStatsArgs = {
  articleId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QueryrecommendationEnginesArgs = {
  abtestPools: ABTestPool;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isPro: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  market: Market;
  modelId?: InputMaybe<Scalars['BigInteger']['input']>;
  modelIds?: InputMaybe<Array<Scalars['BigInteger']['input']>>;
  pageType: Scalars['String']['input'];
  platformId: Platform;
  productId?: InputMaybe<Scalars['BigInteger']['input']>;
  productIds?: InputMaybe<Array<Scalars['BigInteger']['input']>>;
};

/** Query type for fetching data */
export type QueryrecommendationsArgs = {
  abtestPools: ABTestPool;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  engineNames: Array<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  modelIds?: InputMaybe<Array<Scalars['BigInteger']['input']>>;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QueryrecommendationsByEngineArgs = {
  abtestPools: ABTestPool;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  engineNames: Array<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  market: Market;
  modelIds?: InputMaybe<Array<Scalars['BigInteger']['input']>>;
  platformId: Platform;
};

/** Query type for fetching data */
export type QueryregularVariantsByArticleIdArgs = {
  articleId: Scalars['String']['input'];
  pagination: OffsetPaginationInput;
  saleChannel: SaleChannelInput;
};

/** Query type for fetching data */
export type QueryreleaseArgs = {
  id: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QueryscheduleDeliverySlotsByPromiseIdArgs = {
  promiseId: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QuerysearchCustomerOrdersArgs = {
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  platform?: InputMaybe<Platform>;
  reference?: InputMaybe<Scalars['String']['input']>;
  salesMarket?: InputMaybe<Market>;
  sortBy?: Scalars['String']['input'];
  statuses?: Array<CustomerOrderStatus>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  visitorId?: InputMaybe<Scalars['ID']['input']>;
};

/** Query type for fetching data */
export type QuerysearchOtherOffersArgs = {
  is_pro: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platform: Platform;
  product_id: Scalars['BigInteger']['input'];
};

/** Query type for fetching data */
export type QuerysearchProductArgs = {
  article_id?: InputMaybe<Scalars['BigInteger']['input']>;
  include_non_sellable?: InputMaybe<Scalars['Boolean']['input']>;
  market: Market;
  model_id?: InputMaybe<Scalars['BigInteger']['input']>;
  platform: Platform;
  product_id?: InputMaybe<Scalars['BigInteger']['input']>;
};

/** Query type for fetching data */
export type QuerysearchProductCollectionArgs = {
  isPro: Scalars['Boolean']['input'];
  limit: Scalars['Int']['input'];
  modelIds: Array<Scalars['BigInteger']['input']>;
  platform: Platform;
};

/** Query type for fetching data */
export type QuerysellerContractByIdArgs = {
  sellerContractId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QuerysellerContractMediaRetrievalArgs = {
  group?: InputMaybe<Scalars['String']['input']>;
  sellerContractId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QuerysellerContractRetrievalArgs = {
  sellerContractId: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QuerysellerVatByIdArgs = {
  sellerId?: InputMaybe<Scalars['BigInteger']['input']>;
};

/** Query type for fetching data */
export type QueryseoContentArgs = {
  platform: Platform;
  url: Scalars['String']['input'];
};

/** Query type for fetching data */
export type QueryseoContentCategoryArgs = {
  categoryId: Scalars['Long']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QuerythemeArgs = {
  market: Market;
  platform?: InputMaybe<Platform>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Query type for fetching data */
export type QuerythresholdHighlightedCouponsArgs = {
  channel: Channel;
  market: Market;
  platform: Platform;
  userSegments?: InputMaybe<Scalars['Map']['input']>;
};

/** Query type for fetching data */
export type QuerytipsheetArgs = {
  id: Scalars['Int']['input'];
};

/** Query type for fetching data */
export type QuerytopProductArgs = {
  idTopProductPage: Scalars['ID']['input'];
  platform: Platform;
};

/** Query type for fetching data */
export type QueryvisitorRefererArgs = {
  pageType?: InputMaybe<Scalars['String']['input']>;
  refererHeader?: InputMaybe<Scalars['String']['input']>;
  refererId?: InputMaybe<Scalars['Int']['input']>;
  visitorId: Scalars['ID']['input'];
};

/** Query type for fetching data */
export type QuerywalletCouponRedeemedArgs = {
  customerOrderId: Scalars['String']['input'];
};

/** Quick Access Link object */
export type QuickAccessLink = {
  __typename?: 'QuickAccessLink';
  /** Quick access link A/B test */
  abTest?: Maybe<ABTest>;
  /** Channel */
  channel: Channel;
  /** CSS classname to be applied to the quick access link */
  className?: Maybe<Scalars['String']['output']>;
  /** Date and time the quick access link will stop being active */
  endAt?: Maybe<Scalars['Int']['output']>;
  /** Quick access link feature flag */
  featureFlag?: Maybe<FeatureFlag>;
  /** ID */
  id: Scalars['String']['output'];
  /** Market */
  market: Market;
  /** Platform */
  platform: Platform;
  /** Quick access link position */
  position: Scalars['Int']['output'];
  /** Date and time the quick access link will start being active */
  startAt?: Maybe<Scalars['Int']['output']>;
  /** Quick access link status (live, planned, archived) */
  status: Scalars['String']['output'];
  /** HTML target attribute */
  target?: Maybe<Scalars['String']['output']>;
  /** Quick access link text to be displayed */
  title: Scalars['String']['output'];
  /** Quick access link's destination */
  url: Scalars['String']['output'];
  /** User segments associated to the quick access link */
  userSegments?: Maybe<Scalars['Map']['output']>;
};

/** Rating */
export type Rating = {
  __typename?: 'Rating';
  /** average: Float! */
  average: Scalars['Float']['output'];
  /** total_ratings: Int! */
  total_ratings: Scalars['Int']['output'];
};

/** Reason mapping */
export type ReasonMapping = {
  __typename?: 'ReasonMapping';
  /** Refund reason category */
  refundReasonCategory: RefundReasonCategory;
  /** Refund reasons */
  refundReasons: Array<RefundReason>;
};

/** The recipient for a given qualification */
export type Recipient = {
  __typename?: 'Recipient';
  /** Recipient type (e.g: Seller) */
  default: Scalars['String']['output'];
};

/** RecommendationEngine */
export type RecommendationEngine = {
  __typename?: 'RecommendationEngine';
  /** canonicalName */
  canonicalName: Scalars['String']['output'];
  /** category Ids */
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  /** ChannelOffer */
  channelOffers: ChannelOffers;
  /** Unique identification name of the engine */
  engineName: Scalars['ID']['output'];
};

/** Recommendation Type */
export enum RecommendationType {
  ALTERNATIVE = 'ALTERNATIVE',
  GROUPED = 'GROUPED',
  GROUPED_AND_PRICE = 'GROUPED_AND_PRICE',
  PRICE = 'PRICE',
}

/** Redirect */
export type Redirect = {
  __typename?: 'Redirect';
  /** Redirect value */
  redirect: Scalars['String']['output'];
};

/** Redirection of the page */
export type RedirectTo = {
  __typename?: 'RedirectTo';
  /** Code of the redirection */
  code: Scalars['Int']['output'];
  /** Url of the page to redirect */
  targetUrl: Scalars['String']['output'];
};

/** URL on which the frontend should redirect */
export type RedirectToUrl = {
  __typename?: 'RedirectToUrl';
  /** URL to redirect to */
  url: Scalars['String']['output'];
};

/** A refund attempt */
export type RefundAttempt = {
  __typename?: 'RefundAttempt';
  /** Amount of the refund attempt */
  amount: CustomerRefundAmount;
  /** Creation date of the refund attempt */
  createdAt: Scalars['String']['output'];
  /** Refund attempt id is an unique key under UUID format (eb729c04-957d-4819-8bef-26fb72a23b84) */
  id: Scalars['ID']['output'];
  /** Is refund attempt refunded by psp */
  isRefundedByPsp: Scalars['Boolean']['output'];
  /** Manual refund of the refund attempt */
  manualRefund?: Maybe<ManualRefund>;
  /** Payment attempt linked to the refund attempt */
  paymentAttempt: PaymentAttempt;
  /** Payment mean of the refund attempt */
  paymentMeanType?: Maybe<PaymentMeanType>;
  /** Psp name of the refund attempt */
  pspName?: Maybe<Scalars['String']['output']>;
  /** Status of the refund attempt */
  status: RefundAttemptStatus;
  /** Last update date of the refund attempt */
  updatedAt: Scalars['String']['output'];
};

/** Enumeration of refund attempt status */
export enum RefundAttemptStatus {
  ABANDONED = 'ABANDONED',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  TO_HANDLE_MANUALLY = 'TO_HANDLE_MANUALLY',
  TO_PROCESS_BY_PSP = 'TO_PROCESS_BY_PSP',
}

/** Refund Liable */
export enum RefundLiable {
  /** MANOMANO */
  MANOMANO = 'MANOMANO',
  /** SELLER */
  SELLER = 'SELLER',
}

/** Refund reason */
export enum RefundReason {
  /** BOUGHT_BY_MISTAKE */
  BOUGHT_BY_MISTAKE = 'BOUGHT_BY_MISTAKE',
  /** BROKEN_OR_DAMAGED_PRODUCT */
  BROKEN_OR_DAMAGED_PRODUCT = 'BROKEN_OR_DAMAGED_PRODUCT',
  /** CANCELLED_AFTER_DELIVERY */
  CANCELLED_AFTER_DELIVERY = 'CANCELLED_AFTER_DELIVERY',
  /** CANCELLED_BEFORE_SHIPMENT */
  CANCELLED_BEFORE_SHIPMENT = 'CANCELLED_BEFORE_SHIPMENT',
  /** CHEAPER_ELSEWHERE */
  CHEAPER_ELSEWHERE = 'CHEAPER_ELSEWHERE',
  /** CONCRETE_GUARANTEE */
  CONCRETE_GUARANTEE = 'CONCRETE_GUARANTEE',
  /** DAMAGED_ITEM_BUT_UNDAMAGED_PACKAGE */
  DAMAGED_ITEM_BUT_UNDAMAGED_PACKAGE = 'DAMAGED_ITEM_BUT_UNDAMAGED_PACKAGE',
  /** DAMAGED_PACKAGE_DELIVERY_REFUSED */
  DAMAGED_PACKAGE_DELIVERY_REFUSED = 'DAMAGED_PACKAGE_DELIVERY_REFUSED',
  /** DAMAGED_PRODUCT_UNDER_WARRANTY_30DAYS */
  DAMAGED_PRODUCT_UNDER_WARRANTY_30DAYS = 'DAMAGED_PRODUCT_UNDER_WARRANTY_30DAYS',
  /** DAMAGED_PRODUCT_WITHOUT_RETURN */
  DAMAGED_PRODUCT_WITHOUT_RETURN = 'DAMAGED_PRODUCT_WITHOUT_RETURN',
  /** DAMAGED_PRODUCT_WITH_RETURN */
  DAMAGED_PRODUCT_WITH_RETURN = 'DAMAGED_PRODUCT_WITH_RETURN',
  /** DEFECTIVE_ITEM_NOT_WORKING */
  DEFECTIVE_ITEM_NOT_WORKING = 'DEFECTIVE_ITEM_NOT_WORKING',
  /** DEFECTIVE_PRODUCT */
  DEFECTIVE_PRODUCT = 'DEFECTIVE_PRODUCT',
  /** DELAYED */
  DELAYED = 'DELAYED',
  /** DELIVERED_BROKEN_OR_DAMAGED */
  DELIVERED_BROKEN_OR_DAMAGED = 'DELIVERED_BROKEN_OR_DAMAGED',
  /** DELIVERY_DISPUTE */
  DELIVERY_DISPUTE = 'DELIVERY_DISPUTE',
  /** DELIVERY_ISSUE */
  DELIVERY_ISSUE = 'DELIVERY_ISSUE',
  /** DELIVERY_PROBLEM */
  DELIVERY_PROBLEM = 'DELIVERY_PROBLEM',
  /** DELIVERY_PROBLEM_PACKAGE_RETURNED */
  DELIVERY_PROBLEM_PACKAGE_RETURNED = 'DELIVERY_PROBLEM_PACKAGE_RETURNED',
  /** DELIVERY_TIME_EXCEEDED_IN_POINT_OF_DELIVERY */
  DELIVERY_TIME_EXCEEDED_IN_POINT_OF_DELIVERY = 'DELIVERY_TIME_EXCEEDED_IN_POINT_OF_DELIVERY',
  /** EMPTY_PACKAGE */
  EMPTY_PACKAGE = 'EMPTY_PACKAGE',
  /** HYPERCARE */
  HYPERCARE = 'HYPERCARE',
  /** INCOMPLETE_ITEM */
  INCOMPLETE_ITEM = 'INCOMPLETE_ITEM',
  /** INCOMPLETE_ORDER */
  INCOMPLETE_ORDER = 'INCOMPLETE_ORDER',
  /** INCOMPLETE_PRODUCT */
  INCOMPLETE_PRODUCT = 'INCOMPLETE_PRODUCT',
  /** INVALID_REFUND */
  INVALID_REFUND = 'INVALID_REFUND',
  /** ITEM_AND_PACKAGE_DAMAGED */
  ITEM_AND_PACKAGE_DAMAGED = 'ITEM_AND_PACKAGE_DAMAGED',
  /** ITEM_RECEIVED_TOO_LATE */
  ITEM_RECEIVED_TOO_LATE = 'ITEM_RECEIVED_TOO_LATE',
  /** LEGAL_REQUIREMENT */
  LEGAL_REQUIREMENT = 'LEGAL_REQUIREMENT',
  /** MANOMANO_MISTAKE */
  MANOMANO_MISTAKE = 'MANOMANO_MISTAKE',
  /** MANUAL_ISSUE */
  MANUAL_ISSUE = 'MANUAL_ISSUE',
  /** MISSING_CREDIT_NOTE */
  MISSING_CREDIT_NOTE = 'MISSING_CREDIT_NOTE',
  /** MISSING_INVOICE */
  MISSING_INVOICE = 'MISSING_INVOICE',
  /** NON_COMPLIANT_PRODUCT */
  NON_COMPLIANT_PRODUCT = 'NON_COMPLIANT_PRODUCT',
  /** NON_COMPLIANT_PRODUCT_INCORRECT_DESCRIPTION */
  NON_COMPLIANT_PRODUCT_INCORRECT_DESCRIPTION = 'NON_COMPLIANT_PRODUCT_INCORRECT_DESCRIPTION',
  /** NON_COMPLIANT_PRODUCT_LOGISTICS_MISTAKE */
  NON_COMPLIANT_PRODUCT_LOGISTICS_MISTAKE = 'NON_COMPLIANT_PRODUCT_LOGISTICS_MISTAKE',
  /** NON_COMPLIANT_PRODUCT_MISSING_SPARE_PART */
  NON_COMPLIANT_PRODUCT_MISSING_SPARE_PART = 'NON_COMPLIANT_PRODUCT_MISSING_SPARE_PART',
  /** NON_COMPLIANT_PRODUCT_WRONG_LABEL_EAN_CORRESPONDENCE */
  NON_COMPLIANT_PRODUCT_WRONG_LABEL_EAN_CORRESPONDENCE = 'NON_COMPLIANT_PRODUCT_WRONG_LABEL_EAN_CORRESPONDENCE',
  /** NOT_NEEDED_ANYMORE */
  NOT_NEEDED_ANYMORE = 'NOT_NEEDED_ANYMORE',
  /** NO_STOCK */
  NO_STOCK = 'NO_STOCK',
  /** ORDER_CANCELLATION */
  ORDER_CANCELLATION = 'ORDER_CANCELLATION',
  /** ORDER_REJECTED */
  ORDER_REJECTED = 'ORDER_REJECTED',
  /** PACKAGE_LOST */
  PACKAGE_LOST = 'PACKAGE_LOST',
  /** PACKAGE_LOST_SENT_BUT_NOT_RECEIVED_BY_THE_CARRIER */
  PACKAGE_LOST_SENT_BUT_NOT_RECEIVED_BY_THE_CARRIER = 'PACKAGE_LOST_SENT_BUT_NOT_RECEIVED_BY_THE_CARRIER',
  /** PACKAGE_RETURNED_DUE_TO_DELIVERY_PROBLEM */
  PACKAGE_RETURNED_DUE_TO_DELIVERY_PROBLEM = 'PACKAGE_RETURNED_DUE_TO_DELIVERY_PROBLEM',
  /** PRODUCT_NOT_AS_DESCRIBED */
  PRODUCT_NOT_AS_DESCRIBED = 'PRODUCT_NOT_AS_DESCRIBED',
  /** PRODUCT_NOT_COMPATIBLE */
  PRODUCT_NOT_COMPATIBLE = 'PRODUCT_NOT_COMPATIBLE',
  /** PRODUCT_NOT_SHIPPED */
  PRODUCT_NOT_SHIPPED = 'PRODUCT_NOT_SHIPPED',
  /** RETURN_ISSUE */
  RETURN_ISSUE = 'RETURN_ISSUE',
  /** SHIPPED_BEFORE_CANCEL */
  SHIPPED_BEFORE_CANCEL = 'SHIPPED_BEFORE_CANCEL',
  /** UNSUCCESSFUL_DELIVERY */
  UNSUCCESSFUL_DELIVERY = 'UNSUCCESSFUL_DELIVERY',
  /** WAITING_FOR_REFUND */
  WAITING_FOR_REFUND = 'WAITING_FOR_REFUND',
  /** WAITING_FOR_RESHIPMENT */
  WAITING_FOR_RESHIPMENT = 'WAITING_FOR_RESHIPMENT',
  /** WARRANTY_COVERAGE */
  WARRANTY_COVERAGE = 'WARRANTY_COVERAGE',
  /** WITHDRAWAL */
  WITHDRAWAL = 'WITHDRAWAL',
  /** WRONG_CREDIT_NOTE */
  WRONG_CREDIT_NOTE = 'WRONG_CREDIT_NOTE',
  /** WRONG_INVOICE */
  WRONG_INVOICE = 'WRONG_INVOICE',
  /** WRONG_ITEM_WRONG_DESCRIPTION */
  WRONG_ITEM_WRONG_DESCRIPTION = 'WRONG_ITEM_WRONG_DESCRIPTION',
  /** WRONG_MODEL */
  WRONG_MODEL = 'WRONG_MODEL',
}

/** Refund reason category */
export enum RefundReasonCategory {
  /** AGENT_REFUND */
  AGENT_REFUND = 'AGENT_REFUND',
  /** CONCRETE_GUARANTEE */
  CONCRETE_GUARANTEE = 'CONCRETE_GUARANTEE',
  /** EXTREME_CASE */
  EXTREME_CASE = 'EXTREME_CASE',
  /** ONE_STOCK */
  ONE_STOCK = 'ONE_STOCK',
  /** ORDER_REJECTED */
  ORDER_REJECTED = 'ORDER_REJECTED',
  /** RETURN */
  RETURN = 'RETURN',
}

/** Refund request criteria in body input */
export type RefundRequestCriteriaInBodyInput = {
  /** filter */
  filter: FilterInput;
  /** pagination */
  pagination: PaginationInput;
  /** sort */
  sort: SortInput;
};

/** Refund request criteria input */
export type RefundRequestCriteriaInput = {
  /** filter */
  filter: FilterInput;
  /** pagination */
  pagination: PaginationInput;
  /** sort */
  sort: SortInput;
  /** source */
  source: Array<SourceInput>;
};

/** Refund request */
export type RefundRequestInfo = {
  __typename?: 'RefundRequestInfo';
  /** Refund request refunded at Instant type */
  abortedAt?: Maybe<Scalars['String']['output']>;
  /** Refund request refunded at Instant type */
  canceledAt?: Maybe<Scalars['String']['output']>;
  /** Canceler id */
  cancelerId?: Maybe<Scalars['String']['output']>;
  /** Refund request cancellable until Instant type */
  cancellableUntil: Scalars['String']['output'];
  /** Refund request created at Instant type */
  createdAt: Scalars['String']['output'];
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
  /** Customer Refund id */
  customerRefundId?: Maybe<Scalars['ID']['output']>;
  /** Is full refund */
  isFullRefund: Scalars['Boolean']['output'];
  /** Platform */
  platform?: Maybe<Platform>;
  /** Refund liable */
  refundLiable: RefundLiable;
  /** Refund reason */
  refundReason?: Maybe<RefundReason>;
  /** Refund request id */
  refundRequestId: Scalars['ID']['output'];
  /** Refund request Payment methods */
  refundRequestPaymentMethods?: Maybe<Array<RefundRequestPaymentMethod>>;
  /** Refund request refunded at Instant type */
  refundedAt?: Maybe<Scalars['String']['output']>;
  /** Request level */
  requestLevel: RequestLevel;
  /** Requestor */
  requestor: Requestor;
  /** Seller Note */
  sellerNote?: Maybe<Scalars['String']['output']>;
  /** Seller orders */
  sellerOrders: Array<SellerOrderRefundRequest>;
  /** Status */
  status: RefundRequestStatus;
  /** Refund request validated at Instant type */
  validatedAt?: Maybe<Scalars['String']['output']>;
};

/** Refund Request Item */
export type RefundRequestItem = {
  __typename?: 'RefundRequestItem';
  /** amountRefundedBySellerWithoutCommission */
  amountRefundedBySellerWithoutCommission: ComputationPrice;
  /** bv */
  bv: ComputationPriceWithVat;
  /** commission */
  commission: Commission;
  /** manomanoCoupon */
  manomanoCoupon: ComputationPriceWithVat;
  /** manomanoCouponOnProduct */
  manomanoCouponOnProduct: ComputationPriceWithVat;
  /** manomanoCouponOnShipping */
  manomanoCouponOnShipping: ComputationPriceWithVat;
  /** manomanoDeliveryDiscount */
  manomanoDeliveryDiscount: ComputationPriceWithVat;
  /** orderId */
  orderId?: Maybe<Scalars['Int']['output']>;
  /** Order item id */
  orderItemId?: Maybe<Scalars['ID']['output']>;
  /** productAmountRefundedToCustomer */
  productAmountRefundedToCustomer: ComputationPrice;
  /** Product price */
  productPrice: ComputationPriceWithVat;
  /** Quantity requested */
  quantityRequested?: Maybe<Scalars['Int']['output']>;
  /** Refund Claims id */
  refundClaimsId?: Maybe<Scalars['Int']['output']>;
  /** Refund request item id */
  refundRequestItemId?: Maybe<Scalars['ID']['output']>;
  /** sellerCouponOnProduct */
  sellerCouponOnProduct: ComputationPriceWithVat;
  /** shippingAmountRefundedToCustomer */
  shippingAmountRefundedToCustomer: ComputationPrice;
  /** shippingPrice */
  shippingPrice: ComputationPriceWithVat;
  /** totalAmountRefundedBySeller */
  totalAmountRefundedBySeller: ComputationPrice;
  /** totalAmountRefundedToCustomer */
  totalAmountRefundedToCustomer: ComputationPrice;
  /** totalAmountRefundedToManomano */
  totalAmountRefundedToManomano: ComputationPrice;
  /** totalDiscount */
  totalDiscount: ComputationPrice;
  /** totalDiscountOnProduct */
  totalDiscountOnProduct: ComputationPrice;
  /** totalDiscountOnShipping */
  totalDiscountOnShipping: ComputationPrice;
  /** totalManomanoDiscount */
  totalManomanoDiscount: ComputationPrice;
};

/** RefundRequestItem ids */
export type RefundRequestItemIds = {
  __typename?: 'RefundRequestItemIds';
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
  /** Order item id */
  orderItemId: Scalars['ID']['output'];
  /** Refund request id */
  refundRequestId: Scalars['ID']['output'];
  /** Refund request item id */
  refundRequestItemId: Scalars['ID']['output'];
  /** Refund request item validated at Instant type */
  validatedAt: Scalars['String']['output'];
};

/** Refund request items ids */
export type RefundRequestItemsIds = {
  __typename?: 'RefundRequestItemsIds';
  /** customerOrderId */
  customerOrderId: Scalars['ID']['output'];
  /** orderItemId */
  orderItemId: Scalars['ID']['output'];
  /** refundRequestId */
  refundRequestId: Scalars['ID']['output'];
  /** refundRequestItemId */
  refundRequestItemId: Scalars['ID']['output'];
  /** Refund request item validated at Instant type */
  validatedAt: Scalars['String']['output'];
};

/** Refund request payment method */
export type RefundRequestPaymentMethod = {
  __typename?: 'RefundRequestPaymentMethod';
  /** Refund amount */
  amount: ComputationPrice;
  /** Payment mean */
  paymentMean?: Maybe<PaymentMean>;
  /** Psp */
  psp?: Maybe<Psp>;
  /** Refund attempt id */
  refundAttemptId?: Maybe<Scalars['ID']['output']>;
  /** Refund at - Instant type */
  refundedAt: Scalars['String']['output'];
  /** Refunded via psp */
  refundedViaPsp: Scalars['Boolean']['output'];
};

/** Refund Request - Request */
export type RefundRequestRequest = {
  /** customerOrderId */
  customerOrderId: Scalars['ID']['input'];
  /** dryRun */
  dryRun: Scalars['Boolean']['input'];
  /** refundLiable */
  refundLiable: RefundLiable;
  /** refundReason */
  refundReason?: InputMaybe<RefundReason>;
  /** requestCanal */
  requestCanal: RequestCanal;
  /** requestorId */
  requestorId?: InputMaybe<Scalars['String']['input']>;
  /** sellerNote */
  sellerNote?: InputMaybe<Scalars['String']['input']>;
  /** sellerOrders */
  sellerOrders?: InputMaybe<Array<RefundRequestSellerOrder>>;
};

/** Refund Request Request Item */
export type RefundRequestRequestItem = {
  /** orderItemId */
  orderItemId: Scalars['ID']['input'];
  /** productRefundAmountExpected */
  productRefundAmountExpected?: InputMaybe<PriceInput>;
  /** quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
  /** shippingRefundAmountExpected */
  shippingRefundAmountExpected?: InputMaybe<PriceInput>;
};

/** Seller Refund Request - Request */
export type RefundRequestRequestSellerOrder = {
  /** refundRequestItems */
  items: Array<RefundRequestRequestItem>;
  /** sellerContractId */
  sellerContractId: Scalars['Int']['input'];
};

/** Refund Request seller order */
export type RefundRequestSellerOrder = {
  /** refundRequestItems */
  refundRequestItems?: InputMaybe<Array<RefundRequestRequestItem>>;
  /** sellerContractId */
  sellerContractId: Scalars['Int']['input'];
};

/** Refund request status */
export enum RefundRequestStatus {
  /** ABORTED */
  ABORTED = 'ABORTED',
  /** CANCELED */
  CANCELED = 'CANCELED',
  /** CREATED */
  CREATED = 'CREATED',
  /** REFUNDED */
  REFUNDED = 'REFUNDED',
  /** VALIDATED */
  VALIDATED = 'VALIDATED',
}

/** A variant returned within the regular experience */
export type RegularVariant = {
  __typename?: 'RegularVariant';
  /** attributes defining this variant */
  attributes: Array<ChannelOfferSkuAttribute>;
  /** sku id in PIM catalog */
  mmId: Scalars['String']['output'];
  /** sku id in the monechelle catalog */
  modelId: Scalars['String']['output'];
};

/** A set of RegularVariant with their pagination info, see VariantExperience enumeration for more details */
export type RegularVariants = {
  __typename?: 'RegularVariants';
  /** The pagination information for the current results */
  pageInfo?: Maybe<OffsetPageInfo>;
  /** The set of regular variants */
  variants?: Maybe<Array<RegularVariant>>;
};

/** Related Category */
export type RelatedCategory = {
  __typename?: 'RelatedCategory';
  /** Id of the category */
  categoryId: Scalars['Int']['output'];
  /** Images of the category */
  images?: Maybe<Array<Image>>;
  /** Is visible attribute */
  isVisible: Scalars['Boolean']['output'];
  /** Level */
  level: Scalars['Int']['output'];
  /** name */
  name: Scalars['String']['output'];
  /** Slug */
  slug: Scalars['String']['output'];
  /** sort */
  sort: Scalars['Int']['output'];
};

/** Release type definition */
export type Release = {
  __typename?: 'Release';
  /** Id */
  package: Scalars['ID']['output'];
  /** Name of the employee */
  version: Scalars['String']['output'];
};

/** Remaining */
export type Remaining = {
  __typename?: 'Remaining';
  /** Customer order id */
  customerOrderId: Scalars['ID']['output'];
  /** Seller order remainings */
  sellerOrderRemainings: Array<SellerOrderRemaining>;
};

/** Request canal */
export enum RequestCanal {
  /** LEGACY_BACK_OFFICE */
  LEGACY_BACK_OFFICE = 'LEGACY_BACK_OFFICE',
  /** ONE_STOCK */
  ONE_STOCK = 'ONE_STOCK',
  /** PAYMENT_BACK_OFFICE */
  PAYMENT_BACK_OFFICE = 'PAYMENT_BACK_OFFICE',
  /** SELLER_API_CALL */
  SELLER_API_CALL = 'SELLER_API_CALL',
  /** TOOLBOX_CONCRETE_GUARANTEE_PAGE */
  TOOLBOX_CONCRETE_GUARANTEE_PAGE = 'TOOLBOX_CONCRETE_GUARANTEE_PAGE',
  /** TOOLBOX_ORDER_DETAILS_PAGE */
  TOOLBOX_ORDER_DETAILS_PAGE = 'TOOLBOX_ORDER_DETAILS_PAGE',
  /** TOOLBOX_RETURN_PAGE */
  TOOLBOX_RETURN_PAGE = 'TOOLBOX_RETURN_PAGE',
  /** WIDEVIEW_AGENT_REFUND */
  WIDEVIEW_AGENT_REFUND = 'WIDEVIEW_AGENT_REFUND',
  /** WIDEVIEW_CONCRETE_GUARANTEE */
  WIDEVIEW_CONCRETE_GUARANTEE = 'WIDEVIEW_CONCRETE_GUARANTEE',
  /** WIDEVIEW_EXTREME_CASE */
  WIDEVIEW_EXTREME_CASE = 'WIDEVIEW_EXTREME_CASE',
}

/** Request level */
export enum RequestLevel {
  /** CUSTOMER_ORDER */
  CUSTOMER_ORDER = 'CUSTOMER_ORDER',
  /** CUSTOMER_ORDER_ITEM */
  CUSTOMER_ORDER_ITEM = 'CUSTOMER_ORDER_ITEM',
  /** SELLER_ORDER */
  SELLER_ORDER = 'SELLER_ORDER',
}

/** Requestor */
export type Requestor = {
  __typename?: 'Requestor';
  /** Requestor canal */
  requestCanal: RequestCanal;
  /** Requestor id */
  requestorId: Scalars['String']['output'];
};

/** Reviews */
export type Review = {
  __typename?: 'Review';
  /** created at */
  createdAt: Scalars['String']['output'];
  /** customer name */
  customerName: Scalars['String']['output'];
  /** id */
  id: Scalars['Int']['output'];
  /** incentivized */
  incentivized: Scalars['Boolean']['output'];
  /** is b2b */
  isB2b: Scalars['Boolean']['output'];
  /** message */
  message?: Maybe<Scalars['String']['output']>;
  /** order id */
  orderId?: Maybe<Scalars['Int']['output']>;
  /** pictures */
  pictures?: Maybe<Array<Maybe<Picture>>>;
  /** purchase date */
  purchaseDate?: Maybe<Scalars['String']['output']>;
  /** rating */
  rating?: Maybe<Scalars['Int']['output']>;
  /** review score */
  reviewScore?: Maybe<Scalars['Int']['output']>;
  /** Reviewer */
  reviewer: Reviewer;
  /** source */
  source?: Maybe<Scalars['String']['output']>;
  /** total votes */
  totalVotes: Scalars['Int']['output'];
};

/** Reviewer */
export type Reviewer = {
  __typename?: 'Reviewer';
  /** City */
  city: Scalars['String']['output'];
  /** Country */
  country: Scalars['String']['output'];
  /** First name */
  firstName?: Maybe<Scalars['String']['output']>;
};

/** Role */
export enum Role {
  ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR',
  ROLE_BO_PRODUCT_DELIVERY_SUPER_ADMIN = 'ROLE_BO_PRODUCT_DELIVERY_SUPER_ADMIN',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER',
  ROLE_CUSTOMER_B_2_B = 'ROLE_CUSTOMER_B_2_B',
  ROLE_CUSTOMER_GUEST = 'ROLE_CUSTOMER_GUEST',
  ROLE_ONE_STOCK = 'ROLE_ONE_STOCK',
  ROLE_READ_ANY_PARCEL = 'ROLE_READ_ANY_PARCEL',
  ROLE_READ_PARCEL = 'ROLE_READ_PARCEL',
}

/**
 * Sponsored Brand response.
 *
 * Be aware that none of the queries listed here supports caching! GraphQL federation will only keep the lowest cache value
 * amongst the query responses, so beware when using any of these queries alongside others.
 */
export type SBrand = {
  __typename?: 'SBrand';
  /** Brand data */
  brandBlock: SBrandBlock;
  /** List of sponsored products associated to this brand */
  products: Array<SProduct>;
};

/**
 * Sponsored Brand Block representation.
 *
 * Gathers all the Mabaya data specific to the brand.
 */
export type SBrandBlock = {
  __typename?: 'SBrandBlock';
  /** Tracking data to use */
  adTrackingData?: Maybe<Scalars['String']['output']>;
  /** Description of the brand */
  description?: Maybe<Scalars['String']['output']>;
  /** Related image URL - fully qualified, env-specific URL */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Associated landing page URL - fully qualified, env-specific URL */
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  /** ManoMano Generated Revenue: cost per click that will be charged to the ad payer - always > 0 */
  mgr?: Maybe<Scalars['Float']['output']>;
  /** Brand name */
  name?: Maybe<Scalars['String']['output']>;
  /** An object that contains full URLs of secondary images */
  secondaryImageUrls?: Maybe<SBrandBlockSecondaryImageUrls>;
};

/** Sponsored Brand Block Secondary Image URLs representation. */
export type SBrandBlockSecondaryImageUrls = {
  __typename?: 'SBrandBlockSecondaryImageUrls';
  /** Large image URL - fully qualified, env-specific URL */
  large?: Maybe<Scalars['String']['output']>;
  /** Medium image URL - fully qualified, env-specific URL */
  medium?: Maybe<Scalars['String']['output']>;
  /** Small image URL - fully qualified, env-specific URL */
  small?: Maybe<Scalars['String']['output']>;
};

/** Sponsored Brand get by category input type. */
export type SBrandGetByCategory = {
  /** Category identifier */
  id: Scalars['ID']['input'];
};

/**
 * Sponsored Brands query type.
 *
 * Groups all queries related to sponsored brands. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored brands).
 */
export type SBrandsQuery = {
  __typename?: 'SBrandsQuery';
  /** Get a sponsored brand by category */
  getByCategory?: Maybe<SBrand>;
  /** Get a sponsored brand by query */
  getByQuery?: Maybe<SBrand>;
  /**
   * Get a sponsored brand by search
   * @deprecated Use `getByQuery` instead
   */
  getBySearch?: Maybe<SBrand>;
};

/**
 * Sponsored Brands query type.
 *
 * Groups all queries related to sponsored brands. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored brands).
 */
export type SBrandsQuerygetByCategoryArgs = {
  category: SBrandGetByCategory;
  context: SContentContext;
};

/**
 * Sponsored Brands query type.
 *
 * Groups all queries related to sponsored brands. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored brands).
 */
export type SBrandsQuerygetByQueryArgs = {
  context: SContentContext;
  query: Scalars['String']['input'];
};

/**
 * Sponsored Brands query type.
 *
 * Groups all queries related to sponsored brands. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored brands).
 */
export type SBrandsQuerygetBySearchArgs = {
  context: SContentContext;
  search: Scalars['String']['input'];
};

/**
 * Sponsored Content Context input data.
 *
 * Contains all the necessary information to determine the context in which the sponsored content is displayed.
 * This context object is the same for all GraphQL queries in this graph.
 */
export type SContentContext = {
  /**
   * ABT string is a string containing all AB-test flags in the following format: 'aab|bba'.
   *
   * First section before the pipe '|' are dynamic AB tests values, while the second section is for static, pre-defined
   * AB tests.
   *
   * Such AB tests could be parsed and exploited in the sponsored-product microservice, but are also forwarded to the
   * ads partner service.
   *
   * If the provided ABT string is not in the correct format, the sponsored-product microservice will ignore it, but will
   * still forward it to the partner as-is.
   */
  abt?: InputMaybe<Scalars['String']['input']>;
  /** Associated device */
  device: SContentDevice;
  /** Context location */
  location: SContentLocation;
  /** Associated market */
  market: Market;
  /** Associated platform */
  platform: Platform;
};

/**
 * Sponsored content device enum.
 *
 * Used to describe the device context in which the advertisement is displayed.
 */
export enum SContentDevice {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  MOBILE_APP = 'MOBILE_APP',
}

/**
 * Sponsored content location enum.
 *
 * Indicates where the sponsored content is displayed.
 */
export enum SContentLocation {
  ADD_TO_CART_PAGE = 'ADD_TO_CART_PAGE',
  CART_PAGE = 'CART_PAGE',
  CATEGORY_PAGE = 'CATEGORY_PAGE',
  CATEGORY_SPONSORED_BRAND = 'CATEGORY_SPONSORED_BRAND',
  GOOGLE_SHOPPING_PAGE = 'GOOGLE_SHOPPING_PAGE',
  GOOGLE_SHOPPING_PAGE_TOP = 'GOOGLE_SHOPPING_PAGE_TOP',
  HOME_PAGE = 'HOME_PAGE',
  MASS_LANDING_PAGE = 'MASS_LANDING_PAGE',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  PRODUCT_PAGE_HIGHLIGHT = 'PRODUCT_PAGE_HIGHLIGHT',
  PRODUCT_PAGE_TOP = 'PRODUCT_PAGE_TOP',
  SEARCH_BAR = 'SEARCH_BAR',
  SEARCH_PAGE = 'SEARCH_PAGE',
  SEARCH_SPONSORED_BRAND = 'SEARCH_SPONSORED_BRAND',
}

/** Sponsored product representation. */
export type SProduct = {
  __typename?: 'SProduct';
  /** Who pays for the ad - used with DSA */
  adPayer: AdPayer;
  /** Tracking data to use */
  adTrackingData: Scalars['String']['output'];
  /** Associated offer */
  channelOffer?: Maybe<ChannelOffer>;
  /** ManoMano Generated Revenue: cost per click that will be charged to the ad payer - always > 0 */
  mgr?: Maybe<Scalars['Float']['output']>;
};

/**
 * Sponsored Product filters input type.
 *
 * These are used in the search page. Users have the possibility to filter their results on the left-hand section. When
 * changing the filters, the frontend should make a new ad call with the new values.
 */
export type SProductFilters = {
  /** List of brand names to filter by. */
  brandNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of category IDs to filter by. */
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Whether to include only products that are marked as MF (ManoMano Fulfillment). */
  isMF?: InputMaybe<Scalars['Boolean']['input']>;
  /** Minimum rating to filter by. */
  minRating?: InputMaybe<Scalars['Float']['input']>;
  /** Price range to filter by. */
  priceRange?: InputMaybe<PriceRange>;
};

/** Sponsored Product list response. */
export type SProductList = {
  __typename?: 'SProductList';
  /** Indicates whether the first product is a ManoMano Fulfillment product */
  isFirstMF: Scalars['Boolean']['output'];
  /**
   * Position in the result to put the found sponsored product in.
   *
   * For instance, if 'positions' value is [2, 5, 9], in 'products':
   *   - The first product will be put at position 2 in the displayed list
   *   - The second product will be put at position 5 in the displayed list
   *   - The third product will be put at position 9 in the displayed list
   */
  positions?: Maybe<Array<Scalars['Int']['output']>>;
  /** List of found sponsored products */
  products: Array<SProduct>;
};

/** Sponsored Product list by category input type. */
export type SProductListByCategory = {
  /** Category identifier */
  categoryId: Scalars['ID']['input'];
  /**
   * Legacy model identifier - optional, but should be provided with locations using smart queries.
   * If not provided, the call will not use smart queries.
   */
  modelId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * Sponsored Product list by product input type.
 *
 * Represents a single product. Instead of fetching the model ID from the product ID, this service will expect the model ID
 * to be provided upon call. The model ID is normally available to the frontend, so this way of working will prevent us to
 * make an additional, unnecessary call to the product information system.
 *
 * The model ID is only useful for locations using smart queries.
 */
export type SProductListByProduct = {
  /**
   * Legacy model identifier - optional, but should be provided with locations using smart queries.
   * If not provided, the call will not use smart queries.
   */
  modelId?: InputMaybe<Scalars['ID']['input']>;
  /** Legacy product identifier */
  productId: Scalars['ID']['input'];
};

/**
 * Existing Sponsored Product by products input type.
 *
 * This will list those of the sponsored products that actually exists in this system, with all the related information.
 * Category identifiers can also be provided and will be forwarded to the partner, which will filter its result using these
 * category identifiers.
 */
export type SProductsExistingByProducts = {
  /** List of product IDs to search for */
  productIds: Array<Scalars['ID']['input']>;
};

/**
 * Sponsored Products query type.
 *
 * Groups all queries related to sponsored products. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored products).
 *
 * Be aware that none of the queries listed here supports caching! GraphQL federation will only keep the lowest cache value
 * amongst the query responses, so beware when using any of these queries alongside others.
 */
export type SProductsQuery = {
  __typename?: 'SProductsQuery';
  /** List sponsored products by a category */
  listByCategory?: Maybe<SProductList>;
  /** List sponsored products by a single product */
  listByProduct?: Maybe<SProductList>;
  /** List sponsored products by a search query */
  listByQuery?: Maybe<SProductList>;
  /** List existing sponsored products by provided products and/or categories */
  listExistingByProducts?: Maybe<SProductList>;
};

/**
 * Sponsored Products query type.
 *
 * Groups all queries related to sponsored products. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored products).
 *
 * Be aware that none of the queries listed here supports caching! GraphQL federation will only keep the lowest cache value
 * amongst the query responses, so beware when using any of these queries alongside others.
 */
export type SProductsQuerylistByCategoryArgs = {
  category: SProductListByCategory;
  context: SContentContext;
  filters?: InputMaybe<SProductFilters>;
};

/**
 * Sponsored Products query type.
 *
 * Groups all queries related to sponsored products. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored products).
 *
 * Be aware that none of the queries listed here supports caching! GraphQL federation will only keep the lowest cache value
 * amongst the query responses, so beware when using any of these queries alongside others.
 */
export type SProductsQuerylistByProductArgs = {
  context: SContentContext;
  filters?: InputMaybe<SProductFilters>;
  product: SProductListByProduct;
};

/**
 * Sponsored Products query type.
 *
 * Groups all queries related to sponsored products. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored products).
 *
 * Be aware that none of the queries listed here supports caching! GraphQL federation will only keep the lowest cache value
 * amongst the query responses, so beware when using any of these queries alongside others.
 */
export type SProductsQuerylistByQueryArgs = {
  context: SContentContext;
  filters?: InputMaybe<SProductFilters>;
  query: Scalars['String']['input'];
};

/**
 * Sponsored Products query type.
 *
 * Groups all queries related to sponsored products. This object exists only for better organizing the graph, and groups
 * in a single item, all queries related to the same API resource (here, the sponsored products).
 *
 * Be aware that none of the queries listed here supports caching! GraphQL federation will only keep the lowest cache value
 * amongst the query responses, so beware when using any of these queries alongside others.
 */
export type SProductsQuerylistExistingByProductsArgs = {
  context: SContentContext;
  products: SProductsExistingByProducts;
};

/** A pair of Market & Platform representing a ManoMano marketplace */
export type SaleChannel = {
  __typename?: 'SaleChannel';
  /** Market */
  market: Market;
  /** Platform */
  platform: Platform;
};

/**
 * A pair of Market & Platform representing a ManoMano marketplace
 * An offer is defined for a given SaleChannel
 * To be able to query an offer to be exposed on a marketplace, the SaleChannel is a mandatory information to be provided as input along with an offer/sku/masterProduct identifier
 */
export type SaleChannelInput = {
  /** Market */
  market: Market;
  /** Platform */
  platform: Platform;
};

/** Details of the saved payment mean */
export type SavedPaymentMean = {
  __typename?: 'SavedPaymentMean';
  /** Unique identifier of the saved payment mean */
  id: Scalars['String']['output'];
  /** Metadata related to the saved payment mean */
  metadata?: Maybe<SavedPaymentMeanMetadata>;
};

/** Metadata related to the saved payment mean */
export type SavedPaymentMeanMetadata = {
  __typename?: 'SavedPaymentMeanMetadata';
  /** CVC token linked to a saved card */
  cvc_token?: Maybe<Scalars['String']['output']>;
};

/** Represents a saved payment method */
export type SavedPaymentMethod = {
  __typename?: 'SavedPaymentMethod';
  /** Is this the default saved payment method of the customer */
  isDefaultSavedPaymentMethod: Scalars['Boolean']['output'];
  /** Metadata associated with the saved payment method */
  metadata: SavedPaymentMethodMetadata;
  /** Unique identifier of the saved payment method like card.pmxxxxx */
  savedPaymentMethodId: Scalars['String']['output'];
  /** Type of the saved payment method */
  type: PaymentMethodType;
};

/** To specify the place from where saved payment method are fetch */
export enum SavedPaymentMethodContext {
  /** When we are in the checkout funnel */
  FUNNEL = 'FUNNEL',
  /** When we don't want to apply any restrictions */
  GLOBAL = 'GLOBAL',
}

/** Metadata associated with a saved payment method */
export type SavedPaymentMethodMetadata = {
  __typename?: 'SavedPaymentMethodMetadata';
  /** Brand of the card */
  brand: Scalars['String']['output'];
  /** Display brand of the card */
  displayBrand?: Maybe<Scalars['String']['output']>;
  /** Expiration month of the card */
  expirationMonth?: Maybe<Scalars['Int']['output']>;
  /** Expiration year of the card */
  expirationYear?: Maybe<Scalars['Int']['output']>;
  /** Is the card expired */
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  /** Label of the card like VISA **** 1234 */
  label: Scalars['String']['output'];
  /** Name of the PSP like Adyen, Stripe */
  pspName: Scalars['String']['output'];
  /** Payment method id of the PSP like pm_xxxxx for Stripe */
  pspPaymentMethodId: Scalars['String']['output'];
};

/** Input type for selecting a promise */
export type SelectPromiseInput = {
  /** Delivery slot information. Cannot be used together with 'pickupPoint'. */
  deliverySlot?: InputMaybe<DeliverySlotInput>;
  /**
   * UUID of the dispatch we want to select
   * example: b6c84891-b59d-40f0-b9c1-efc9479a0f2e
   */
  dispatchId: Scalars['ID']['input'];
  /** Pickup point information. Cannot be used together with 'deliverySlot'. */
  pickupPoint?: InputMaybe<PickupPointInput>;
  /**
   * UUID of the promise we want to select
   * example: 92b70235-8269-4a24-9b24-69308dc04b59
   */
  promiseId: Scalars['ID']['input'];
};

/** Seller */
export type Seller = {
  __typename?: 'Seller';
  /** country: String */
  country?: Maybe<Scalars['String']['output']>;
  /** id: Int! */
  id: Scalars['Int']['output'];
  /** name: String */
  name?: Maybe<Scalars['String']['output']>;
  /** sellerVat: SellerVat */
  sellerVat?: Maybe<SellerVat>;
  /** url: String */
  url?: Maybe<Scalars['String']['output']>;
};

/** Federated seller contract entity */
export type SellerContract = {
  __typename?: 'SellerContract';
  /** Author quote */
  about: Scalars['String']['output'];
  /** Address */
  address: Scalars['String']['output'];
  /** City */
  city: Scalars['String']['output'];
  /** Company Front name */
  companyFrontName: Scalars['String']['output'];
  /** Company name */
  companyName: Scalars['String']['output'];
  /** Platform */
  contractPlatform: Platform;
  /** Seller contract status */
  contractStatus: ContractStatus;
  /** Country */
  country: Scalars['String']['output'];
  /** Date of creation */
  createdAt: Scalars['String']['output'];
  /**
   * Seller contract id
   * @deprecated Field id is deprecated. Use sellerContractId instead. Delete date: 2024/10/10
   */
  id: Scalars['Int']['output'];
  /** Is B2B */
  isB2b: Scalars['Boolean']['output'];
  /** Is mmf */
  isMmf: Scalars['Boolean']['output'];
  /** Platform */
  platform: PlatformDto;
  /** Seller account id */
  sellerAccountId: Scalars['Int']['output'];
  /** Seller contract id */
  sellerContractId: Scalars['Int']['output'];
  /** Seller Contract Media */
  sellerContractMedia?: Maybe<SellerContractMedia>;
  /** Seller legal entity id */
  sellerLegalEntityId?: Maybe<Scalars['String']['output']>;
  /** Seller contract status */
  status: ContractStatusDto;
  /** Date of update */
  updatedAt: Scalars['String']['output'];
  /** Seller contract uuid */
  uuid: Scalars['String']['output'];
};

/** Object representing a seller contract media from a seller contract id and a optional group */
export type SellerContractMedia = {
  __typename?: 'SellerContractMedia';
  /** Media */
  media: Array<Maybe<SellerMedia>>;
  /** Seller contract id */
  sellerContractId: Scalars['Int']['output'];
};

/** Seller Create Refund Request */
export type SellerCreateRefundRequest = {
  __typename?: 'SellerCreateRefundRequest';
  /** Refund request cancellable until Instant type */
  cancellableUntil: Scalars['String']['output'];
  /** Refund request created at Instant type */
  createdAt: Scalars['String']['output'];
  /** customerOrderId */
  customerOrderId: Scalars['ID']['output'];
  /** isFullRefund */
  isFullRefund: Scalars['Boolean']['output'];
  /** refundReason */
  refundReason?: Maybe<RefundReason>;
  /** refundRequestId */
  refundRequestId: Scalars['ID']['output'];
  /** refundRequestItems */
  refundRequestItems: Array<RefundRequestItem>;
  /** requestLevel */
  requestLevel: RequestLevel;
  /** requestor */
  requestor: Requestor;
  /** sellerContractId */
  sellerContractId: Scalars['Int']['output'];
  /** sellerNote */
  sellerNote?: Maybe<Scalars['String']['output']>;
  /** status */
  status: RefundRequestStatus;
};

/** Seller info */
export type SellerInfo = {
  __typename?: 'SellerInfo';
  /** Establishment country */
  establishmentCountry: Scalars['String']['output'];
  /** Seller Id */
  sellerId: Scalars['Int']['output'];
  /** Ship from */
  shipFrom: ShipFrom;
};

/** Object representing Media */
export type SellerMedia = {
  __typename?: 'SellerMedia';
  /** Creation date of media */
  createdAt: Scalars['String']['output'];
  /** Extension of media */
  extension: Scalars['String']['output'];
  /** Group of media */
  group: Scalars['String']['output'];
  /** Update date of media */
  updatedAt: Scalars['String']['output'];
  /** Url of media */
  url: Scalars['String']['output'];
  /** Variation of media */
  variation?: Maybe<Scalars['String']['output']>;
};

/** Seller order type */
export type SellerOrder = {
  __typename?: 'SellerOrder';
  /** bv */
  bv: ComputationPriceWithVat;
  /** Is MMF */
  isMmf: Scalars['Boolean']['output'];
  /** Manomano coupon */
  manomanoCoupon: ComputationPriceWithVat;
  /** Manomano coupon on product */
  manomanoCouponOnProduct: ComputationPriceWithVat;
  /** Manomano coupon on shipping */
  manomanoCouponOnShipping: ComputationPriceWithVat;
  /** Manomano delivery discount */
  manomanoDeliveryDiscount: ComputationPriceWithVat;
  /** Order id */
  orderId?: Maybe<Scalars['Int']['output']>;
  /** Product amount paid by the customer */
  productAmountPaidByTheCustomer: ComputationPrice;
  /** Product price */
  productPrice: ComputationPriceWithVat;
  /** Seller contract id */
  sellerContractId: Scalars['Int']['output'];
  /** Seller coupon on product */
  sellerCouponOnProduct: ComputationPriceWithVat;
  /** Seller order items */
  sellerOrderItems: Array<SellerOrderItem>;
  /** Shipping amount paid by the customer */
  shippingAmountPaidByTheCustomer: ComputationPrice;
  /** Shipping price */
  shippingPrice: ComputationPriceWithVat;
  /** Total amount paid by the customer */
  totalAmountPaidByTheCustomer: ComputationPrice;
  /** Total discount */
  totalDiscount: ComputationPrice;
  /** Total discount on product */
  totalDiscountOnProduct: ComputationPrice;
  /** Total discount on shipping */
  totalDiscountOnShipping: ComputationPrice;
  /** Total Manomano discount */
  totalManomanoDiscount: ComputationPrice;
};

/** Seller order item type */
export type SellerOrderItem = {
  __typename?: 'SellerOrderItem';
  /** bv */
  bv: ComputationPriceWithVat;
  /** Commission */
  commission: Commission;
  /** Create time */
  createTime: Scalars['String']['output'];
  /** Manomano coupon */
  manomanoCoupon: ComputationPriceWithVat;
  /** Manomano coupon on product */
  manomanoCouponOnProduct: ComputationPriceWithVat;
  /** Manomano coupon on shipping */
  manomanoCouponOnShipping: ComputationPriceWithVat;
  /** Manomano delivery discount */
  manomanoDeliveryDiscount: ComputationPriceWithVat;
  /** Offer id */
  offerId?: Maybe<Scalars['String']['output']>;
  /** Order item id */
  orderItemId: Scalars['ID']['output'];
  /** Product amount paid by the customer */
  productAmountPaidByTheCustomer: ComputationPrice;
  /** Product id */
  productId: Scalars['Int']['output'];
  /** Product price */
  productPrice: ComputationPriceWithVat;
  /** Quantity */
  quantity: Scalars['Int']['output'];
  /** Seller contract id */
  sellerContractId: Scalars['Int']['output'];
  /** Seller coupon on product */
  sellerCouponOnProduct: ComputationPriceWithVat;
  /** Seller sku */
  sellerSku?: Maybe<Scalars['String']['output']>;
  /** Shipping amount paid by the customer */
  shippingAmountPaidByTheCustomer: ComputationPrice;
  /** Shipping price */
  shippingPrice: ComputationPriceWithVat;
  /** Shipping time */
  shippingTime?: Maybe<Scalars['String']['output']>;
  /** Total amount paid by the customer */
  totalAmountPaidByTheCustomer: ComputationPrice;
  /** Total discount */
  totalDiscount: ComputationPrice;
  /** Total discount on product */
  totalDiscountOnProduct: ComputationPrice;
  /** Total discount on shipping */
  totalDiscountOnShipping: ComputationPrice;
  /** Total Manomano discount */
  totalManomanoDiscount: ComputationPrice;
  /** Unit Manomano coupon */
  unitManomanoCoupon: ComputationPrice;
  /** Unit product price */
  unitProductPrice: ComputationPriceWithVat;
  /** Unit seller coupon on product */
  unitSellerCouponOnProduct: ComputationPrice;
  /** Unit shipping price */
  unitShippingPrice: ComputationPriceWithVat;
  /** Xborder vat treatment code */
  xborderVatTreatmentCode: Scalars['String']['output'];
};

/** Seller order item remaining */
export type SellerOrderItemRemaining = {
  __typename?: 'SellerOrderItemRemaining';
  /** Order item id */
  orderItemId: Scalars['ID']['output'];
  /** Product max refundable */
  productMaxRefundable: ComputationPrice;
  /** quantity refundable */
  quantityRefundable: Scalars['Int']['output'];
  /** Shipping max refundable */
  shippingMaxRefundable: ComputationPrice;
  /** Total max refundable */
  totalMaxRefundable: ComputationPrice;
};

/** Seller order refund request */
export type SellerOrderRefundRequest = {
  __typename?: 'SellerOrderRefundRequest';
  /** Manomano view product amount refunded to the customer */
  mmViewProductAmountRefundedToTheCustomer: ComputationPrice;
  /** Manomano view shipping amount refunded to the customer */
  mmViewShippingAmountRefundedToTheCustomer: ComputationPrice;
  /** Refund Request Items */
  refundRequestItems: Array<RefundRequestItem>;
  /** Seller contract id */
  sellerContractId: Scalars['Int']['output'];
  /** Seller view product amount refunded to the customer */
  sellerViewProductAmountRefundedToTheCustomer: ComputationPrice;
  /** Seller view shipping amount refunded to the customer */
  sellerViewShippingAmountRefundedToTheCustomer: ComputationPrice;
};

/** Seller order remaining */
export type SellerOrderRemaining = {
  __typename?: 'SellerOrderRemaining';
  /** Customer amount refundable */
  customerAmountRefundable?: Maybe<ComputationPrice>;
  /** Seller order items */
  items: Array<SellerOrderItemRemaining>;
  /** Seller contract id */
  sellerContractId: Scalars['Int']['output'];
};

/** Seller Refund Request - Request */
export type SellerRefundRequestRequest = {
  /** customerOrderId */
  customerOrderId: Scalars['ID']['input'];
  /** dryRun */
  dryRun: Scalars['Boolean']['input'];
  /** items */
  items: Array<RefundRequestRequestItem>;
  /** refundReason */
  refundReason?: InputMaybe<RefundReason>;
  /** requestCanal */
  requestCanal: RequestCanal;
  /** requestorId */
  requestorId?: InputMaybe<Scalars['String']['input']>;
  /** sellerContractId */
  sellerContractId: Scalars['Int']['input'];
  /** sellerNote */
  sellerNote?: InputMaybe<Scalars['String']['input']>;
};

/** Seller remaining request */
export type SellerRemainingInput = {
  /** Customer order id */
  customerOrderId: Scalars['ID']['input'];
  /** Order item id */
  orderItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Seller contract id */
  sellerContractId: Scalars['Int']['input'];
};

/** Contains the return policy information for a seller. */
export type SellerReturnPolicy = {
  __typename?: 'SellerReturnPolicy';
  /** A custom return policy defined by the seller, if applicable. */
  customReturnPolicy?: Maybe<Scalars['String']['output']>;
  /** Indicates if the return fees are free for the buyer. */
  returnFeesFree: Scalars['Boolean']['output'];
  /** Maximum number of days for the cashback process after the reception of the product. */
  returnProcessTime: Scalars['Int']['output'];
  /** The maximum number of days a buyer has to withdraw from the purchase. */
  returnWithdrawalLimitInDays: Scalars['Int']['output'];
  /** The unique identifier for the seller. */
  sellerId: Scalars['ID']['output'];
  /** Indicates if the seller has validated the seller return policy or not and we offer the default values for MF and non MF. */
  validated: Scalars['Boolean']['output'];
};

/** Contains the return policy text for a seller. */
export type SellerReturnPolicyText = {
  __typename?: 'SellerReturnPolicyText';
  /** The unique identifier for the seller. */
  sellerId: Scalars['ID']['output'];
  /** The custom return policy text for the seller. */
  text?: Maybe<Scalars['String']['output']>;
};

/** SellerVat */
export type SellerVat = {
  __typename?: 'SellerVat';
  /** the business location */
  establishment?: Maybe<Scalars['String']['output']>;
  /** seller id */
  sellerId: Scalars['BigInteger']['output'];
  /** ship form */
  shipFrom?: Maybe<Scalars['String']['output']>;
};

/** Content information for a given page */
export type SeoContent = {
  __typename?: 'SeoContent';
  /** Content of the first HTML block */
  htmlBlock?: Maybe<Scalars['String']['output']>;
  /** Content of the second HTML block */
  htmlBlock2?: Maybe<Scalars['String']['output']>;
  /** Content of the property "description" of the meta tag */
  metaDesc?: Maybe<Scalars['String']['output']>;
  /** Associated platform */
  platform: Platform;
  /** Associated title of the page */
  title?: Maybe<Scalars['String']['output']>;
  /** URL of the page */
  url: Scalars['String']['output'];
};

/** Content information for a given category */
export type SeoContentCategory = {
  __typename?: 'SeoContentCategory';
  /** Category id */
  categoryId: Scalars['Long']['output'];
  /** Content of the first HTML block */
  htmlBlock?: Maybe<Scalars['String']['output']>;
  /** Content of the second HTML block */
  htmlBlock2?: Maybe<Scalars['String']['output']>;
  /** Content of the property "description" of the meta tag */
  metaDesc?: Maybe<Scalars['String']['output']>;
  /** Associated platform */
  platform: Platform;
  /** Associated title of the page */
  title?: Maybe<Scalars['String']['output']>;
};

/** Ship from */
export type ShipFrom = {
  __typename?: 'ShipFrom';
  /** Country code */
  countryCode: Scalars['String']['output'];
  /** default */
  default: Scalars['Boolean']['output'];
};

/** Sku */
export type Sku = {
  __typename?: 'Sku';
  /** attributes: [CatalogAttribute] */
  attributes?: Maybe<Array<Maybe<CatalogAttribute>>>;
  /** optional carbon score */
  carbon_score?: Maybe<CarbonScore>;
  /** description: Description */
  description?: Maybe<Description>;
  /** ean: String */
  ean?: Maybe<Scalars['String']['output']>;
  /** energy label */
  energyLabel?: Maybe<EnergyLabel>;
  /** is_available_in_pro: Boolean! */
  is_available_in_pro: Scalars['Boolean']['output'];
  /** is_pro_product: Boolean! */
  is_pro_product: Scalars['Boolean']['output'];
  /** manufacturer_sku: String */
  manufacturer_sku?: Maybe<Scalars['String']['output']>;
  /** media: [Media]! */
  media: Array<Maybe<Media>>;
  /** mm_id: BigInteger */
  mm_id?: Maybe<Scalars['BigInteger']['output']>;
  /** model_id:Int! */
  model_id: Scalars['Int']['output'];
  /** rating: Rating! */
  rating: Rating;
  /** reference: String! */
  reference: Scalars['String']['output'];
  /** repairability_index: Float */
  repairability_index?: Maybe<Scalars['Float']['output']>;
  /** slug_article: String */
  slug_article?: Maybe<Scalars['String']['output']>;
  /** title: String! */
  title: Scalars['String']['output'];
  /** variants: VariantWrapper */
  variants?: Maybe<VariantWrapper>;
};

/** A snack advice is a small sentence giving a tip on a category */
export type SnackAdvice = {
  __typename?: 'SnackAdvice';
  /** id */
  id: Scalars['ID']['output'];
  /** text */
  text: Scalars['String']['output'];
  /** type */
  type: SnackAdviceType;
};

/** SnackAdviceType Enum */
export enum SnackAdviceType {
  green = 'green',
  technical = 'technical',
}

/** Sort input */
export type SortInput = {
  /** field */
  field: SortableField;
  /** orderSort */
  sort?: InputMaybe<OrderSort>;
};

/** Sortable field */
export enum SortableField {
  /** CREATED_AT */
  CREATED_AT = 'CREATED_AT',
  /** VALIDATED_AT */
  VALIDATED_AT = 'VALIDATED_AT',
}

/** Sort direction enum */
export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Source */
export enum Source {
  automatic = 'automatic',
  manual = 'manual',
}

/** Source input */
export type SourceInput = {
  /** key */
  key: Scalars['String']['input'];
  /** value */
  value: Array<Scalars['String']['input']>;
};

/** Stock */
export type Stock = {
  __typename?: 'Stock';
  /** increase_quantity: Int! */
  increase_quantity: Scalars['Int']['output'];
  /** stock_available_in_weeks: Int! */
  stock_available_in_weeks: Scalars['Int']['output'];
  /** stock_minimum_value: Int! */
  stock_minimum_value: Scalars['Int']['output'];
  /** total_stock: Int! */
  total_stock: Scalars['Int']['output'];
};

/** Subjected to VAT */
export enum SubjectedToVat {
  /** The customer has expressed that is not subjected to the VAT rules */
  NO = 'NO',
  /** The customer has not expressed whether he/she is subjected or not to the VAT rules */
  NOT_INFORMED = 'NOT_INFORMED',
  /** The customer has expressed that is subjected to the VAT rules */
  YES = 'YES',
}

/** A subscription plan with its price and sales information */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  /** ID of the subscription plan */
  planId: Scalars['ID']['output'];
  /** Price of the subscription plan */
  price: SubscriptionPrice;
  /** Sales information currently active on the plan, null if none */
  sales?: Maybe<SubscriptionSale>;
  /** Billing frequency of the subscription plan */
  subscriptionType: BillingFrequency;
};

/** Object representing a price with and without VAT */
export type SubscriptionPrice = {
  __typename?: 'SubscriptionPrice';
  /** Price with VAT */
  amountWithVat: Scalars['Float']['output'];
  /** Price without VAT */
  amountWithoutVat: Scalars['Float']['output'];
  /** Currency of the price */
  currency: Currency;
};

/** A subscription sale with its start and end date, discounted price and coupon */
export type SubscriptionSale = {
  __typename?: 'SubscriptionSale';
  /** Coupon code of the sale */
  coupon: Scalars['String']['output'];
  /** Discounted price of the subscription plan if a discount is active, null otherwise */
  discountedPrice: SubscriptionPrice;
  /** End date of the sale */
  endDate: Scalars['String']['output'];
  /** Is the sale a prolongation of another one */
  isProlongation?: Maybe<Scalars['Boolean']['output']>;
  /** Start date of the sale */
  startDate: Scalars['String']['output'];
};

/**
 * Theme type
 * Represents the colors of the theme and the date when it starts being valid.
 * There is no end date because the theme is valid until a newer one is defined.
 */
export type Theme = {
  __typename?: 'Theme';
  /** Map containing the colors of the theme, as key-value pairs where the key is the color name and the value is the color value */
  colors: Scalars['Map']['output'];
  /** Date when the theme starts being valid */
  startAt?: Maybe<Scalars['DateTime']['output']>;
};

/** ThresholdHighlightedCoupons type definition */
export type ThresholdHighlightedCoupons = {
  __typename?: 'ThresholdHighlightedCoupons';
  /** Coupons list */
  coupons?: Maybe<Array<Coupon>>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** End date of the campaign */
  endDate: Scalars['String']['output'];
  /** Label */
  label?: Maybe<Scalars['String']['output']>;
  /** Link Text */
  linkText?: Maybe<Scalars['String']['output']>;
  /** Link URL */
  linkUrl?: Maybe<Scalars['String']['output']>;
  /** Start date of the campaign */
  startDate: Scalars['String']['output'];
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Customer segments */
  userSegments?: Maybe<Scalars['Map']['output']>;
};
export type Tipsheet = {
  __typename?: 'Tipsheet';
  author: Scalars['Int']['output'];
  body: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  categories: Array<Maybe<Scalars['Int']['output']>>;
  categoryId: Scalars['Int']['output'];
  categoryPicture: TipsheetPicture;
  features: Array<Maybe<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  leadParagraph?: Maybe<Scalars['String']['output']>;
  picture: TipsheetPicture;
  platform: Platform;
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};
export type TipsheetPicture = {
  __typename?: 'TipsheetPicture';
  path: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Information for a top products page */
export type TopProduct = {
  __typename?: 'TopProduct';
  /** Attributes to display for each products */
  attributes: Array<AttributeMetadata>;
  /** List of products selected for best cost effectiveness */
  bestCostEffectiveness: ProductCollection;
  /** List of products selected for best price */
  bestPrice: ProductCollection;
  /** List of products selected for best quality */
  bestQuality: ProductCollection;
  /** Associated category */
  category: Category;
  /** Top Product Page identifier */
  id: Scalars['ID']['output'];
  /** Associated platform */
  platform: Platform;
  /** Slug of the top products page */
  slug: Scalars['String']['output'];
  /** Title of the top products pages */
  title: Scalars['String']['output'];
  /** Year to display */
  year: Scalars['Int']['output'];
};

/** Inputs for updating manual refund bank details */
export type UpdateManualRefundCustomerBankDetailsInput = {
  /** Bank account holder name */
  bankAccountHolderName: Scalars['String']['input'];
  /** Bank account BIC */
  bic: Scalars['String']['input'];
  /** Bank account IBAN */
  iban: Scalars['String']['input'];
  /** Manual refund id is an unique key under UUID format (eb729c04-957d-4819-8bef-26fb72a23b84) */
  manualRefundId: Scalars['ID']['input'];
};

/** Mutation response for manual refund bank details update */
export type UpdateManualRefundCustomerBankDetailsResponse = MutationResponse & {
  __typename?: 'UpdateManualRefundCustomerBankDetailsResponse';
  code: Scalars['String']['output'];
  manualRefund?: Maybe<ManualRefund>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Indicates if the PSP SDK must be used */
export type UsePspSdk = {
  __typename?: 'UsePspSdk';
  /** Client secret to use for the PSP SDK */
  clientSecret: Scalars['String']['output'];
};

/**
 * The variant experience associated to a masterProduct
 * It will dictate which query to use to retrieve the variants, either legacyVariantsByArticleId or regularVariantsByArticleId
 */
export enum VariantExperience {
  /**
   * When this masterProduct's variations (its SKUs) do not allow a distinction between them according to their variant attributes
   * It can be that there is no variant attribute at all or that some variant attributes values are the same for some of its SKUs
   */
  LEGACY = 'LEGACY',
  /** When this masterProduct's variations (its SKUs) is made of a single SKU */
  NONE = 'NONE',
  /** When this masterProduct's variations (its SKUs) allow a distinction between them according to their variant attributes */
  REGULAR = 'REGULAR',
}

/** Variant only with the model_id */
export type VariantSimple = {
  __typename?: 'VariantSimple';
  /** model_id: Int! */
  model_id: Scalars['Int']['output'];
};

/** VariantWrapper */
export type VariantWrapper = {
  __typename?: 'VariantWrapper';
  /** data: [VariantSimple]! */
  data: Array<Maybe<VariantSimple>>;
  /** total: Int! */
  total: Scalars['Int']['output'];
};

/** VAT status */
export enum VatStatus {
  /** Only applies for ES (companies) and IT: when it is the first time that we send a VAT number to validate (automatically, not introduced by the customer), if the status response from Funds Out is INVALID, we will store at company level INVALID_AUTO */
  INVALID_AUTO = 'INVALID_AUTO',
  /** Applies for all the others platforms (ES sole traders included) from the first time without exception, and for ES (companies), IT and all the others platforms since the second attempt */
  INVALID_MANUAL = 'INVALID_MANUAL',
  /** This VAT number has been delivered to FundsOut for validation */
  IN_PROGRESS = 'IN_PROGRESS',
  /** This VAT number does not apply in a country */
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  /** This VAT number has not been sent to FundsOut for validation */
  NOT_SENT_TO_VALIDATE = 'NOT_SENT_TO_VALIDATE',
  /** This VAT number has passed validation */
  VALID = 'VALID',
}

/**
 * A Collection of items that a Visitor intends to Purchase.
 * The only information in possession of the visitor cart is the product_id and the quantity of each product.
 * A Visitor Cart can't be used on the checkout funnel, as only Customers can make purchases.
 */
export type VisitorCart = {
  __typename?: 'VisitorCart';
  /** When was this Visitor Cart created */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** The items in the Cart */
  items: Array<CartItem>;
  /** The Market of the Visitor Cart, always B2C */
  market: Market;
  /** The platform of the Cart */
  platform: Platform;
  /** A base64 encoding of a json representation of this Cart */
  sharingKey?: Maybe<Scalars['String']['output']>;
  /** When was the last update to this Visitor Cart */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** The Visitor who owns this Visitor Cart */
  visitorId: Scalars['String']['output'];
};

/** Represent a visitor referer */
export type VisitorReferer = {
  __typename?: 'VisitorReferer';
  /** Calculation date of the referer */
  calculationDate: Scalars['String']['output'];
  /** raw referer id */
  rawRefererId: Scalars['Int']['output'];
  /** referer id */
  refererId: Scalars['Int']['output'];
  /** Tracking campaign of the referer */
  trackingCampaign: Scalars['String']['output'];
  /** Tracking category of the referer */
  trackingCategory: Scalars['String']['output'];
  /** Tracking channel of the referer */
  trackingChannel: Scalars['String']['output'];
};

/** Represent a wallet coupon redeemed */
export type WalletCouponRedeemed = {
  __typename?: 'WalletCouponRedeemed';
  /** coupon amount */
  amount: Scalars['Float']['output'];
  /** coupon code */
  couponCode: Scalars['String']['output'];
  /** coupon restriction */
  restriction?: Maybe<CouponRedeemedRestrictionName>;
};

/** Represents an expiration event for a wallet amount */
export type WalletExpiration = {
  __typename?: 'WalletExpiration';
  /** The amount that will expire */
  amount: Scalars['Float']['output'];
  /** The date when the amount will expire, in string format */
  date: Scalars['String']['output'];
};

/** Represents the source of the wallet transaction, including a name and an initiative (if applicable) */
export type WalletHistorySource = {
  __typename?: 'WalletHistorySource';
  /** The specific initiative or campaign associated with the wallet source, if relevant */
  initiative?: Maybe<Scalars['String']['output']>;
  /** The name of the source type (e.g., PURCHASE, MIGRATION, or CAMPAIGN) */
  name: WalletHistorySourceName;
};

/** Enum defining possible sources of a wallet transaction credit */
export enum WalletHistorySourceName {
  /** The wallet amount was sourced from a campaign or promotion */
  CAMPAIGN = 'CAMPAIGN',
  /** The wallet amount was sourced from a migration */
  MIGRATION = 'MIGRATION',
  /** The wallet amount was sourced from a purchase */
  PURCHASE = 'PURCHASE',
}

/** Enum representing the type of historical event for the wallet */
export enum WalletHistoryType {
  /** A new entry or credit was created */
  CREATED = 'CREATED',
  /** The amount expired and is no longer available */
  EXPIRED = 'EXPIRED',
  /** The amount was used or redeemed */
  USED = 'USED',
}

/**
 * A Collection of items that a Customer likes.
 * The only information in possession of the wishlist is the product_id and the quantity of each product.
 */
export type Wishlist = {
  __typename?: 'Wishlist';
  /** When was this Wishlist created */
  createdAt: Scalars['DateTime']['output'];
  /** The Customer who owns this wishlist */
  customerId: Scalars['Int']['output'];
  /**
   * The items in the Wishlist.
   * Beware that wishlist is not aware of the products that may not be available anymore.
   * For this reason, the items within the array are nullable, and will be null when the query does the federation
   * with ChannelOffer, but there is not a matching ChannelOffer.
   */
  items: Array<Maybe<WishlistItem>>;
  /** The Market of the Wishlist, may be B2C or B2B */
  market: Market;
  /** The name of this wishlist */
  name: Scalars['String']['output'];
  /** The platform of the Wishlist */
  platform: Platform;
  /** When was the last update to this Wishlist */
  updatedAt: Scalars['DateTime']['output'];
};

/** An item in the wishlist */
export type WishlistItem = {
  __typename?: 'WishlistItem';
  /** The representation of the item on the channel-offers subgraph */
  product: ChannelOffer;
  /** How many of this item are present in the Wishlist */
  quantity: Scalars['Int']['output'];
};

/** A type containing the workflow for a given node */
export type Workflow = QualificationCode | Redirect;

/** Xborder computation */
export type XborderComputation = {
  __typename?: 'XborderComputation';
  /** Reason associated to code */
  reason: Scalars['String']['output'];
  /** Xborder vat treatment code */
  xborderVatTreatmentCode: Scalars['String']['output'];
};